import { useEffect } from "react";
import { nanoid } from "nanoid";
import { PagButton } from "./PagButton";

const PAGE_LIMIT = 6;
const OFFSET = 3;

/**
 * @typedef {object} PaginationProps
 * @property {number} pageCount
 * @property {number} page - 0 based index
 * @property {React.SetStateAction<React.Dispatch<number>>} setPage
 * @property {() => void} [onPageSelect]
 *
 * @param {PaginationProps} props
 */
export function Pagination({ pageCount, page, setPage, onPageSelect, hasMore = false }) {
	const pages = [];
	for (let i = 0; i < pageCount; ++i) {
		pages.push(i);
	}
	if (pages.length === 0) pages.push(0);

	const l = Math.max(page - OFFSET, 0);
	const r = Math.min(page + OFFSET, pages.length);
	const pageLeft = pages.slice(l, r);
	const lastPage = pages[pages.length - 1];

	function handleNextPage() {
		if (page === pageCount - 1 && !hasMore) return; // -1 since page is 0 index
		setPage(page + 1);
	}

	function handlePrevPage() {
		if (page === 0) return;
		setPage(page - 1);
	}

	function handlePageSel(p) {
		return () => {
			setPage(p);
			onPageSelect && onPageSelect();
		};
	}

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [page]);

	return (
		<div
			className="mx-auto d-flex mt-4"
			style={{ width: "max-content", gap: "6px" }}
		>
			<PagButton
				handleClick={handlePrevPage}
				active={page !== 0}
				disabled={page === 0}
			>
				<PrevSVG active={page !== 0} />
			</PagButton>
			{pages.length <= PAGE_LIMIT ? (
				pages.map((p) => (
					<PagButton
						key={nanoid(10)}
						handleClick={handlePageSel(p)}
						active={page === p}
					>
						{p + 1 /* page + 1 since it is 0 based */}
					</PagButton>
				))
			) : (
				<>
					{l === 0 ? (
						<></>
					) : (
						<>
							<PagButton
								handleClick={handlePageSel(pages[0])}
								active={false}
							>
								{pages[0] + 1}
							</PagButton>
							{pageLeft[0] !== pages[1] && <More />}
						</>
					)}
					{pageLeft.map((p) => (
						<PagButton
							key={nanoid(10)}
							handleClick={handlePageSel(p)}
							active={p === page}
						>
							{p + 1 /* page + 1 since it is 0 based */}
						</PagButton>
					))}
					{r === pages.length ? (
						<></>
					) : (
						r + 1 !== pages.length && <More />
					)}
					{r !== pages.length && (
						<PagButton
							handleClick={handlePageSel(lastPage)}
							active={lastPage === page}
						>
							{lastPage + 1 /* page + 1 since it is 0 based */}
						</PagButton>
					)}
				</>
			)}
			{hasMore && <More />}
			<PagButton
				handleClick={handleNextPage}
				active={page !== pages.length - 1}
				disabled={page === pages.length - 1 && !hasMore}
			>
				<NextSVG active={page !== pages.length - 1} />
			</PagButton>
		</div>
	);
}

function More() {
	return (
		<div
			className="d-flex align-items-end justify-content-center"
			style={{ width: "20px" }}
		>
			<span>...</span>
		</div>
	);
}

function NextSVG({ active }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="9"
			height="14"
			viewBox="0 0 9 14"
			fill="none"
		>
			<path
				d="M8.69341 7.74352L2.76856 13.6917C2.35905 14.1028 1.69686 14.1028 1.2917 13.6917L0.307134 12.7032C-0.102378 12.2921 -0.102378 11.6273 0.307134 11.2206L4.50681 7.00437L0.307134 2.78819C-0.102378 2.37707 -0.102378 1.71228 0.307134 1.30553L1.28735 0.308341C1.69686 -0.10278 2.35905 -0.10278 2.7642 0.308341L8.68905 6.25648C9.10292 6.6676 9.10292 7.3324 8.69341 7.74352Z"
				fill={active ? "#F3F9FF" : "#0D1120"}
			/>
		</svg>
	);
}

function PrevSVG({ active }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="9"
			height="14"
			viewBox="0 0 9 14"
			fill="none"
		>
			<path
				d="M0.306591 7.74352L6.23144 13.6917C6.64095 14.1028 7.30314 14.1028 7.7083 13.6917L8.69287 12.7032C9.10238 12.2921 9.10238 11.6273 8.69287 11.2206L4.49319 7.00437L8.69287 2.78819C9.10238 2.37707 9.10238 1.71228 8.69287 1.30553L7.71265 0.308341C7.30314 -0.10278 6.64095 -0.10278 6.2358 0.308341L0.310948 6.25648C-0.102921 6.6676 -0.102921 7.3324 0.306591 7.74352Z"
				fill={active ? "#F3F9FF" : "#0D1120"}
			/>
		</svg>
	);
}
