import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import Select from 'react-select';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import { toast } from 'react-toastify';
import "./styles.scss";
import "./styles-override.scss";
import { cnSupabase } from '../../database';
import airtable from '../../airtables';

// Configure dayjs to use Monday as the first day of the week
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1 // Monday as first day of week (0 is Sunday)
});

// Weekly Availability Scheduler Component
const WeeklyScheduler = () => {
  const { userInfo, totalTimezones } = useSelector(state => state.appInfo);
  
  // State for week selection - always start at current week (Monday)
  const [currentWeekStart, setCurrentWeekStart] = useState(dayjs().weekday(0)); // 0 = Monday with weekday plugin
  
  // Track if we're at the current week (to disable previous button)
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  const [weekDates, setWeekDates] = useState([]);
  const [availability, setAvailability] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  
  // Timezone state
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  
  const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const weekDaysMobile = ["M", "T", "W", "T", "F"];
  
  // Load user's timezone when component mounts
  useEffect(() => {
    const loadUserTimezone = async () => {
      if (userInfo && userInfo.id) {
        try {
          // For session hosts, we need to get their timezone from Airtable
          if (userInfo["Time Zone"]) {
            const tz = totalTimezones.find(tt => tt.id === userInfo["Time Zone"][0]);
            if (tz) {
              // Just set the selected timezone
              setSelectedTimezone({ 
                label: tz["Connected North System"], 
                value: tz.id 
              });
            }
          }
        } catch (error) {
          console.error("Error loading user timezone:", error);
        }
      }
    };
    
    loadUserTimezone();
  }, [userInfo, totalTimezones]);

  // Generate week dates from start date and check if it's the current week
  useEffect(() => {
    const dates = [];
    // Ensure we're starting from Monday regardless of what day currentWeekStart is
    const start = currentWeekStart.weekday(0); // 0 = Monday with weekday plugin
    
    for (let i = 0; i < 5; i++) {
      dates.push(start.add(i, 'day'));
    }
    
    setWeekDates(dates);
    
    // Check if we're at the current week
    const currentWeek = dayjs().weekday(0); // 0 = Monday with weekday plugin
    setIsCurrentWeek(currentWeekStart.isSame(currentWeek, 'day'));
  }, [currentWeekStart]);

  // Format date for display in the header with full month name
  const formatDate = (date) => {
    return date.format('MMMM D');
  };
  
  // Format date for database storage
  const formatDateForDB = (date) => {
    return date.format('YYYY-MM-DD');
  };
  
  // Save current week changes before navigating
  const saveCurrentWeekChanges = async () => {
    if (changed) {
      try {
        // Get the host ID - use selectedHost for team viewers or userInfo.id for hosts
        const hostId = userInfo.id;
        
        // Prompt the user if they want to save changes
        const saveConfirmed = window.confirm(
          "You have unsaved changes to your availability. Would you like to save them before moving to a different week?"
        );
        
        if (saveConfirmed) {
          setIsLoading(true);
          
          // Save timezone if needed
          if (selectedTimezone && selectedTimezone.value) {
            await airtable.teams.update(hostId, {
              "Time Zone": [selectedTimezone.value]
            });
          }
          
          // Save each day's availability
          const savePromises = Object.keys(availability).map(dateString => {
            return cnSupabase.hostAvailability.saveAvailability(
              hostId,
              dateString,
              JSON.stringify(availability[dateString])
            );
          });
          
          await Promise.all(savePromises);
          
          setChanged(false);
          // Show success message with toast
          toast.success("Availability saved before switching weeks.");
        }
      } catch (error) {
        console.error("Error saving before week change:", error);
        // Show error message with toast
        toast.error("Failed to save availability. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Move to previous week, but not before current week
  const goToPreviousWeek = async () => {
    // Try to save current week's changes first
    await saveCurrentWeekChanges();
    
    const currentWeek = dayjs().weekday(0); // 0 = Monday with weekday plugin
    setCurrentWeekStart(prev => {
      // If the previous week would be before current week, don't navigate back
      const previousWeek = prev.subtract(1, 'week');
      if (previousWeek.isBefore(currentWeek)) {
        return currentWeek;
      }
      return previousWeek;
    });
  };

  // Move to next week
  const goToNextWeek = async () => {
    // Try to save current week's changes first
    await saveCurrentWeekChanges();
    
    setCurrentWeekStart(prev => prev.add(1, 'week'));
  };
  
  // Fetch availability data for the current week
  useEffect(() => {
    const fetchAvailability = async () => {
      if (!userInfo || !userInfo.id) return;
      
      setIsLoading(true);
      
      try {
        // Calculate date range for current week
        const startDate = formatDateForDB(currentWeekStart);
        const endDate = formatDateForDB(currentWeekStart.add(4, 'day'));
        
        // Fetch data from ConnectedNorth Supabase
        const availabilityData = await cnSupabase.hostAvailability.getByHostAndDateRange(
          userInfo.id,
          startDate,
          endDate
        );
        
        // Initialize empty week structure
        const hostAvailability = {};
        weekDates.forEach((date) => {
          const dateString = formatDateForDB(date);
          hostAvailability[dateString] = Array(11).fill(0); // 0 = unavailable, 1 = first half, 2 = second half, 3 = full hour
        });
        
        // Fill with data from Supabase
        if (availabilityData && availabilityData.length > 0) {
          availabilityData.forEach(record => {
            const dateString = record.date;
            if (hostAvailability[dateString]) {
              try {
                const availArray = JSON.parse(record.availability);
                hostAvailability[dateString] = availArray;
              } catch (e) {
                console.error("Error parsing availability JSON:", e);
              }
            }
          });
        }
        
        setAvailability(hostAvailability);
      } catch (error) {
        console.error("Error fetching availability data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchAvailability();
  }, [userInfo, currentWeekStart, weekDates]);
  
  // Handle half-hour block clicks
  const handleTopHalfClick = (dateString, hour) => {
    // Create a copy of availability state
    const newAvailability = JSON.parse(JSON.stringify(availability));
    
    // Make sure the date array exists
    if (!newAvailability[dateString]) {
      newAvailability[dateString] = Array(11).fill(0);
    }
    
    // Get current value for this time slot
    const currentValue = newAvailability[dateString][hour] || 0;
    
    // Toggle top half
    if (currentValue === 0 || currentValue === 2) {
      // Add top half
      newAvailability[dateString][hour] = currentValue + 1;
    } else {
      // Remove top half
      newAvailability[dateString][hour] = currentValue - 1;
    }
    
    // Update state
    setAvailability(newAvailability);
    setChanged(true);
  };
  
  const handleBottomHalfClick = (dateString, hour) => {
    // Create a copy of availability state
    const newAvailability = JSON.parse(JSON.stringify(availability));
    
    // Make sure the date array exists
    if (!newAvailability[dateString]) {
      newAvailability[dateString] = Array(11).fill(0);
    }
    
    // Get current value for this time slot
    const currentValue = newAvailability[dateString][hour] || 0;
    
    // Toggle bottom half
    if (currentValue === 0 || currentValue === 1) {
      // Add bottom half
      newAvailability[dateString][hour] = currentValue + 2;
    } else {
      // Remove bottom half
      newAvailability[dateString][hour] = currentValue - 2;
    }
    
    // Update state
    setAvailability(newAvailability);
    setChanged(true);
  };
  
  // Save availability to Supabase
  const handleSave = async () => {
    if (!userInfo || !userInfo.id) return;
    
    setIsLoading(true);
    
    try {
      // Also save the timezone to Airtable
      if (selectedTimezone && selectedTimezone.value) {
        // Update the user's timezone in Airtable
        await airtable.teams.update(userInfo.id, {
          "Time Zone": [selectedTimezone.value]
        });
      }
      
      // Save each day's availability separately to Supabase
      const savePromises = Object.keys(availability).map(dateString => {
        return cnSupabase.hostAvailability.saveAvailability(
          userInfo.id,
          dateString,
          JSON.stringify(availability[dateString])
        );
      });
      
      await Promise.all(savePromises);
      
      toast.success("Availability has been saved successfully!");
      setChanged(false);
    } catch (error) {
      console.error("Error saving availability:", error);
      toast.error("Failed to save availability. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  // Format time for display
  const formatTime = (hour) => {
    const adjustedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
    return `${adjustedHour}`;
  };
  
  // Format time for display in the selected cells
  const formatTimeRange = (hour, isFirstHalf = true) => {
    // Start time
    const baseHourStart = hour + 8; // Adjust to actual hour (8 AM to 6 PM)
    const adjustedHourStart = baseHourStart > 12 ? baseHourStart - 12 : baseHourStart;
    const minutesStart = isFirstHalf ? '00' : '30';
    
    // End time
    let baseHourEnd = isFirstHalf ? hour + 8 : hour + 9;
    const adjustedHourEnd = baseHourEnd > 12 ? baseHourEnd - 12 : baseHourEnd;
    const minutesEnd = isFirstHalf ? '30' : '00';
    const ampmEnd = baseHourEnd >= 12 ? 'PM' : 'AM';
    
    return `${adjustedHourStart}:${minutesStart} - ${adjustedHourEnd}:${minutesEnd} ${ampmEnd}`;
  };
  
  return (
    <div className="sh-availability" style={{ padding: '20px' }}>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mb-0">Weekly Availability Scheduler</h2>
          <div>
            {userInfo && userInfo['Status'] !== 'Session Host' && !userInfo['Primary Session Host'] && (
              <a href="../session-host/team-viewer" className="btn btn-primary btn-sm">
                Host Team Viewer
              </a>
            )}
          </div>
        </div>
        <p className="text-muted">Set your availability for specific weeks using the calendar below.</p>
      </div>
      
      <div className="week-selector">
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={async () => {
            if (!isCurrentWeek && !isLoading) {
              await goToPreviousWeek();
            }
          }}
          disabled={isCurrentWeek || isLoading}
          title={isCurrentWeek ? "Cannot go before current week" : "Go to previous week"}
        >
          <i className="fa fa-chevron-left" />
        </Button>
        
        <div className="week-display">
          <span>Week of {formatDate(currentWeekStart)}</span>
          {isCurrentWeek && <span className="current-week-badge ml-2">Current Week</span>}
        </div>
        
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={async () => {
            if (!isLoading) {
              await goToNextWeek();
            }
          }}
          disabled={isLoading}
        >
          <i className="fa fa-chevron-right" />
        </Button>
      </div>
      
      <div className="timezone-info">
        <div className="d-flex align-items-center justify-content-between">
          <FormGroup className="mb-0 timezone-selector">
            <div className="d-flex align-items-center flex-column flex-md-row">
              <label className="mr-md-2 mb-2 mb-md-0">What time zone do you live in?</label>
              <Select
                className="react-select-container timezone-select"
                classNamePrefix="react-select"
                value={selectedTimezone}
                onChange={(selectedOption) => {
                  // Only prompt if they're actually changing their timezone
                  if (selectedOption && (!selectedTimezone || selectedOption.value !== selectedTimezone.value)) {
                    // Show confirmation dialog
                    const confirmed = window.confirm(
                      "Are you sure you want to change your timezone? " +
                      "This will affect how your availability is interpreted. " +
                      "Only change this if you've moved to a different timezone."
                    );
                    
                    if (confirmed) {
                      setSelectedTimezone(selectedOption);
                      setChanged(true);
                    }
                  }
                }}
                options={totalTimezones
                  .filter(tt => tt["Connected North System"].includes("Canada"))
                  .map(tz => ({
                    value: tz.id,
                    label: tz["Connected North System"]
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))
                }
              />
            </div>
          </FormGroup>
          
          <Button
            color="primary"
            className="copy-button ml-2"
            onClick={async () => {
              if (isCurrentWeek) {
                toast.warning("You're currently on the first week. There's no previous week to copy from.");
                return;
              }
              
              // Ask for confirmation
              const confirmed = window.confirm(
                "This will copy your availability from the previous week. Any unsaved changes to the current week will be lost. Continue?"
              );
              
              if (!confirmed) return;
              
              setIsLoading(true);
              
              try {
                // Calculate date range for previous week
                const prevWeekStart = currentWeekStart.subtract(1, 'week');
                const startDate = formatDateForDB(prevWeekStart);
                const endDate = formatDateForDB(prevWeekStart.add(4, 'day'));
                
                // Fetch data from previous week
                const prevAvailabilityData = await cnSupabase.hostAvailability.getByHostAndDateRange(
                  userInfo.id,
                  startDate,
                  endDate
                );
                
                // Initialize new week structure with same days of the week
                const newAvailability = {};
                weekDates.forEach((date, i) => {
                  const dateString = formatDateForDB(date);
                  // Default to empty slots
                  newAvailability[dateString] = Array(11).fill(0);
                  
                  // Find the corresponding day of the week from previous week
                  if (prevAvailabilityData && prevAvailabilityData.length > 0) {
                    const prevDate = formatDateForDB(prevWeekStart.add(i, 'day'));
                    const prevDayData = prevAvailabilityData.find(record => record.date === prevDate);
                    
                    if (prevDayData) {
                      try {
                        const availArray = JSON.parse(prevDayData.availability);
                        newAvailability[dateString] = availArray;
                      } catch (e) {
                        console.error("Error parsing previous week JSON:", e);
                      }
                    }
                  }
                });
                
                // Update state with the copied availability
                setAvailability(newAvailability);
                setChanged(true);
                
                toast.success("Availability copied from previous week!");
              } catch (error) {
                console.error("Error copying from previous week:", error);
                toast.error("Failed to copy from previous week. Please try again.");
              } finally {
                setIsLoading(false);
              }
            }}
            disabled={isLoading || isCurrentWeek}
          >
            <i className="fa fa-copy mr-1" /> Copy from Previous Week
          </Button>
        </div>
      </div>
      
      {isLoading ? (
        <div className="text-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="availability-grid">
          <div className="days-header">
            <div className="time-label"></div>
            {weekDates.map((date, i) => (
              <div key={i} className="day-label">
                <div className="day-name">
                  <span className="desktop-day">{weekDays[i]}</span>
                  <span className="mobile-day">{weekDaysMobile[i]}</span>
                </div>
                <div className="date">
                  <span className="desktop-date">{date.format('MMMM D')}</span>
                  <span className="mobile-date">{date.format('MMM D')}</span>
                </div>
              </div>
            ))}
          </div>
          
          <div className="time-slots">
            {Array.from({ length: 11 }).map((_, hour) => (
              <div key={hour} className="time-row">
                <div className="time-label">
                  {formatTime(hour + 8)}
                  <span className="am-pm">{hour + 8 >= 12 ? 'PM' : 'AM'}</span>
                </div>
                
                {weekDates.map((date, dayIndex) => {
                  const dateString = formatDateForDB(date);
                  
                  // Get availability value (0: none, 1: first half, 2: second half, 3: full hour)
                  const availValue = (availability[dateString] && availability[dateString][hour]) || 0;
                  
                  // Check if top and bottom halves are selected
                  const hasTopHalf = availValue === 1 || availValue === 3;
                  const hasBottomHalf = availValue === 2 || availValue === 3;
                  
                  // We'll take a different approach to identify true consecutive blocks
                  // Thinking of half-hours as a continuous timeline
                  
                  // Maps hour position to a timeline position (hour 0, first half = position 0)
                  const getTimePosition = (hour, isFirstHalf) => hour * 2 + (isFirstHalf ? 0 : 1);
                  
                  // Function to check if a half-hour slot is selected
                  const isSlotSelected = (dateStr, position) => {
                    const hourPos = Math.floor(position / 2);
                    const isFirst = position % 2 === 0;
                    
                    if (hourPos < 0 || hourPos > 10) return false;
                    
                    const value = (availability[dateStr] && availability[dateStr][hourPos]) || 0;
                    
                    if (isFirst) {
                      return value === 1 || value === 3; // First half or full hour
                    } else {
                      return value === 2 || value === 3; // Second half or full hour
                    }
                  };

                  // Determine if this is the first or last half-hour in a continuous sequence
                  let isFirstInSequence = false;
                  let isLastInSequence = false;
                  let displayFirstHalfDate = false;
                  let displayLastHalfDate = false;

                  // Check for first in sequence
                  if (hasTopHalf) {
                    const topPosition = hasTopHalf ? getTimePosition(hour, true) : -1;
                    isFirstInSequence = !isSlotSelected(dateString, topPosition - 1);
                    isLastInSequence = !isSlotSelected(dateString, topPosition + 1);
                    displayFirstHalfDate = isFirstInSequence || isLastInSequence;
                  }

                  // Check for last in sequence
                  if (hasBottomHalf) {
                    const bottomPosition = hasBottomHalf ? getTimePosition(hour, false) : -1;
                    isFirstInSequence = !isSlotSelected(dateString, bottomPosition - 1);
                    isLastInSequence = !isSlotSelected(dateString, bottomPosition + 1);
                    displayLastHalfDate = isFirstInSequence || isLastInSequence;
                  }

                  // Whether to show time in top or bottom half
                  const showTopTime = hasTopHalf && displayFirstHalfDate;
                  const showBottomTime = hasBottomHalf && displayLastHalfDate;

                  return (
                    <div
                      key={dayIndex}
                      className={`time-block ${availValue === 1 ? 'first-half' : ''} ${availValue === 2 ? 'second-half' : ''} ${availValue === 3 ? 'full-hour' : ''}`}
                    >
                      <div 
                        className="half-block top-half"
                        onClick={() => handleTopHalfClick(dateString, hour)}
                      >
                        {showTopTime && (
                          <span className="time-label-display">
                            {formatTimeRange(hour, true)}
                          </span>
                        )}
                      </div>
                      <div 
                        className="half-block bottom-half"
                        onClick={() => handleBottomHalfClick(dateString, hour)}
                      >
                        {showBottomTime && (
                          <span className="time-label-display">
                            {formatTimeRange(hour, false)}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )}
      
      <div className="action-buttons mt-4 d-flex">
        <Button 
          color="danger" 
          outline
          onClick={() => {
            // Ask for confirmation
            const confirmed = window.confirm(
              "Are you sure you want to clear all availability for this week? This will remove all your selected time slots."
            );
            
            if (!confirmed) return;
            
            // Create empty availability for each day of the week
            const emptyAvailability = {};
            weekDates.forEach(date => {
              const dateString = formatDateForDB(date);
              emptyAvailability[dateString] = Array(11).fill(0);
            });
            
            // Update state
            setAvailability(emptyAvailability);
            setChanged(true);
            
            // Notify user
            toast.info("All availability has been cleared. Don't forget to save your changes.");
          }}
          disabled={isLoading}
          className="clear-button mr-2"
        >
          Clear Availability
        </Button>
        
        <Button 
          color="primary" 
          onClick={handleSave} 
          disabled={!changed || isLoading}
          className="save-button"
        >
          {isLoading ? "Saving..." : "Save Availability"}
        </Button>
      </div>
    </div>
  );
};

export default WeeklyScheduler;