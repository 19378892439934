import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
  NewLightbulbCalenderIcon,
  NewSchoolZoomedIcon,
  NewPdPlanIcon,
  NewBarChartIcon,
  NewResourcesIcon,
  NewAccountIcon,
  NewSessionsIcon,
  NewUserIcon,
  NewDocumentIcon
} from "../../assets/img/icons/menu";
import {
  dashboardRoute,
  upcomingSessionsRoute,
  favoritesRoute,
  pendingRequestsRoute,
  searchRoute,
  collectionRoute,
  browseByCurriculumRoute,
  schoolResourcesRoute,
  newUserTourRoute,
  settingsRoute,
  pdPlanRoute,
  pdLogRoute,
  eventsRoute,
  principalRoute,
  principalReportsRoute,
  principalTeachersRoute,
  adminResourceOrdersRoute,
  schoolResourceOrdersRoute,
  resourceCartRoute,
  personalResources,
  schoolCalendarRoute,
  sessionCalendarRoute,
  personalAvailability,
  teamViewerAvailability,
  rateRoute,
  policiesRoute,
  contactRoute,
  bestPracticesRoute,
  availableSessionsRoute,
  mailRoute,
  generalResources,
  personalInfo,
  hostBestPracticesRoute,
  providerTourRoute,
  mySessionsRoute,
  plannerRoute,
  orientationRoute,
  sivuliaqtutRoute
} from "./constants";
import { getIsPDPlanUser } from "../../pages/PDPlan/util/get-is-pd-plan-user";
import {
  getIsPrincipal,
  getIsLimitedToCollection,
  getIsPreferredFrench,
  getIsResourceAdmin,
  hasMultipleSchools,
  getIsSessionPrincipal,
  getIsNunavutUser,
  getIsNunavutAdmin,
  getIsHostEmail
} from "./utils";
import useSignOut from "../../libs/hooks/use-sign-out";
import { generateAuthToken } from "../../utils/generateAuthToken";

export const useMainMenu = () => {
  const history = useHistory();
  const { userType, userInfo, isSessionHost } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [navs, setNavs] = useState([]);
  const [orientationUrl, setOrientationUrl] = useState(null);
  const { startTour, show } = useIntercom();
  const { preSignOut } = useSignOut();

  const onSignout = () => {
    preSignOut();
  };

  useEffect(() => {
    setNavs(getNavigations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const fetchOrientationUrl = async () => {
      try {
        const token = await generateAuthToken(userInfo.id, userType);
        const encodedToken = encodeURIComponent(token);
        const route = orientationRoute + `?token=${encodedToken}`;
        setOrientationUrl(route);
      } catch (error) {
        console.error("Error generating token URL:", error);
        setOrientationUrl(null);
      }
    };

    if (userInfo?.id && userType) {
      fetchOrientationUrl();
    }
  }, [userInfo, userType]);

  const getNavigations = () => {
    switch (userType) {
      case "Teacher":
        return getTeacherNavs();
      case "Team":
        return getTeamNavs();
      case "Provider":
        return getProviderNavs();
      case "Presenter":
        return getPresenterNavs();
      default:
        return [];
    }
  };

  // Providers Menu
  const getProviderNavs = () => {
    const ProviderMenu = [
      {
        title: t("my-sessions"),
        iconImg: <NewLightbulbCalenderIcon />,
        intercomLabel: "my-sessions-header",
        showMenu: true,
        subMenuItems: [
          {
            to: upcomingSessionsRoute,
            label: t("my-bookings"),
            tooltip: t("my-bookings"),
            intercomLabel: "my-bookings",
            showLink: true
          },
          {
            to: pendingRequestsRoute,
            label: t("requests"),
            tooltip: t("pending_session_requests"),
            intercomLabel: "requests",
            showLink: true
          },
          {
            to: mySessionsRoute,
            label: t("my-sessions"),
            tooltip: t("search-my-sessions"),
            intercomLabel: "my-sessions",
            showLink: true
          },
          {
            to: "/cn/my-payments",
            label: t("my-payments"),
            tooltip: t("my-payments-tooltip"),
            intercomLabel: "my-payments",
            showLink: userInfo["PPPP"]
          },
          {
            to: sessionCalendarRoute,
            label: t("session-calendar"),
            tooltip: t("session-calendar"),
            intercomLabel: "session-calendar",
            showLink: true
          }
        ]
      },
      {
        to: policiesRoute,
        label: t("policies-menu"),
        iconImg: <NewDocumentIcon />,
        intercomLabel: "Policies",
        showLink: true
      },
      {
        title: t("resources-title"),
        iconImg: <NewResourcesIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: generalResources,
            label: t("resources-title"),
            intercomLabel: "resources-title",
            showLink: true,
          },
          {
            to: bestPracticesRoute,
            label: t("best-practices"),
            intercomLabel: "best-practices",
            showLink: true
          },
        ]
      },
      {
        title: t("account"),
        iconImg: <NewUserIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: personalInfo,
            label: t("my-info"),
            tooltip: t("view-edit-contact-information"),
            intercomLabel: "my-info",
            showLink: true
          },
          {
            to: providerTourRoute,
            label: t("platform-tour"),
            containerClass: "platform-tour",
            intercomLabel: "platform-tour",
            showLink: true
          },
          {
            label: t("sign-out"),
            onClick: () => onSignout(),
            intercomLabel: "help-and-faqs",
            showLink: true
          }
        ],
      }
    ]
    return ProviderMenu
  }

  // Presenter Menu
  const getPresenterNavs = () => {
    const PresenterMenu = [
      {
        title: t("my-sessions"),
        iconImg: <NewLightbulbCalenderIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: upcomingSessionsRoute,
            label: t("my-bookings"),
            tooltip: t("my-bookings"),
            intercomLabel: "my-bookings",
            showLink: true
          },
        ]
      },
      {
        title: t("resources-title"),
        iconImg: <NewResourcesIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: generalResources,
            label: t("resources-title"),
            intercomLabel: "resources-title",
            showLink: true,
          },
          {
            to: bestPracticesRoute,
            label: t("best-practices"),
            intercomLabel: "best-practices",
            showLink: true
          },
        ]
      },
      {
        title: t("account"),
        iconImg: <NewUserIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: personalInfo,
            label: t("my-info"),
            tooltip: t("view-edit-contact-information"),
            intercomLabel: "my-info",
            showLink: true
          },
          {
            label: t("sign-out"),
            onClick: () => onSignout(),
            intercomLabel: "help-and-faqs",
            showLink: true
          }
        ],
      }
    ]
    return PresenterMenu
  }

  const getTeamNavs = () => {
    const TeamMenu = [
      {
        title: t("my-sessions"),
        iconImg: <NewLightbulbCalenderIcon />,
        showMenu: !isSessionHost,
        subMenuItems: [
          {
            to: upcomingSessionsRoute,
            label: t("upcoming"),
            tooltip: t("upcoming_sessions"),
            intercomLabel: "upcoming_sessions",
            showLink: true
          },
          {
            to: rateRoute,
            label: t("manage-past"),
            tooltip: t("manage-past-sessions"),
            showLink: true
          },
          {
            to: pendingRequestsRoute,
            label: t("requests"),
            tooltip: t("pending_session_requests"),
            intercomLabel: "requests",
            showLink: true
          },
        ]
      },
      {
        to: upcomingSessionsRoute,
        label: t("upcoming"),
        tooltip: t("upcoming_sessions"),
        iconImg: <NewLightbulbCalenderIcon />,
        intercomLabel: "upcoming_sessions",
        showLink: isSessionHost
      },
      {
        to: personalAvailability,
        label: t("my_availability"),
        tooltip: t("my_availability"),
        iconImg: <NewSchoolZoomedIcon />,
        showLink: isSessionHost || userInfo["Primary Session Host"]
      },
      {
        to: teamViewerAvailability,
        label: 'Host Availability',
        tooltip: 'Host Availability',
        iconImg: <NewSchoolZoomedIcon />,
        showLink: userInfo.id === 'rec73EHdAgSgXbdAj' || userInfo.id === 'recy8SrsgmXKRWko4'
      },
      {
        to: principalRoute,
        label: hasMultipleSchools(userInfo, isSessionHost) ? t("principal.heading-multi") : t("principal.heading"),
        iconImg: <NewSchoolZoomedIcon />,
        intercomLabel: "principal",
        showLink: getIsSessionPrincipal(userInfo, isSessionHost)
      },
      {
        title: t("explore_sessions"),
        iconImg: <NewSessionsIcon />,
        showMenu: !isSessionHost,
        subMenuItems: [
          {
            to: searchRoute,
            label: t("search"),
            tooltip: t("search_sessions"),
            intercomLabel: "search",
            showLink: true
          },
          {
            to: browseByCurriculumRoute,
            label: t("browse-by-curriculum"),
            intercomLabel: "browses-by-curriculum",
            showLink: true
          },
          {
            to: collectionRoute,
            label: t("explore-by-collection"),
            intercomLabel: "explore-by-collection",
            showLink: true
          },
        ]
      },
      {
        title: t("my-learning"),
        iconImg: <NewPdPlanIcon />,
        showMenu: true,
        subMenuItems: [
          {
            to: hostBestPracticesRoute,
            label: t("host-training"),
            intercomLabel: "Host Training",
            showLink: isSessionHost
          },
          {
            to: orientationUrl,
            label: "Nunavut Orientation",
            intercomLabel: "teacher-orientation",
            tooltip: "Nunavut Orientation",
            showLink: true,
            openInNewTab: true
          }
        ]
      },
      {
        to: eventsRoute,
        label: t("events"),
        tooltip: t("events"),
        intercomLabel: "events",
        iconImg: <NewAccountIcon />,
        showLink: !isSessionHost
      },
      {
        to: policiesRoute,
        label: t("policies-menu"),
        iconImg: <NewDocumentIcon />,
        intercomLabel: "Policies",
        showLink: true
      },
      {
        to: availableSessionsRoute,
        label: t("available-sessions"),
        iconImg: <NewUserIcon />,
        intercomLabel: "Available Sessions",
        showLink: isSessionHost && userInfo["Host Email"] === 'mfurdyk'
      },
      {
        to: mailRoute,
        label: t("my-email"),
        iconImg: <NewDocumentIcon />,
        openInNewTab: true,
        showLink: getIsHostEmail(userInfo),
      },
      {
        label: t("sign-out"),
        iconImg: <NewUserIcon />,
        onClick: () => onSignout(),
        showLink: isSessionHost,
      },
      {
        title: t("account"),
        iconImg: <NewUserIcon />,
        showMenu: !isSessionHost,
        subMenuItems: [
          {
            to: settingsRoute,
            label: t("my-settings"),
            intercomLabel: "my-settings",
            showLink: true
          },
          {
            to: contactRoute,
            label: t("contacts"),
            intercomLabel: "contacts",
            showLink: true
          },
          {
            label: t("sign-out"),
            onClick: () => onSignout(),
            showLink: true
          }
        ]
      }
    ]
    return TeamMenu;
  }

  // Teacher & Princpal Menu
  const getTeacherNavs = () => {
    const teacherMenu = [
      {
        to: dashboardRoute,
        label: t("dashboard"),
        iconImg: <NewBarChartIcon />,
        intercomLabel: "dashboard",
        showLink: true
      },
      {
        title: t("my-sessions"),
        iconImg: <NewLightbulbCalenderIcon />,
        intercomLabel: "my-sessions",
        showMenu: true,
        subMenuItems: [
          {
            to: upcomingSessionsRoute,
            label: t("upcoming-past-menu"),
            intercomLabel: "upcoming-sessions",
            tooltip: "my-sessions",
            showLink: true
          },
          {
            to: pendingRequestsRoute,
            label: t("pending-requests-menu"),
            tooltip: t("pending_session_requests"),
            intercomLabel: "requests",
            showLink: true
          },
          {
            to: favoritesRoute,
            label: t("menu-favourites"),
            tooltip: t("my-favourites"),
            intercomLabel: "menu-favourites",
            showLink: true
          }
        ]
      },
      {
        title: t("explore_sessions"),
        iconImg: <NewSessionsIcon />,
        intercomLabel: "explore-sessions",
        showMenu: true,
        subMenuItems: [
          {
            to: searchRoute,
            label: t("search"),
            tooltip: t("search_sessions"),
            intercomLabel: "search",
            showLink: !getIsLimitedToCollection(userInfo)
          },
          {
            to: collectionRoute,
            label: t("explore-by-collection"),
            intercomLabel: "explore-by-collection",
            showLink: !getIsPreferredFrench(userInfo)
          },
          {
            to: browseByCurriculumRoute,
            label: t("browse-by-curriculum"),
            intercomLabel: "browse-by-curriculum",
            showLink: !getIsPreferredFrench(userInfo)
          },
          {
            to: userInfo["Limit Booking to Collection"] ? `${collectionRoute}/${userInfo["Limit Booking to Collection"][0]}` : searchRoute,
            label: t("search"),
            tooltip: t("search_sessions"),
            intercomLabel: "search",
            showLink: getIsLimitedToCollection(userInfo)
          },
        ]
      },
      {
        title: t("learning-resources"),
        iconImg: <NewResourcesIcon />,
        showMenu: getIsResourceAdmin(userInfo) || userInfo["Beta Tester"],
        subMenuItems: [
          {
            to: schoolResourcesRoute,
            label: t("explore-resources"),
            intercomLabel: "explore-resources",
            tooltip: "Explore Resources",
            showLink: getIsResourceAdmin(userInfo) || userInfo["Beta Tester"]
          },
          {
            to: personalResources,
            label: t("my-resource"),
            intercomLabel: "my-resources",
            tooltip: "My Resources",
            showLink: getIsResourceAdmin(userInfo) || userInfo["Beta Tester"]
          },
          {
            to: resourceCartRoute,
            label: t("resource-cart"),
            intercomLabel: "resource-cart",
            tooltip: "Resource Cart",
            showLink: getIsResourceAdmin(userInfo) || userInfo["Beta Tester"]
          },
          {
            to: schoolResourceOrdersRoute,
            label: t("school-orders"),
            intercomLabel: "school-orders",
            tooltip: "School Orders",
            showLink: getIsPrincipal(userInfo)
          },
          {
            to: adminResourceOrdersRoute,
            label: t("resource-orders-admin"),
            intercomLabel: "resource-orders-admin",
            tooltip: "Order Administration",
            showLink: getIsResourceAdmin(userInfo)
          }
        ]
      },
      {
        title: hasMultipleSchools(userInfo, isSessionHost) ? t("principal.heading-multi") : t("principal.heading"),
        iconImg: <NewSchoolZoomedIcon />,
        showMenu: getIsPrincipal(userInfo),
        subMenuItems: [
          {
            to: principalTeachersRoute,
            label: 'Teachers & Staff',
            intercomLabel: "teachers-and-staff",
            showLink: true,
            onClick: () => {
              const currentUrl = window.location.href;
              if (currentUrl.includes('/cn/principal') && !currentUrl.includes('/cn/principal-reporting')) {
                setTimeout(() => {
                  if (window.principalHandleTabChange) {
                    window.principalHandleTabChange('Teachers & Staff');
                  }
                }, 0);
              } else {
                history.push('/cn/principal#teachers');
              }
            }
          },
          {
            to: principalReportsRoute,
            label: 'Monthly Reports',
            intercomLabel: "monthly-reports",
            showLink: getIsNunavutUser(userInfo),
            onClick: () => {
              const currentUrl = window.location.href;
              if (currentUrl.includes('/cn/principal') && !currentUrl.includes('/cn/principal-reporting')) {
                setTimeout(() => {
                  if (window.principalHandleTabChange) {
                    window.principalHandleTabChange('Monthly Reports');
                  }
                }, 0);
              } else {
                history.push('/cn/principal#reports');
              }
            }
          },
          {
            to: sessionCalendarRoute,
            label: t("session-calendar"),
            tooltip: t("session-calendar"),
            intercomLabel: "session-calendar",
            showLink: !getIsNunavutUser(userInfo)
          },
          {
            to: schoolCalendarRoute,
            label: t("school_calendar"),
            intercomLabel: "school-calendar",
            showLink: getIsNunavutUser(userInfo)
          },
          {
            to: plannerRoute,
            label: 'D & O Day Planner',
            intercomLabel: "planner",
            showLink: getIsNunavutAdmin(userInfo) && userInfo["Beta Tester"]
          },
        ]
      },
      {
        title: t("my-learning"),
        iconImg: <NewPdPlanIcon />,
        showMenu: getIsNunavutUser(userInfo),
        subMenuItems: [
          {
            to: pdPlanRoute,
            label: t("my-pd-plan"),
            labelInMobile: "PD Plan",
            showLink: getIsPDPlanUser(userInfo)
          },
          {
            to: pdLogRoute,
            label: t("my-pd-log"),
            labelInMobile: "PD Log",
            showLink: getIsPDPlanUser(userInfo)
          },
          {
            to: orientationUrl,
            label: t("teacher-orientation"),
            intercomLabel: "teacher-orientation",
            tooltip: "Teacher Orientation",
            showLink: getIsNunavutUser(userInfo),
            openInNewTab: true
          },
          {
            to: sivuliaqtutRoute,
            label: "Sivuliaqtut",
            intercomLabel: "sivuliaqtut",
            tooltip: "Sivuliaqtut",
            showLink: true
          },
        ]
      },
      {
        to: eventsRoute,
        label: t("events"),
        intercomLabel: "events",
        iconImg: <NewAccountIcon />,
        showLink: true
      },
      {
        title: t("account"),
        iconImg: <NewUserIcon />,
        intercomLabel: "account",
        showMenu: true,
        subMenuItems: [
          {
            to: settingsRoute,
            label: t("my-settings"),
            intercomLabel: "my-settings",
            showLink: true
          },
          {
            label: t("help-and-faqs"),
            onClick: () => show(),
            intercomLabel: "help-and-faqs",
            showLink: true
          },
          {
            to: newUserTourRoute,
            label: t("platform-tour"),
            containerClass: "platform-tour",
            onClick: () => startTour(458667),
            intercomLabel: "platform-tour",
            showLink: true
          },
          {
            label: t("sign-out"),
            onClick: () => onSignout(),
            intercomLabel: "help-and-faqs",
            showLink: true
          }
        ]
      }
    ];

    return teacherMenu;
  }

  return {
    navs,
  }
}