import React from "react";
import { Button } from "reactstrap";
import { setSession } from "../../utils/session";

const PermissionAsk = ({ initialize, closeToast }) => {
  return (
    <div className="permission-asking-toast">
      <strong>Would you like push notifications of upcoming sessions? You will be notified 48 hours and 1 hour before each session.</strong>
      <div className="permission-asking-actions">
        <Button
          color="primary"
          onClick={() => {
            setSession("CONNECTEDNORTH_PERMISSION_ASKED_STATUS", "granted");
            initialize();
            closeToast();
          }}
        >
          Enable Notification
        </Button>
        <Button
          color="outline-primary"
          onClick={() => {
            setSession("CONNECTEDNORTH_PERMISSION_ASKED_STATUS", "denied")
            closeToast();
          }}
        >
          No thanks
        </Button>
      </div>
    </div>
  )
}

export default PermissionAsk;