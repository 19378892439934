import React from "react";
import styles from "./styles.module.scss";

const NotificationMsg = ({ data, closeToast }) => {
  const { title, message, notificationData } = data;

  const manageNotification = () => {
    if (notificationData?.type === "upcoming_session") {
      window.location.href = "/cn/upcoming";
    }

    closeToast();
  }

  return (
    <div className={styles.wrapper} onClick={() => manageNotification()}>
      <div className={styles.logo}>
        <img src="/favicon.png" alt="Notification" />
      </div>
      <div className={styles.content}>
        <span>{title}</span>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default NotificationMsg;
