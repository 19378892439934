import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
);

const DonutChart = ({ title, sessions, labels: rawLabels, data: rawData }) => {

	const labels = [...rawLabels, 'None']
	const data = rawData ? [...rawData, rawData.some(d => d !== 0) ? 0 : 1] : []
	const dataObject = {
		labels: labels,
		datasets: [
			{
				label: "Dataset 1",
				data: data,
				backgroundColor: ["#63abfd", "#ffa5cb", "#F765A3", "#A155B9", "#FFA07A", "#FFD700", "#FF6347", "#FF4500", "#e0e0e0"],
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			emptyDoughnut: {
        color: 'rgba(255, 128, 0, 0.5)',
        width: 2,
        radiusDecrease: 20
      },
			legend: {
				display: true,
				position: "bottom",
				align: "center",
				labels: {
					font: {
						weight: "400",
						size: 14,
						family: "Effra, sans-serif",
					},
					color: "#0D1120",
					usePointStyle: true,
					pointStyle: "circle",
					boxWidth: 5,
					boxHeight: 5,
				},
			},
			title: {
				display: Boolean(title),
				text: title,
				position: "top",
				font: {
					weight: "500",
					size: 16,
					family: "Effra, sans-serif",
				},
				color: "#0D1120",
			},
		},
		cutout: "55%",
	};

	return (
		<div style={{ height: '300px', width: '100%' }}>
			<Doughnut data={dataObject} options={options} />
		</div>
	);
};

export default DonutChart;
