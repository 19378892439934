import { jsPDF } from "jspdf";
import { Document, Packer, Paragraph, TextRun, ImageRun } from 'docx';
import { saveAs } from 'file-saver';
import LOGO from "../assets/img/logo1.png";
import { getNiceFormatDateTimeString } from "./time";

export const handleDownloadParentLetterPDF = (session, userInfo, appLocale, dateType) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "letter",
  });

  let yPosition = 10;
  // Add the Connected North logo at the top
  doc.addImage(LOGO, "png", 10, yPosition, 120, 15);

  // Set font and text color for the title
  doc.setFontSize(24);
  doc.setTextColor(44, 37, 96); // Dark blue color as in your code
  doc.setFont("helvetica", "bold");
  // doc.text("Connected North Session", 33, yPosition + 23); // Title
  yPosition += 40

  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0); // Black text for body
  doc.setFont("helvetica", "normal");
  doc.text("Dear Caring Adults,", 10, yPosition);
  yPosition += 10

  const programDesc = "Connected North is an education program offered by a Canadian charity that delivers interactive video conferences to schools like ours. They connect guest speakers to students in a variety of subjects.";
  const splitProgramDesc = doc.splitTextToSize(programDesc, 190);
  doc.text(splitProgramDesc, 10, yPosition);
  yPosition += 15

  // Different text based on number of schools
  if (session["# Schools"] > 1) {
    doc.text(`I am writing to let you know that students will be participating in the following session:`, 10, yPosition);
  } else {
    doc.text(`I am writing to let you know that students in Grade ${session["Grade(s)"].slice(0, -1).join(", ")}${session["Grade(s)"].length > 1 ? " and " + session["Grade(s)"][session["Grade(s)"].length - 1] : session["Grade(s)"][0]} will be participating in the following session:`, 10, yPosition);
  }
  yPosition += 10;

  // Session Title
  const title = session["Custom Session Title"]
  ? session["Custom Session Title"]
  : session["Session Title Text"]
  ? session["Session Title Text"]
  : session["Session Title"]

  doc.setFont("helvetica", "bold");
  doc.text(`${title}`, 10, yPosition);
  yPosition += 8

  // Date and Time
  doc.setFont("helvetica", "normal");
  doc.text(`Date: ${getNiceFormatDateTimeString(
    new Date(session["Session Start Date/Time"]),
    appLocale,
    dateType,
  )}`, 10, yPosition);
  yPosition += 8


  // Provider Name
  doc.text(`Provider: ${session["Provider Name"]}`, 10, yPosition);
  yPosition += 8

  // Description
  doc.setFont("helvetica", "italic");
  const splitDescription = doc.splitTextToSize(
    `${session["Session Description"]}`,
    190
  );
  doc.text(splitDescription, 10, yPosition);
  yPosition += splitDescription.length * 5.5;

  doc.setFont("helvetica", "normal");
  doc.text("If you have any questions about this session, please contact me.", 10, yPosition);
  yPosition += 15;

  // Add teacher's name at the bottom
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.setTextColor(44, 37, 96); // Dark blue for teacher's name
  doc.text(`Thank You,`, 10, yPosition); // Near the bottom of the page
  doc.text(`${userInfo["Teacher Name"]}`, 10, yPosition + 7);

  // Save the PDF with a dynamic name
  doc.save(`Parent_Letter_${title?.[0]?.split(" ")?.join("_")}.pdf`);
};

export const handleDownloadParentLetterDocx = (session, userInfo, appLocale, dateType) => {
  // NOTE: We intentionally skip the logo for DOCX files to avoid compatibility issues with Word
  // Including the logo was causing "unreadable content" warnings in Microsoft Word
  generateDocument(session, userInfo, appLocale, dateType, null);
};

const generateDocument = (session, userInfo, appLocale, dateType, logoData) => {
  const title = session["Custom Session Title"]
    ? (Array.isArray(session["Custom Session Title"]) ? session["Custom Session Title"][0] : session["Custom Session Title"])
    : session["Session Title Text"]
    ? (Array.isArray(session["Session Title Text"]) ? session["Session Title Text"][0] : session["Session Title Text"])
    : session["Session Title"]
    ? (Array.isArray(session["Session Title"]) ? session["Session Title"][0] : session["Session Title"])
    : "Session";

  const description = session["Session Description"]
    ? (Array.isArray(session["Session Description"]) ? session["Session Description"][0] : session["Session Description"])
    : "";

  const doc = new Document({
    sections: [{
      properties: {
        page: {
          margin: {
            top: 720,
            right: 720,
            bottom: 720,
            left: 720,
          },
        },
      },
      children: [
        ...(logoData ? [new Paragraph({
          children: [
            new ImageRun({
              data: logoData,
              transformation: {
                width: 456,
                height: 57,
              },
            }),
          ],
          spacing: { after: 800 },
        })] : []),

        new Paragraph({
          children: [
            new TextRun({
              text: "Dear Caring Adults,",
              size: 24,
            }),
          ],
          spacing: { after: 200 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: "Connected North is an education program offered by a Canadian charity that delivers interactive video conferences to schools like ours. They connect guest speakers to students in a variety of subjects.",
              size: 24,
            }),
          ],
          spacing: { after: 300 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: session["# Schools"] > 1
                ? "I am writing to let you know that students will be participating in the following session:"
                : `I am writing to let you know that students in Grade ${session["Grade(s)"].slice(0, -1).join(", ")}${session["Grade(s)"].length > 1 ? " and " + session["Grade(s)"][session["Grade(s)"].length - 1] : session["Grade(s)"][0]} will be participating in the following session:`,
              size: 24,
            }),
          ],
          spacing: { after: 200 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: title,
              bold: true,
              size: 24,
            }),
          ],
          spacing: { after: 160 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: `Date: ${getNiceFormatDateTimeString(
                new Date(session["Session Start Date/Time"]),
                appLocale,
                dateType,
              )}`,
              size: 24,
            }),
          ],
          spacing: { after: 160 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: `Provider: ${session["Provider Name"]}`,
              size: 24,
            }),
          ],
          spacing: { after: 160 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: description,
              italics: true,
              size: 24,
            }),
          ],
          spacing: { after: 300 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: "If you have any questions about this session, please contact me.",
              size: 24,
            }),
          ],
          spacing: { after: 300 },
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: "Thank You,",
              bold: true,
              color: "2C2560",
              size: 24,
            }),
          ],
        }),

        new Paragraph({
          children: [
            new TextRun({
              text: userInfo["Teacher Name"],
              bold: true,
              color: "2C2560",
              size: 24,
            }),
          ],
        }),
      ],
    }],
  });

  Packer.toBlob(doc).then((blob) => {
    const title = session["Custom Session Title"] || session["Session Title Text"] || session["Session Title"];
    saveAs(blob, `Parent_Letter_${title?.[0]?.split(" ")?.join("_")}.docx`);
  });
};
