import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import './styles.scss'
import { useTranslation } from 'react-i18next';
import {
  GradeLevels,
  MAX_SUBJECT_NUM,
  SELECTBOX_STYLE,
  WellBeingLinks
} from '../../../utils/constants';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { distinctArray } from '../../../utils/array';
import Loading from '../../../components/Loading';
import useSessionBook from '../../../libs/hooks/use-session-book';
import SessionFullTimePicker from '../../../components/SessionFullTimePicker';

const StepFillInformation = ({
  previous,
  next,
  session,
  teachers,
  hosts,
  presenters,
  provider,
  loading,
  prefill = null,
}) => {
  const { totalSubjects, appLocale } = useSelector(state => state.appInfo)
  const [sessionDate, setSessionDate] = useState(null);
  const [alterSessionDate, setAlterSessionDate] = useState(null);
  const [status, setStatus] = useState("");
  const [length, setLength] = useState(session["Length (Minutes)"] || 0);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [cilcReqId, setCilcReqId] = useState("");
  const [studentNum, setStudentNum] = useState(0);
  const [host, setHost] = useState(null)
  const [wbLinks, setWBLinks] = useState([]);
  const [presenter, setPresenter] = useState(null);
  const [suppliesShipment, setSuppliesShipment] = useState(false);
  const [notes, setNotes] = useState("");
  const [hostNotes, setHostNotes] = useState(session["Default Host Notes"] || "");
  const [preferredTimeChanged, setPreferredTimeChanged] = useState(false);
  const [timeValid, setTimeValid] = useState("");
  const [showAlternativeDate, setShowAlternativeDate] = useState(false);
  const [useCustomLength, setUseCustomLength] = useState(false);

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const {
    loading: bookSessionLoading,
    supplies,
    dayLimitations,
    sessionLengthOptions,
    isHandsOnSession,
    initialDates,
    shouldDisableDate
  } = useSessionBook({
    providerSession: session,
    bookType: "create",
    teachers: teachers,
    suppliesShipment
  });

  const getSubjectName = (subject) => {
    return appLocale === 'fr' ? subject["Subject (FR)"] : subject["Subject"];
  }

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prefill && prefill["Presenters"] && prefill["Presenters"].length) {
      const p = presenters.find(t => t.id === prefill["Presenters"][0]);
      if (p) {
        setPresenter({ value: p.id, label: p.Name });
      }
    } else if (session["Default Presenter"] && presenters) {
      const defaultPresenter = presenters.find(p => p.id === session["Default Presenter"][0]);
      if (defaultPresenter) {
        setPresenter({ value: defaultPresenter.id, label: defaultPresenter.Name });
      }
    }
  }, [prefill, session, presenters]);

  useEffect(() => {
    if (initialDates.length === 0) return;

    setSessionDate(initialDates[0]);
    setAlterSessionDate(initialDates[1]);
  }, [initialDates])

  const initialize = async () => {
    if (prefill && prefill["Well-being Link"]) {
      setWBLinks(prefill["Well-being Link"].map(link => ({ value: link, label: link })))
    } else if (session["Well-being Link"]) {
      setWBLinks(session["Well-being Link"].map(link => ({ value: link, label: link })))
    }

    const gs = teachers.reduce((result, teacher, i) => {
      return {
        grades: [...result.grades, ...(teacher["Grade(s) Taught"] || [])],
        subIds: [...result.subIds, ...(teacher["Subject(s) Taught"] || [])]
      }
    }, { grades: [], subIds: [] });

    if (prefill && prefill["Grade(s)"]) {
      setGrades(prefill["Grade(s)"])
    } else {
      setGrades(distinctArray(gs.grades))
    }

    if (prefill && prefill["Subject/Curriculum"]) {
      const subIds = prefill["Subject/Curriculum"];
      const ss = totalSubjects.filter(sub => subIds.indexOf(sub.id) >= 0);
      setSubjects(ss.map(s => ({ value: s.id, label: getSubjectName(s) })))
    } else {
      const subIds = gs.subIds;
      const ss = totalSubjects.filter(sub => subIds.indexOf(sub.id) >= 0);
      setSubjects(ss.map(s => ({ value: s.id, label: getSubjectName(s) })))
    }

    if (prefill) {
      if (prefill["Notes"]) setNotes(prefill["Notes"]);
      if (prefill["Session Host Notes"]) setHostNotes(prefill["Session Host Notes"]);
      if (prefill["Students"]) setStudentNum(prefill["Students"]);
      if (prefill["Length (Minutes)"]) setLength(prefill["Length (Minutes)"])
      if (prefill["Presenters"] && prefill["Presenters"].length) {
        const p = presenters.find(t => t.id === prefill["Presenters"][0]);
        setPresenter({ value: p.id, label: p.Name });
      }

      if (prefill["Supplies Shipment Not Required"]) setSuppliesShipment(prefill["Supplies Shipment Not Required"]);
      if (prefill["CILC Request ID"]) setCilcReqId(prefill["CILC Request ID"]);
    }
  }

  const onNext = () => {
    let errList = {}
    if (!preferredTimeChanged) {
      errList.sessionDate = t("sessionrequest.confirm-msg");
    }

    if (!length) errList.length = "Invalid Number";
    if (!studentNum) errList.studentNum = "Invalid Number";
    if (!grades || grades.length === 0) errList.grades = "Grade(s) field is required.";
    if (!subjects || subjects.length === 0) errList.subjects = "Subject(s) field is required.";

    if (Object.keys(errList).length) {
      setErrors(errList);
      return;
    }

    let infos = {
      sessionDate,
      status,
      length,
      studentNum,
      grades,
      subjects: subjects.map(sub => sub.value),
      cilcReqId,
      host: host ? host.value : null,
      wbLinks: wbLinks.map(l => l.value),
      suppliesShipment,
      presenter: presenter?.value || null,
      notes,
      hostNotes
    }

    // Only include alterSessionDate if it's actually set and the checkbox is checked
    if (showAlternativeDate && alterSessionDate) {
      infos.alterSessionDate = alterSessionDate;
    }

    next(infos);
  }

  const getDayLimitationString = () => {
    const limitations = provider["Day Limitations"];
    switch (limitations.length) {
      case 0:
        return "";
      case 1:
        return limitations[0];
      default:
        const subs = limitations.slice(0, limitations.length - 1);
        return subs.join(", ") + " and " + limitations[limitations.length - 1];
    }
  }

  const canNext = () => {
    if (loading) return false;
    if (!status) return false;
    if (timeValid !== "valid") return false;
    return true;
  }

  const renderTeacherCreditInfo = (teacher) => {
    if (!teacher) return null;

    let creditCalc = 0;
    let creditCost = 0;

    const providerGroups = session["Indigenous Group(s)"];
    const schoolGroups = teacher["School Indigenous Group(s)"];

    if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
      creditCalc = teacher['Credit Balance'] - session['Discounted Credits'];
      creditCost = session['Discounted Credits'];
    } else {
      creditCalc = teacher['Credit Balance'] - session['Credits'];
      creditCost = session['Credits'];
    }

    // Only show credit info if the session costs credits
    if (creditCost > 0) {
      return (
        <div className='credit-status'>
          <span className='text-left'>
            {teacher["Teacher Name"]}'s Available Credits: {teacher['Credit Balance']}<br></br>
            {t("session-credits")}: {creditCost}<br></br>
          </span>
          <span>
            {t("remaining-credits")}: {creditCalc}
          </span>
        </div>
      )
    }
  }

  // Check for out of stock supplies
  const outOfStockSupply = useMemo(() => {
    if (!supplies || !supplies.length) return null;
    
    return supplies.find(supply =>
      supply?.["Essential?"] === "Yes" &&
      supply?.["Supplies Type"] === "Kit per Student" &&
      (supply?.Status === "Sioux Lookout Storage" || supply?.Status === "Ottawa Storage" || supply?.Status === "Toronto Office Storage") &&
      (supply?.["Quantity Remaining"] || 0) <= 0
    );
  }, [supplies]);

  // Check for low stock supplies
  const lowSupply = useMemo(() => {
    if (!supplies || !supplies.length) return null;
    
    return supplies.find(supply =>
      supply?.["Essential?"] === "Yes" &&
      supply?.["Supplies Type"] === "Kit per Student" &&
      (supply?.Status === "Sioux Lookout Storage" || supply?.Status === "Ottawa Storage" || supply?.Status === "Toronto Office Storage") &&
      (supply?.["Quantity Remaining"] || 0) > 0 &&
      (supply?.["Quantity Remaining"] || 0) < 20
    );
  }, [supplies]);

  const getSupplyMessage = useMemo(() => {
    if (!supplies || !supplies.length) return null;

    if (outOfStockSupply) {
      return "The Required Supplies item for this Session is currently out of stock. As a result, the earliest possible booking date is preselected below, 60 days from today.";
    }

    if (lowSupply) {
      const quantity = lowSupply["Quantity Remaining"] || 0;
      if (session["No Requests when Supplies OOS"]) {
        return `There ${quantity === 1 ? 'is' : 'are'} currently ${quantity} kit${quantity === 1 ? '' : 's'} available for this Session. 
             If this is enough for the class you're booking for, you may select an earlier date. If not, this Session is not currently able to be booked, due to supplies not being available at this time due to seasonal supply issues. Please try again later!`;
      }
      return `There ${quantity === 1 ? 'is' : 'are'} currently ${quantity} kit${quantity === 1 ? '' : 's'} available for this Session. 
             If this is enough for the class you're booking for, you may select an earlier date. If not, the earliest recommended date, in 60 days, is selected by default below.`;
    } else {
      return "Due to the need for mailed Supplies for this Session, requests must be made 30 days in advance. The earliest available date is shown below.";
    }
  }, [outOfStockSupply, lowSupply, session, supplies]);

  return (
    <Fragment>
      <div className="step-content">
        {(!!provider && dayLimitations.length > 0) && (
          <div className="day-limitations-msg">
            <span>{provider.Name} is only available for bookings on {getDayLimitationString()}</span>
          </div>
        )}
        {(!!provider && provider['Other Limitations']) && (
          <div className="day-limitations-msg">
            <span>{provider['Other Limitations']}</span>
          </div>
        )}
        {(supplies?.length > 0) && (
          <div className="day-limitations-msg" style={{ 
            backgroundColor: lowSupply && session["No Requests when Supplies OOS"] ? '#f8d7da' : '#fff3cd', 
            padding: '10px', 
            borderRadius: '4px', 
            marginBottom: '10px' 
          }}>
            <span>
              {getSupplyMessage}
            </span>
          </div>
        )}
        {teachers.length === 1 && (renderTeacherCreditInfo(teachers[0]))}
        <Form>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label>Status</label>
                <Input
                  id="status"
                  type="select"
                  className="form-control"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  disabled={loading}
                >
                  <option></option>
                  <option>Booked</option>
                  <option>Wish List</option>
                  <option>In Planning</option>
                  <option>Pending Provider Response</option>
                  <option>Requested</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          {!bookSessionLoading && (
            <SessionFullTimePicker
              labels={[
                "Session Date & Time",
                "Add Alternative Session Date & Time"
              ]}
              preferTime={sessionDate}
              alterTime={alterSessionDate}
              sessionLen={length}
              shouldDisableDate={shouldDisableDate}
              provider={provider}
              providerSession={session}
              teachers={teachers}
              withAlterTime={(status === 'Requested' || status === 'Wish List' || status === 'In Planning' || status === 'Pending Provider Response')}
              inRow={true}
              onChange={(date, which) => {
                if (which === "prefer") {
                  setSessionDate(date);
                  setPreferredTimeChanged(true);
                } else {
                  setAlterSessionDate(date);
                }
              }}
              controlAlterTime={true}
              onValid={v => setTimeValid(v)}
              isAddAlterTime={showAlternativeDate}
              onChangeAlterDateShow={setShowAlternativeDate}
            />
          )}

          <Row>
            <Col sm={6}>
              <FormGroup>
                <label>{t("length")}</label>
                <div className={classNames({ "is-invalid": errors.length ? true : false })}>
                  {!useCustomLength ? (
                    <Select
                      className="form-style"
                      options={sessionLengthOptions}
                      value={sessionLengthOptions.find(option =>
                        typeof option.value === 'number' ? option.value === length : option.value === 'custom'
                      )}
                      onChange={(selected) => {
                        if (selected.value === 'custom') {
                          if (window.confirm(t("session.custom-length-confirmation",
                            { defaultValue: "Please confirm you have discussed a custom Session length with the provider?" }))) {
                            setUseCustomLength(true);
                          } else {
                            // Reset to default length if user cancels
                            setLength(session["Length (Minutes)"]);
                          }
                        } else {
                          setLength(selected.value);
                          setUseCustomLength(false);
                        }
                        let err = { ...errors };
                        delete err.length;
                        setErrors(err);
                      }}
                      defaultValue={sessionLengthOptions[0]}
                      isDisabled={loading}
                    />
                  ) : (
                    <Input
                      type="number"
                      className="form-control"
                      value={length}
                      placeholder={`Standard length: ${session["Length (Minutes)"]} minutes`}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setLength(isNaN(value) ? 0 : value);
                        let err = { ...errors };
                        if (isNaN(value) || value <= 0) {
                          err.length = "Invalid length";
                        } else {
                          delete err.length;
                        }
                        setErrors(err);
                      }}
                      disabled={loading}
                    />
                  )}
                </div>
                <div className="invalid-feedback">{errors.length}</div>
                {isHandsOnSession && (
                  <small className="text-muted mt-2 d-block" style={{ fontSize: '14px' }}>
                    Hands-on Art/Science Sessions require the full Session time ({session["Length (Minutes)"]} minutes) to ensure completion.
                  </small>
                )}
                {!isHandsOnSession && session["Minimum Length"] === session["Length (Minutes)"] && (
                  <small className="text-muted mt-2 d-block" style={{ fontSize: '14px' }}>
                    This Provider is unfortunately not able to accommodate requests for reduced Session length.
                  </small>
                )}
                {useCustomLength && (
                  <small className="text-warning mt-2 d-block" style={{ fontSize: '14px' }}>
                    {t("session.custom-length-warning",
                      { defaultValue: "Please ensure you have discussed this custom length with the provider before proceeding." })}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <label>How many students do you expect to attend?</label>
                <div className={classNames({ "is-invalid": errors.studentNum ? true : false })}>
                  <Input
                    type="number"
                    className="form-style"
                    value={studentNum}
                    min="0"
                    onChange={e => {
                      const res = Math.max(0, parseInt(e.target.value));
                      let err = { ...errors };
                      if (isNaN(res)) {
                        setStudentNum(0);
                        err.studentNum = "Invalid number";
                      } else {
                        err.studentNum = false;
                        delete err.studentNum;
                        setStudentNum(res);
                      }
                      setErrors(err);
                    }}
                    disabled={loading}
                  />
                </div>
                <div className="invalid-feedback">{errors.studentNum}</div>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <label>{t("grades")}</label>
            <div className={classNames({
              "is-invalid": errors.grades ? true : false
            })}>
              <Select
                isMulti
                className="form-style"
                value={grades.filter(g => g !== 'SSA').map(g => ({ value: g, label: g }))}
                onChange={(selectedOptions) => {
                  const gs = selectedOptions.map(option => option.value)
                  setGrades(gs)

                  let err = { ...errors };
                  if (gs.length) delete err.grades;
                  else err.grades = "Grade(s) field is required."
                  setErrors(err);
                }}
                options={GradeLevels.map(g => ({ value: g, label: g }))}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
              />
            </div>
            <div className="invalid-feedback">{errors.grades}</div>
          </FormGroup>

          <FormGroup>
            <label>{t("subjects")}</label>
            <div className={classNames({ "is-invalid": errors.subjects ? true : false })}>
              <Select
                isMulti
                className="form-style"
                value={subjects}
                onChange={(selectedOptions) => {
                  setSubjects(selectedOptions)

                  let err = { ...errors };
                  if (!selectedOptions.length) err.subjects = 'no-subjects'
                  else if (selectedOptions.length > MAX_SUBJECT_NUM) err.subjects = 'over'
                  else delete err.subjects;
                  setErrors(err);
                }}
                options={totalSubjects.map(subject => ({
                  value: subject.id,
                  label: getSubjectName(subject)
                }))}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
              />
            </div>
            <div className="invalid-feedback">
              {errors.subjects === 'over' ? `Please select a maximum of ${MAX_SUBJECT_NUM} Subject areas most closely aligned to this Session.` : "Subject field is required."}
            </div>
          </FormGroup>

          {(session["CILC?"] && session["CILC?"][0]) ? (
            <FormGroup>
              <label>CILC Request ID</label>
              <Input
                type="text"
                className="form-style"
                value={cilcReqId}
                onChange={e => setCilcReqId(e.target.value)}
                disabled={loading}
              />
            </FormGroup>
          ) : null}

          <FormGroup>
            <label>Session Host (optional)</label>
            <Select
              className="form-style"
              value={host}
              onChange={(selectedOption) => setHost(selectedOption)}
              options={hosts.map(host => ({ value: host.id, label: host.Name }))}
              styles={SELECTBOX_STYLE}
              isDisabled={loading}
            />
          </FormGroup>

          <FormGroup>
            <label>{t("well-being-links")}</label>
            <Select
              isMulti
              className="form-style"
              value={wbLinks}
              onChange={(selectedOptions) => setWBLinks(selectedOptions)}
              options={WellBeingLinks.map(link => ({
                value: link,
                label: t(link)
              }))}
              styles={SELECTBOX_STYLE}
              isDisabled={loading}
            />
          </FormGroup>

          {presenters && presenters.length ? (
            <FormGroup>
              <label>
                Presenter {session["Default Presenter"]
                  ? "(The default presenter is selected, only change if directed by Provider)"
                  : "(if known)"}
              </label>
              <Select
                className="form-style"
                value={presenter}
                onChange={(selectedOption) => setPresenter(selectedOption)}
                options={[
                  { value: null, label: "-- None --" },
                  ...presenters.map(p => ({ value: p.id, label: p.Name }))
                ]}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
                isClearable={true}
              />
            </FormGroup>
          ) : null}

          <div style={{ height: 10 }} />
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="notes">Notes for Provider</label>
                <Input
                  id="notes"
                  name="notes"
                  type="textarea"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="optional-textarea"
                  disabled={loading}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="notes">Notes/Context for Host</label>
                <Input
                  id="hostnotes"
                  name="hostnotes"
                  type="textarea"
                  value={hostNotes}
                  onChange={(e) => setHostNotes(e.target.value)}
                  className="optional-textarea"
                  disabled={loading}
                />
              </FormGroup>
            </Col>
          </Row>
          {session["Supplies"] && session["Supplies"].length ? (
            <FormGroup check>
              <Input type='checkbox' id="suppliesShipment" onChange={(e) => setSuppliesShipment(e.target.checked)} disabled={loading} />
              <Label check for="suppliesShipment" style={{ fontSize: 16 }}>If this is a rebooking of a session where the class already has the required materials, and/or you do not want this booking to generate a request for supplies to be mailed, please check this box!</Label>
            </FormGroup>
          ) : null}
        </Form>
      </div>
      <div className="step-actions">
        <Button
          outline
          onClick={previous}
          disabled={loading}
        >Back</Button>
        <Button
          color="primary"
          onClick={onNext}
          disabled={!canNext()}
        >{loading && <Loading size={20} />}&nbsp;Book</Button>
      </div>
    </Fragment>
  )
}

export default StepFillInformation;
