import React, { Fragment, useEffect, useRef, useState } from "react";
import CardsFooter from "../components/Footers/CardsFooter.js";
import { Redirect, Switch, Route } from "react-router-dom";
import {
	UpcomingStack,
	CalendarStack,
	DashboardStack,
	RateStack,
	PendingStack,
	ContactStack,
	SearchStack,
	MySessionsStack,
	BrowserCurriculumStack,
	BrowserPhotoStack,
	MyInfoStack,
	FavoritesStack,
	BestPracticeStack,
	MystatsStack,
	MySettingsStack,
	SurveyStack,
	ResourceStack,
	EventsStack,
	PDWeekStack,
	CurriculumDetailStack,
	BPReflectionStack,
	BPCodeConductStack,
	CancellationReschedulePolicyStack,
	BPIntroductionStack,
	StudentInteractivityStack,
	TraumaInformedApproachStack,
	CreativeCustomizedContentStack,
	CreditStack,
	PoliciesStack,
	ChatGPTActivityStack,
	AskQuestionStack,
	SHBestPracticeStack,
	SHAvailabilityStack,
	AvailableSessionsStack,
	BP2024IntroductionStack,
	BP2024SurveyStack,
	BP2024ReflectionStack,
	BP2024RoleExpectationsStack,
	BP2024LanguageRevitalizationStack,
	BP2024CancellationReschedulePolicyStack,
	PDPlanReviewerStack,
	PDLogReviewerStack,
	BP2024RecordingStack,
	PrincipalReportingStack,
	SchoolResourceStack,
	MyPaymentsStack,
} from "./routers";
import { Container } from "reactstrap";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import airtable from "../airtables";
import pdAirtable from "../airtables/PDAirtable.js";
import { useDispatch, useSelector } from "react-redux";
import SessionDetail from "./SessionDetail";
import ProviderDetail from "./ProviderDetail";
import LogoutScreen from "./Logout.js";
import TagCollection from "./Browser/Tag/index";
import { Collections } from "./Collections";
import Principal from "./Principal";
import SchoolCalendar from "./Principal/SchoolCalendar";
import DayPlanner from "./Principal/DayPlanner";
import EditCalendar from "./Principal/SchoolCalendar/EditCalendar";
import {
	setPresenterLoading,
	setPresenterUpcoming,
	setPresenterPast,
	setRegionsMap,
	setIsNewProvider,
	setTimezones,
	setTeamSchools,
	setGNUserInfo,
	setResourceCart,
	setResourceOrders,
	setMyResources,
	setFavouriteResources,
	setNotificationPermission,
	setNotificationToken,
} from "../redux/actions";
import { setPresentersForProvider, setTotalRegions } from "../redux/actions";
import { analytics } from "../firebase";
import ModalList from "./modals";
import AppSidebar from "../components/Sidebar";
import { MainNav } from "../components/MainNav";
import "./layout.scss";
import classNames from "classnames";
import { getDefaultUrl } from "../utils/url.js";
import { getIsPDPlanUser } from "./PDPlan/util/get-is-pd-plan-user.js";
import { checkNunavutTeacher } from "./PrincipalReporting/util/check-nunavut-user.js";
import PDPlanTeacher from './PDPlan/PDTeacher';
import PDLogTeacher from './PDPlan/PDLog';
import SchoolResourceDetailScreen from "./SchoolResources/detail";
import MyResourceDetailScreen from "./SchoolResources/my";
import ResourceCartScreen from "./SchoolResources/cart";
import SchoolResourceOrderListScreen from "./SchoolResources/school.jsx";
import AdminSchoolResourceOrderListScreen from "./SchoolResources/admin.jsx";
import SivuliaqtutCertification from "./Sivuliaqtut/index.js";
import NotificationConfig from "./Notification";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { configureUserPushToken } from "../libs/apis/index.js";
import { getUsername } from "../utils/string.js";
import { toast } from "react-toastify";
import NotificationMsg from "../components/NotificationMsg/index.js";
// These imports are temporarily commented out while teacher notifications are disabled
// Will be needed when teacher notifications are re-enabled
// import { getIsTeacherOnboardEnabled } from "./OnboardTeacher/utils/get-is-teacher-onboard-enabled.js";
// import { getIsIncompleteTeacher } from "./OnboardTeacher/utils/get-is-new-teacher.js";
// import { TEACHER_FIELDS } from "./OnboardTeacher/constants/teacher-fields.js";
import { getSession } from "../utils/session.js";
import PermissionAsk from "../components/NotificationMsg/permission.js";

const Page = (props) => {
	const [loading, setLoading] = useState("loading");
	const [isOpen, setIsOpen] = useState(() => {
		const savedState = localStorage.getItem('navbarOpen');
		return savedState === null ? true : savedState === 'true';
	});
	const { userType, userInfo, isSessionHost } = useSelector(
		(state) => state.appInfo,
	);
	const { path } = props.match;
	const url = window.location.href;
	const deviceWidth = window.innerWidth;
	const dispatch = useDispatch();
	const messagingUnsubscribeRef = useRef(null);
	const toastPromptRef = useRef(false);

	useEffect(() => {
		// Only initialize notifications for non-Teacher users
		// (Teacher notifications temporarily disabled until ready for production)
		if (userType !== "Teacher" && userInfo.id === 'rec73EHdAgSgXbdAj') {
			emitNotificationInitialize();
		}
		// Original Teacher notification logic preserved but commented out for future reference
		/*
		else {
			const isDistrictLeader = (userInfo["District Leader?"] ?? "").toLowerCase() === "true";
			const isIncompleteTeacher = getIsIncompleteTeacher(userInfo);

			if (userInfo["School P/T Text"] === "NU" &&
				(!userInfo["2FA"] || userInfo["2FA"] === "None") &&
				((userInfo["School Leader?"] === "true") || (userInfo["District Leader?"] === "true"))) {
			} else if (
				getIsTeacherOnboardEnabled() &&
				!isDistrictLeader &&
				!(!isIncompleteTeacher && userInfo[TEACHER_FIELDS.COMPLETED])
			) {
			} else {
				emitNotificationInitialize();
			}
		}
		*/

		(async () => {
			if (userInfo) {
				setLoading("signed");
				try {
					await Promise.all([
						setUserId(analytics, userInfo["Email"]),
						setUserProperties(analytics, "user_type", userType),
						logEvent(analytics, "login", {
							email: userInfo["Email"],
							userType,
						}),
					]);
					const rs = await airtable.regions.getAllRegions();
					rs.sort((a, b) => a["Long Name"].localeCompare(b["Long Name"]));
					dispatch(setTotalRegions(rs));

					if (rs && rs.length) {
						const m = {};
						rs.forEach((r) => (m[r.id] = r));
						dispatch(setRegionsMap(m));
					}

					if (
						userType === "Provider" &&
						userInfo["Presenters"] &&
						userInfo["Presenters"].length
					) {
						const ps = await airtable.presenters.listByIds(userInfo["Presenters"]);
						dispatch(setPresentersForProvider(ps));
					} else if (userType === "Team") {
						const tSchools = await airtable.schools.getTeamSchools(userInfo);
						dispatch(setTeamSchools(tSchools));
					} else if (userType === "Teacher" && userInfo["School P/T Text"] === "NU") {
						const uInfo = await pdAirtable.nunavutTeachers.getTeacherByOriginTeacherId(userInfo.id);
						if (uInfo) {
							dispatch(setGNUserInfo(uInfo));

							const resourceIds = uInfo["Favourited Resources"];
							if (resourceIds) {
								pdAirtable.resources.listByIds(resourceIds).then(rss => {
									dispatch(setFavouriteResources(rss));
								})
							}

							const cart = await pdAirtable.resourceRequests.getTeacherRequest(uInfo["Teacher Name"], uInfo.id);
							dispatch(setResourceCart(cart));

							// Get resources from orders
							let myResourceIds = [];
							const orderIds = uInfo["Resource Orders"];
							if (orderIds && orderIds.length) {
								const orders = await pdAirtable.resourceOrders.listByIds(orderIds);
								dispatch(setResourceOrders(orders));
								
								// Extract resource IDs from orders
								for (const order of orders) {
									if (order.Requests) {
										const requests = await pdAirtable.resourceRequests.listByIds(order.Requests);
										for (const request of requests) {
											if (request.Resource && request.Resource.length > 0) {
												myResourceIds = [...myResourceIds, ...request.Resource];
											}
										}
									}
								}
							}
							
							// Get downloaded resources
							const downloadedResourceIds = uInfo["Downloaded Resources"] || [];
							
							// Combine ordered and downloaded resources (removing duplicates)
							const combinedResourceIds = [...new Set([...myResourceIds, ...downloadedResourceIds])];
							
							if (combinedResourceIds.length > 0) {
								const resources = await pdAirtable.resources.listByIds(combinedResourceIds);
								dispatch(setMyResources(resources));
							}
						}
					}
				} catch (error) {
					console.log("firebase analytics error", error);
				}
			} else {
				setLoading("login");
			}

			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		})();

		return () => {
			if (messagingUnsubscribeRef.current) {
				messagingUnsubscribeRef.current();
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (userType === "Presenter" && userInfo) {
			if (
				userInfo["Sessions Delivered"] &&
				userInfo["Sessions Delivered"].length
			) {
				dispatch(setPresenterLoading(true));

				airtable.sessions.loadPresenterSessions(userInfo["Sessions Delivered"])
					.then(({ upcoming, past }) => {
						dispatch(setPresenterUpcoming(upcoming));
						dispatch(setPresenterPast(past));
						dispatch(setPresenterLoading(false));
					})
					.catch(error => dispatch(setPresenterLoading(false)))
			} else {
				dispatch(setPresenterLoading(false));
				dispatch(setPresenterUpcoming([]));
				dispatch(setPresenterPast([]));
			}
		}

		if (userType === "Provider" && userInfo) {
			dispatch(
				setIsNewProvider(
					userInfo.Status === "In Development" ||
					userInfo.Status === "Outreach started" ||
					userInfo["Total Delivered"] < 2,
				),
			);
		} else {
			dispatch(setIsNewProvider(false));
		}

		if (userType === 'Team') {
			airtable.timezones.listAll().then(res => {
				dispatch(setTimezones(res));
			})
		}
	}, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

	const emitNotificationInitialize = async () => {
		const nInitialize = () => {
			if (window.isMobile) {
				window.updateNotificationInfo = updateNotificationInfo;
				window.postNotificationMessage = (data) => {
					const payload = JSON.parse(data);
					postNotificationMessage(payload);
				};
				window.ReactNativeWebView.postMessage("RequestNotificationInfo");
			} else {
				isSupported().then(supported => {
					if (supported) {
						const messaging = getMessaging();
						Notification.requestPermission().then(permission => {
							if (permission === "granted") {
								getToken(messaging, { vapidKey: "BOwHBS8e2Fw0kh1I4xArraDIvsEGvNcwQVE7H1Ag-t0ctXKXwK-BrMhKFGhBtFT8int5STwY_DFK6KxI1I72PtE" }).then(token => {
									updateNotificationInfo("web", permission, token);
								})
							} else {
								updateNotificationInfo("web", permission, "");
							}
						});

						messagingUnsubscribeRef.current = onMessage(messaging, (payload) => {
							postNotificationMessage(payload);
						});
					}
				})
			}
		}

		const permissionAsked = await getSession("CONNECTEDNORTH_PERMISSION_ASKED_STATUS");
		if (permissionAsked === "granted") {
			nInitialize();
		} else if (!permissionAsked && !toastPromptRef.current) {
			toast.info(<PermissionAsk initialize={nInitialize} />,
				{
					position: "top-right",
					hideProgressBar: true,
					autoClose: false,
					closeButton: false,
				}
			);
			toastPromptRef.current = true;
		}
	}

	const updateNotificationInfo = (platform, perm, token) => {
		dispatch(setNotificationPermission(perm));
		dispatch(setNotificationToken(token));

		if (perm === "granted") {
			configureUserPushToken({
				userId: userInfo.id,
				name: getUsername(userInfo, userType),
				type: userType,
				platform: platform,
				token: token
			});
		}
	}

	const postNotificationMessage = (payload) => {
		toast(NotificationMsg, {
			data: {
				title: payload.notification.title,
				message: payload.notification.body,
				notificationData: payload.data
			},
			hideProgressBar: true
		})
	}

	const getDefaultPage = (path) => {
		return `${path}${getDefaultUrl(userType, userInfo)}`;
	};

	if (loading === "loading") return <div>loading</div>;

	if (loading === "login") {
		return url ? (
			<Redirect to={`/login?url=${url}`} />
		) : (
			<Redirect to="/login" />
		);
	}

	//const showUpdatedNav = userInfo && ((userType === 'Teacher' && userInfo?.["School Province or Territory"]?.[0] === 'NU') || userType === 'Team not ready' || userType === 'Team');
	const showUpdatedNav = userInfo && (
		userType === 'Team' ||
		userType === 'Teacher' ||
		userType === 'Provider' ||
		window.location.hostname === 'localhost' ||
		userInfo["Beta Tester"] ||
		userInfo["Resource Administrator"]
	);

	if (!userInfo) return null;

	return (
		<div className={classNames({
			"layout-wrapper": true,
			"sidebar-callapsed": !isOpen
		})}>
			{showUpdatedNav && (deviceWidth >= '480') && (
				<MainNav
					path={path}
					isOpen={isOpen}
					shown={(v) => setIsOpen(v)}
					toggleNavbar={() => {
						const newState = !isOpen;
						setIsOpen(newState);
						localStorage.setItem('navbarOpen', newState);
					}}
				/>
			)}
			{(!showUpdatedNav || (deviceWidth < '480')) && (
				<AppSidebar
					path={path}
					isOpen={isOpen}
					shown={(v) => setIsOpen(v)}
					toggleNavbar={() => setIsOpen(!isOpen)}
				/>
			)}
			<main className="main-frame" id="main-frame">
				<section className="main-content-wrapper" id="main-content-wrapper">
					<Container>
						<Switch>
							<Redirect
								from={`${path}/`}
								exact
								to={getDefaultPage(path)}
							/>

							<Route
								path={`${path}/`}
								exact
								component={
									userType === "Provider"
										? MySessionsStack
										: UpcomingStack
								}
							/>

							<Route
								path={`${path}/notification-config`}
								component={NotificationConfig}
							/>

							<Route
								path={`${path}/upcoming`}
								component={UpcomingStack}
							/>
							<Route
								path={`${path}/calendar`}
								component={CalendarStack}
							/>
							<Route
								path={`${path}/rate`}
								component={RateStack}
							/>
							<Route
								path={`${path}/pending`}
								component={PendingStack}
							/>
							<Route
								path={`${path}/contact/:actionType`}
								component={ContactStack}
							/>
							<Route
								path={`${path}/contact`}
								component={ContactStack}
							/>
							<Route
								path={`${path}/browser/curriculum`}
								component={BrowserCurriculumStack}
							/>
							<Route
								path={`${path}/browser/photo`}
								component={BrowserPhotoStack}
							/>
							<Route
								path={`${path}/collection/:tagId`}
								component={TagCollection}
							/>
							<Route
								path={`${path}/collection/`}
								component={Collections}
							/>
							<Route
								path={`${path}/session/:sessionId`}
								exact
								component={SessionDetail}
							/>
							<Route
								path={`${path}/provider/:providerId`}
								exact
								component={ProviderDetail}
							/>
							<Route
								path={`${path}/curriculum/:cId`}
								exact
								component={CurriculumDetailStack}
							/>
							<Route
								path={`${path}/logout`}
								exact
								component={LogoutScreen}
							/>
							<Route
								path={["/cn/aiactivities/:pSessionId", "/cn/aiactivities/:pSessionId/:sSessionId"]}
								exact
								component={ChatGPTActivityStack}
							/>

							{userType === "Teacher" && (
								<>
									<Route
										path={`${path}/favorites`}
										component={FavoritesStack}
									/>
									<Route
										path={`${path}/mystats`}
										component={MystatsStack}
									/>
									<Route
										path={`${path}/mysettings`}
										component={MySettingsStack}
									/>
									<Route
										path={`${path}/events`}
										component={EventsStack}
									/>
									<Route
										path={`${path}/dashboard`}
										component={DashboardStack}
									/>
									<Route
										path={`${path}/credits`}
										component={CreditStack}
									/>
									<Route
										path={`${path}/ask-a-question/:sessionId`}
										component={AskQuestionStack}
									/>
									<Route
										path={`${path}/principal`}
										component={Principal}
									/>
									<Route
										path={`${path}/schoolcalendar`}
										component={SchoolCalendar}
										exact
									/>
									<Route
										path={`${path}/schoolcalendar/edit`}
										component={EditCalendar}
									/>
									<Route
										path={[`${path}/schoolcalendar/planner`, `${path}/schoolcalendar/planner/:schoolId`]}
										render={(props) => <DayPlanner {...props} />}
										exact
									/>

									{userInfo["School P/T Text"] === "NU" && (
										<Fragment>
											<Route
												exact
												path={`${path}/school-resources`}
												component={SchoolResourceStack}
											/>

											<Route
												path={`${path}/school-resources/:resourceId`}
												component={SchoolResourceDetailScreen}
											/>

											<Route
												path={`${path}/my-resources`}
												component={MyResourceDetailScreen}
											/>

											<Route
												path={`${path}/resource-cart`}
												component={ResourceCartScreen}
											/>

											<Route
												path={`${path}/school-resource-orders`}
												component={SchoolResourceOrderListScreen}
											/>
										</Fragment>
									)}

									<Route
										path={`${path}/admin-resource-orders`}
										component={AdminSchoolResourceOrderListScreen}
									/>

									<Route
										path={`${path}/my-learning/sivuliaqtut`}
										exact
										component={SivuliaqtutCertification}
									/>

									{getIsPDPlanUser(userInfo) && (
										<>
											<Route
												path={`${path}/pd-plan/:teacherId`}
												exact
												component={PDPlanReviewerStack}
											/>
											<Route
												path={`${path}/pd-plan`}
												exact
												component={PDPlanTeacher}
											/>
											<Route
												path={`${path}/pd-log`}
												exact
												component={PDLogTeacher}
											/>
											<Route
												path={`${path}/pd-log/:teacherId`}
												exact
												component={PDLogReviewerStack}
											/>
										</>
									)}
									{checkNunavutTeacher(userInfo) && (
										<>
											<Route
												path={`${path}/principal-reporting`}
												exact
												render={() => <Redirect to={`${path}/principal#reports`} />}
											/>
											<Route
												path={`${path}/principal-reporting/:responseId`}
												exact
												component={PrincipalReportingStack}
											/>
										</>
									)}

									{userInfo["School PT"] &&
										userInfo["School PT"][0] ===
										"recalRmA0o3OZhdlO" && (
											<Route
												path={`${path}/pd-week`}
												component={PDWeekStack}
											/>
										)}
									{!(
										userInfo["Limit Booking to Collection"] &&
										userInfo["Limit Booking to Collection"].length
									) ? (
										<Route
											path={`${path}/search`}
											component={SearchStack}
										/>
									) : null}
								</>
							)}

							{userType === "Team" && (
								<>
									<Route
										path={`${path}/events`}
										component={EventsStack}
									/>
									<Route
										path={`${path}/search`}
										component={SearchStack}
									/>
									<Route
										path={`${path}/credits/:teacherId`}
										render={(props) => props.location.pathname !== `${path}/credits/earn-more` ? <CreditStack /> : null}
									/>
									<Route
										path={`${path}/principal`}
										component={Principal}
									/>
									<Route
										path={`${path}/ask-a-question/:sessionId`}
										component={AskQuestionStack}
									/>
									<Route
										path={`${path}/mysettings`}
										component={MySettingsStack}
									/>
									<Route
										path={`${path}/policies`}
										component={PoliciesStack}
									/>
									<Route
										path={`${path}/session-host/availability/:hostId?`}
										component={SHAvailabilityStack}
									/>
									<Route
										path={`${path}/session-host/availability-schedule`}
										render={(routeProps) => {
											const WeeklyScheduler = require('./SHAvailability/weekly-scheduler').default;
											return <WeeklyScheduler {...routeProps} />;
										}}
									/>
									<Route
										path={`${path}/session-host/team-viewer`}
										render={(routeProps) => {
											const TeamViewerScheduler = require('./SHAvailability/team-viewer-scheduler').default;
											return <TeamViewerScheduler {...routeProps} />;
										}}
									/>
									<Route
										path={`${path}/session-host/test-block`}
										render={(routeProps) => {
											const TestBlock = require('./SHAvailability/test-block').default;
											return <TestBlock {...routeProps} />;
										}}
									/>
									{isSessionHost && (
										<Fragment>
											<Route
												path={`${path}/available-sessions`}
												component={AvailableSessionsStack}
											/>
											<Route
												path={`${path}/session-host/best-practices/:page`}
												component={SHBestPracticeStack}
											/>
										</Fragment>
									)}
								</>
							)}

							{userType === "Provider" && (
								<>
									<Route
										path={`${path}/survey`}
										component={SurveyStack}
									/>
									<Route
										path={`${path}/mysessions`}
										component={MySessionsStack}
									/>
									<Route
										path={`${path}/my-payments`}
										component={MyPaymentsStack}
									/>
									<Route
										path={`${path}/dashboard`}
										component={DashboardStack}
									/>
									<Route
										path={`${path}/best-practices/:page`}
										component={BestPracticeStack}
									/>
									<Route
										path={`${path}/best-practices-2023/introduction`}
										component={BPIntroductionStack}
									/>
									<Route
										path={`${path}/best-practices-2023/reflection`}
										component={BPReflectionStack}
									/>
									<Route
										path={`${path}/best-practices-2023/code-of-conduct`}
										component={BPCodeConductStack}
									/>
									<Route
										path={`${path}/best-practices-2023/cancellation-reschedule-policy`}
										component={CancellationReschedulePolicyStack}
									/>
									<Route
										path={`${path}/myinfo`}
										component={MyInfoStack}
									/>
									<Route
										path={`${path}/resources/facilitation-of-student-interactivity`}
										component={StudentInteractivityStack}
									/>
									<Route
										path={`${path}/resources/trauma-informed-approaches`}
										component={TraumaInformedApproachStack}
									/>
									<Route
										path={`${path}/resources/creatively-customized-content`}
										component={CreativeCustomizedContentStack}
									/>
									<Route
										path={`${path}/resources`}
										component={ResourceStack}
									/>
									<Route
										path={`${path}/policies`}
										component={PoliciesStack}
									/>

									{/* 2024 best practices routes */}
									<Route
										path={`${path}/best-practice/2024/introduction`}
										component={BP2024IntroductionStack}
									/>
									<Route
										path={`${path}/best-practice/2024/survey`}
										component={BP2024SurveyStack}
									/>
									<Route
										path={`${path}/best-practice/2024/reflection`}
										component={BP2024ReflectionStack}
									/>
									<Route
										path={`${path}/best-practice/2024/recording`}
										component={BP2024RecordingStack}
									/>
									<Route
										path={`${path}/best-practice/2024/language-revitalization`}
										component={BP2024LanguageRevitalizationStack}
									/>
									<Route
										path={`${path}/best-practice/2024/role-expectations`}
										component={BP2024RoleExpectationsStack}
									/>
									<Route
										path={`${path}/best-practice/2024/cancellation-reschedule-policy`}
										component={BP2024CancellationReschedulePolicyStack}
									/>
								</>
							)}

							{userType === "Presenter" && (
								<>
									<Route
										path={`${path}/best-practices/:page`}
										component={BestPracticeStack}
									/>
									<Route
										path={`${path}/myinfo`}
										component={MyInfoStack}
									/>
									<Route
										path={`${path}/resources/facilitation-of-student-interactivity`}
										component={StudentInteractivityStack}
									/>
									<Route
										path={`${path}/resources/trauma-informed-approaches`}
										component={TraumaInformedApproachStack}
									/>
									<Route
										path={`${path}/resources/creatively-customized-content`}
										component={CreativeCustomizedContentStack}
									/>
									<Route
										path={`${path}/resources`}
										component={ResourceStack}
									/>
								</>
							)}
						</Switch>
					</Container>
				</section>
				<CardsFooter />
				<ModalList />
			</main>
		</div>
	);
};

export default Page;