// Route Variables 
export const dashboardRoute = "/cn/dashboard";
export const upcomingSessionsRoute = "/cn/upcoming";
export const favoritesRoute = "/cn/favorites";
export const pendingRequestsRoute = "/cn/pending";
export const searchRoute = "/cn/search";
export const collectionRoute = "/cn/collection";
export const browseByCurriculumRoute = "/cn/browser/curriculum";
export const schoolResourcesRoute = "/cn/school-resources";
export const newUserTourRoute = "/cn/dashboard?product_tour_id=458667";
export const providerTourRoute = "/cn/upcoming?product_tour_id=466709";
export const settingsRoute = "/cn/mysettings";
export const pdPlanRoute = "/cn/pd-plan";
export const eventsRoute = "/cn/events";
export const principalRoute = "/cn/principal";
export const principalReportsRoute = "/cn/principal#reports";
export const principalTeachersRoute = "/cn/principal#teachers";
export const adminResourceOrdersRoute = "/cn/admin-resource-orders";
export const schoolResourceOrdersRoute = "/cn/school-resource-orders";
export const resourceCartRoute = "/cn/resource-cart";
export const personalResources = "/cn/my-resources";
export const sessionCalendarRoute = "/cn/calendar";
export const schoolCalendarRoute = "/cn/schoolcalendar";
export const personalAvailability = "/cn/session-host/availability-schedule";
export const teamViewerAvailability = "/cn/session-host/team-viewer";
export const hostBestPracticesRoute = "/cn/session-host/best-practices/introduction"
export const rateRoute = "/cn/rate";
export const policiesRoute = "/cn/policies";
export const pdLogRoute = "/cn/pd-log";
export const plannerRoute = "/cn/schoolcalendar/planner";
export const contactRoute = "/cn/contact";
export const availableSessionsRoute = "/cn/available-sessions"
export const mailRoute = "https://mail.google.com/a/connectednorth.org";
export const generalResources = "/cn/resources";
export const personalInfo = "/cn/myinfo";
export const bestPracticesRoute = "/cn/best-practices/introduction";
export const mySessionsRoute = "/cn/mysessions";
export const orientationRoute = "https://orientation.connectednorth.org/auth/callback";
export const sivuliaqtutRoute = "/cn/my-learning/sivuliaqtut";

