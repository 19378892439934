import {AIRTABLE_APP, AIRTABLE_KEY} from "./index"

export default class AirtableTable {
  constructor(instance, tableName) {
    this.instance = instance
    this.tableName = tableName;
    this.table = this.instance(this.tableName);
    this.fields = null;
    this.pageSize = 100;
  }

  create = (param) => {
    return new Promise((resolve, reject) => {
      this.table.create([{
        "fields": param
      }]).then(res => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        });
      }).catch(error => {
        reject(error)
      })
    })
  }

  createMultiple = (paramList) => {
    return new Promise((resolve, reject) => {
      this.table.create(paramList.map(param => ({ fields: param })))
        .then(res => {
          const records = res.map(item => ({
            ...item.fields,
            id: item.id
          }))
          resolve(records);
        }).catch(error => {
          reject(error)
        })
    })
  }

  select = (id) => {
    if (!id) return null;

    return new Promise((resolve, reject) => {
      this.table.find(id).then(record => {
        if (record && record._rawJson) {
          resolve({
            ...record.fields,
            id: record.id
          })
        } else {
          resolve(null)
        }
      }).catch(error => {
        if (error.error === 'NOT_FOUND') resolve(null);
        else reject(error.toString())
      })
    })
  }

  delete = (id) => {
    if (!id) return;
    return new Promise((resolve, reject) => {
      this.table.destroy([id]).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  update = (id, param) => {
    if (!id || !param || Object.values(param).length === 0) return;

    return new Promise((resolve, reject) => {
      this.table.update([{
        id, fields: param
      }]).then(res => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        })
      }).catch(error => {
        reject(error)
      })
    })
  }

  multipleUpdate = (infoList) => {
    return new Promise((resolve, reject) => {
      this.table.update(infoList.map(info => ({
        id: info.id, fields: info.param
      }))).then(res => {
        resolve(res.map(record => ({
          ...record.fields,
          id: record.id
        })))
      }).catch(error => {
        reject(error)
      })
    })
  }

  list = (condition, filter = null, allFields = false) => {
    return new Promise((resolve, reject) => {
      var result = [];
      let con = { ...condition };
      if (!condition.fields && this.fields && !allFields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      this.table.select(con).eachPage((records, fetchNextPage) => {
        records.forEach(record => {
          var item = {
            id: record.id,
            ...record.fields
          }
          if (!filter || filter(item)) {
            result.push(item);
          }
        })
        fetchNextPage()
      }).then(() => {
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    });
  }

  listByIds = (ids, sort) => {
    const filter = ids.map(id => `RECORD_ID() = '${id}'`).join(',');
    let condition = { filterByFormula: `OR(${filter})` };
    if (sort) condition.sort = sort;

    if (this.fields) condition.fields = Object.values(this.fields);
    return this.list(condition)
  }

  selectOneByCondition = async (condition) => {
    const items = await this.list({
      ...condition, maxRecords: 1
    }, null, true);

    return (items && items.length) ? items[0] : null;
  }

  getFirstPage = (condition) => {
    return new Promise((resolve, reject) => {
      var result = [];
      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      this.table.select(con)
        .firstPage()
        .then((records) => {
          records.forEach(record => {
            var item = {
              id: record.id,
              ...record.fields
            }
            result.push(item);
          })
          resolve(result)
        }).catch(error => {
          reject(error)
        })
    });
  }

  listAll = () => {
    let condition = {}
    if (this.fields) condition.fields = Object.values(this.fields);
    return this.list(condition);
  }

  /**
   *
   * @typedef ConditionProp
   * @property {string} filterByFormula
   * @property {Array<Object>} sort
   * @property {number} pageSize
   *
   * @param {ConditionProp} condition
   * @param {*} loadFunc
   * @returns
   */
  listItems = (condition, loadFunc) => {
    return new Promise((resolve, reject) => {

      let pageNum = 0;
      const result = [];
      const totalResult = [];

      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: Object.values(this.fields)
        }
      }

      if (!con.pageSize) con.pageSize = this.pageSize;

      this.table.select(con).eachPage((records, fetchNextPage) => {
        const pageItems = [];
        records.forEach(record => {
          var item = {
            id: record.id,
            ...record.fields
          }
          pageItems.push(item);
          totalResult.push(item);
        });

        const newPage = {
          pageNo: pageNum,
          items: pageItems
        }
        result.push(newPage);
        loadFunc && loadFunc([...result]);
        pageNum++;

        fetchNextPage()
      }).then(() => {
        resolve(totalResult)
      }).catch(error => {
        reject(error)
      })
    });
  }

  listPaginated = (condition, pageNumber = 1, offset = 0, filter = null, allFields = false, pageSize = 15) => {
    return new Promise((resolve, reject) => {
      try {
        let con = { ...condition };
        if (!condition.fields && this.fields && !allFields) {
          con = {
            ...condition,
            fields: Object.values(this.fields)
          };
        }

        con.pageSize = pageSize;

        if (offset === 0 && pageNumber > 1) {
          this.fetchMultiplePages(con, pageNumber, pageSize, filter)
            .then(result => {
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          con.offset = offset;

          let results = [];

          this.fetchWithOffset(con).then(res => {
            for (const item of res.records) {
              if (!filter || filter(item)) {
                if (results.length < pageSize) {
                  results.push(item);
                }
              }
            }
            resolve({
              records: results,
              pagination: {
                pageSize,
                currentPage: pageNumber - 1,
                hasMore: res.records.length === pageSize && !!res.offset,
                offset: res.offset,
              }
            });
          })
          .catch(error => {
            reject(error);
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  fetchMultiplePages = async (con, targetPageNumber, pageSize, filter) => {
    let currentOffset = null;
    let currentPage = 1;
    let allRecords = [];

    while (currentPage <= targetPageNumber) {
      if (currentOffset) {
        con.offset = currentOffset;
      } else {
        delete con.offset;
      }

      const res = await this.fetchWithOffset(con);

      const filteredRecords = filter
        ? res.records.filter(item => filter(item))
        : res.records;

      allRecords = allRecords.concat(filteredRecords);

      currentOffset = res.offset;
      currentPage++;

      if (!res.records.length || !res.offset) {
        break;
      }
    }

    return {
      records: allRecords,
      pagination: {
        pageSize,
        currentPage: targetPageNumber,
        hasMore: currentOffset !== null,
        offset: currentOffset,
      }
    };
  };

  fetchWithOffset = async (con) => {
    const baseUrl = `https://api.airtable.com/v0/${AIRTABLE_APP}/${this.tableName}`;

    const queryParams = new URLSearchParams();

    if (con) {
      Object.entries(con).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (key === 'fields') {
            value.forEach(field => {
              queryParams.append('fields[]', field);
            });
          } else if (key === 'sort') {
            value.forEach((sortItem, index) => {
              if (sortItem.field) {
                queryParams.append(`sort[${index}][field]`, sortItem.field);
                if (sortItem.direction) {
                  queryParams.append(`sort[${index}][direction]`, sortItem.direction);
                }
              }
            });
          } else {
            value.forEach(item => {
              queryParams.append(`${key}[]`, item);
            });
          }
        } else if (value !== undefined && value !== null) {
          queryParams.append(key, value);
        }
      });
    }

    const url = `${baseUrl}?${queryParams.toString()}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${AIRTABLE_KEY}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Airtable API error: ${response.status} ${errorText}`);
    }

    const data = await response.json();

    return {
      records: data.records.map(record => ({
        id: record.id,
        ...record.fields
      })),
      offset: data.offset
    };
  };
}
