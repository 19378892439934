import { useEffect, useState } from "react";
import airtable from "../../../airtables";

const useSchool = (school) => {
	const [loading, setLoading] = useState(true);
	const [sessions, setSessions] = useState([]);

	useEffect(() => {
		if (!school?.id) {
			setLoading(false);
			setSessions([]);
			return;
		}

		const fetchData = async () => {
			try {
				setLoading(true);
				const sessList = await airtable.sessions.getAllSessionsInYearInSchool(
					school.id,
					school.School,
				);
				setSessions(sessList);
			} catch (error) {
				console.error("Error fetching school data:", error);
				setSessions([]);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [school?.id, school?.School]);

	return {
		loading,
		sessions,
	};
};

export default useSchool;
