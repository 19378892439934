import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Airtable from "../../airtables";
import BarGraph from "../../components/Graphs/Bar";
import LineGraph from "../../components/Graphs/Line";
import WellbeingPieGraph from "../../components/Graphs/WellbeingPie";
import SubjectsPieGraph from "../../components/Graphs/SubjectsPie";
import CarouselProvider from "../../components/Carousel";
import SessionInfo from "../../components/SessionInfo";
import { getDisplayInfos, getActionMenus } from "../SessionInfoDisplay";
import { TokenBlue } from "../Credit/assets/token-blue";
import { Button } from "reactstrap";
import { CircularProgress, Switch } from "@mui/material";
import {
  AchievementBadges,
  AchievementCredit,
  EmptyGraph,
  IndigenousGraph,
} from "../Dashboard/components";
import s from "../Dashboard/style.module.scss";
import { openURL } from "../../utils/url";
import { getProviderSessionIDFromSession, getSession, setSession } from "../../utils/session";
import { getClusterLeadName } from "../../utils/string";
import { TEACHER_FIELDS } from "../OnboardTeacher/constants/teacher-fields";
import { getIsIncompleteTeacher } from "../OnboardTeacher/utils/get-is-new-teacher";
import { getIsTeacherOnboardEnabled } from "../OnboardTeacher/utils/get-is-teacher-onboard-enabled";
import { ONBOARD_TEACHER_ROUTES } from "../OnboardTeacher/routing";

const responsiveSessions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const responsiveWellbeing = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const Screen = () => {
  const { userType, userInfo, appLocale, subjectMap } = useSelector(
    (state) => state.appInfo,
  );

  const [showPrincipalView, setShowPrincipalView] = useState(false);
  const [isSwitchedOn, setSwitchedOn] = useState(true);

  /** @type {boolean} */
  const isSchoolLeader =
    (userInfo["School Leader?"] ?? "").toLowerCase() === "true";
  const isDistrictLeader =
    (userInfo["District Leader?"] ?? "").toLowerCase() === "true";

  const [recommended, setRecommended] = useState([]);
  const [free, setFree] = useState();
  const [lead, setLead] = useState();
  const [personalSessions, setPersonalSessions] = useState([]);
  const [schoolSessions, setSchoolSessions] = useState([]);
  const [all, setAll] = useState([]);
  const [upcomings, setUpcomings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  var wellbeingList = {
    "Culture, Language & Identity": {
      count: 0,
      title: "Culture, Language & Identity",
      titleFr: "Culture, langue, identité",
      image: `culture-language-and-identity`,
      imageFn: `culture-language-and-identity-fn`,
    },
    "Food & Nutrition": {
      count: 0,
      title: "Food & Nutrition",
      titleFr: "Alimentation et nutrition",
      image: "food-and-nutrition",
      imageFn: "food-and-nutrition-fn",
    },
    "Hope For the Future": {
      count: 0,
      title: "Hope For the Future",
      titleFr: "Espoir pour l'avenir",
      image: "hope-for-the-future",
      imageFn: "hope-for-the-future-fn",
    },
    "Relationship with Staff & Authority": {
      count: 0,
      title: "Relationship with Staff & Authority",
      titleFr: "Relation avec l'autorité",
      image: "relationships-with-staff-and-authority",
      imageFn: "relationships-with-staff-and-authority-fn",
    },
    "Relationship with Students/Peers": {
      count: 0,
      title: "Relationship with Students/Peers",
      titleFr: "Relation avec les pairs",
      image: "relationships-with-peers-students",
      imageFn: "relationships-with-peers-students-fn",
    },
    "Parent & Family Involvement": {
      count: 0,
      title: "Parent & Family Involvement",
      titleFr: "Participation des parents et de la famille",
      image: "parent-and-family-involvements",
      imageFn: "parent-and-family-involvements-fn",
    },
    "Arts & Recreation": {
      count: 0,
      title: "Arts & Recreation",
      titleFr: "Arts et loisirs",
      image: "arts-and-recreation",
      imageFn: "arts-and-recreation-fn",
    },
  };

  if (userInfo["Well-being Links"]) {
    userInfo["Well-being Links"] = userInfo["Well-being Links"].filter(Boolean);
  } else {
    userInfo["Well-being Links"] = []
  }

  userInfo["Well-being Links"].forEach((a) => {
    var splitList;
    if (a) {
      splitList = a
        .match(/("[^"]+"|[^,]+)/g)
        .map((item) =>
          item.startsWith('"') && item.endsWith('"')
            ? item.slice(1, -1).trim()
            : item.trim(),
        );
    } else {
      splitList = "";
    }
    if (splitList.length > 0) {
      splitList.forEach((b) => {
        if (wellbeingList[b]) {
          wellbeingList[b].count = wellbeingList[b].count + 1;
        }
      });
    }
  });

  useEffect(() => {
    window.document.title = "Dashboard - Connected North";
    onRefresh();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!showPrincipalView) {
      setAll(personalSessions);
      setUpcomings(
        personalSessions.filter((sess) => sess.Status === "Booked"),
      );
    } else {
      setAll(schoolSessions);
      const ids = schoolSessions.filter((sess) => sess.Status === "Booked" && sess['Session Start Date/Time'] > new Date().toISOString()).slice(0, 10).map(sess => sess.id)
      Airtable.sessions.listByIds(ids, [{ field: 'Session Start Date/Time', direction: 'asc' }]).then(upcomingSession => {
        setUpcomings(upcomingSession);
      });
    }
  }, [showPrincipalView, personalSessions, schoolSessions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userType === 'Teacher' && upcomings && upcomings.length) {
      const session = upcomings[0];
      const cancelledActiveSessionId = getSession("teacher-active-session");
      if (cancelledActiveSessionId === session.id) return;

      const sessionTime = new Date(session["Session Start Date/Time"]).getTime();
      const currentTime = Date.now();

      if (Math.abs(sessionTime - currentTime) < 300000 /* 10 mins */) {
        const providerName = session["Provider Name"][0]
        confirmAlert({
          title: 'Join your session',
          message: `You have a session with ${providerName} happening now. Ready to join?`,
          buttons: [{
            label: t("join-session"),
            onClick: () => openURL(session["WebEx/Zoom Link"])
          },
          {
            label: "Cancel",
            onClick: () => setSession("teacher-active-session", session.id)
          }]
        });
      }
    }
  }, [upcomings]) // eslint-disable-line react-hooks/exhaustive-deps


  const handlePrincipal = (event) => {
    setShowPrincipalView((showPrincipalView) => !showPrincipalView);
    setSwitchedOn(event.target.checked);
  };

  const onRefresh = async () => {
    setLoading(true);

    var subjects = [];
    var grades = [];

    if (userInfo["Subject(s) Taught"] || userInfo["Grade(s) Taught"]) {
      if (userInfo["Subject(s) Taught"]) {
        userInfo["Subject(s) Taught"].forEach((sub) => {
          if (
            subjectMap[sub]?.Subject !== null &&
            subjectMap[sub]?.Subject !== undefined
          )
            subjects.push(subjectMap[sub]?.Subject);
        });
      }
      if (userInfo["Grade(s) Taught"]) {
        userInfo["Grade(s) Taught"].forEach((grade) => {
          if (grade !== null && grade !== undefined)
            grades.push(grade);
        });
      }
      const tRecommend =
        await Airtable.providerSessions.getRecommendedUpcomingSessions(
          subjects,
          grades,
          [{ field: "Date Added", direction: "asc" }],
        );
      setRecommended(tRecommend);
    }

    const tYear = await Airtable.sessions.getAllSessionsInYear(
      "Teacher",
      userInfo["Teacher Name"],
      userInfo.id,
      false,
    );

    const tFree = await Airtable.providerSessions.getFreeUpcomingSessions([
      { field: "Date Added", direction: "asc" },
    ]);

    if (userInfo["School Lead"] && userInfo["School Lead"].length) {
      const tLead = await Airtable.teams.select(
        userInfo["School Lead"][0],
      );
      setLead(tLead);
    }

    if (isSchoolLeader || isDistrictLeader) {
      setShowPrincipalView(true);
      let tSchool;
      if (userInfo["Cluster Lead Text"]) {
        tSchool = await Airtable.sessions.getAllSessionsInYearInCluster(userInfo["Cluster Leadership"][0], userInfo["Cluster Lead Text"][0]);
      } else {
        tSchool = await Airtable.sessions.getAllSessionsInYearInSchool(userInfo["School Name"][0], userInfo["School Name Text"][0]);
      }
      setSchoolSessions(tSchool);
    }

    setFree(tFree);
    setPersonalSessions(tYear);
    setLoading(false);
  };

  const onRequestSupport = (e) => {
    e.preventDefault();
    window.location.href = "tel:8679920055";
  };

  const renderSessions = (source, type) => {
    return source?.length > 0 ? (
      source?.map((session, index) => {
        const providerSessionId = type === "upcoming" ? getProviderSessionIDFromSession(session) : session.id

        return (
          <SessionInfo
            displayInfos={getDisplayInfos(session, userType)}
            actionMenu={getActionMenus(session, userType)}
            sessionDetailLink={providerSessionId}
            requestSupport={onRequestSupport}
            key={index}
            session={session}
          />
        )
      })
    ) : (
      <div>
        <div>
          <p className="mb-3" style={{ lineHeight: "1.2" }}>
            {t(`empty.${type}`)}
          </p>
        </div>
        {type === "recommended" && (
          <div className={s.buttonGroup}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => window.location.href = "/cn/mysettings"}
            >
              {t("dashboard-btns.update-settings")}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => window.location.href = "/cn/search"}
            >
              {t("dashboard-btns.explore-all-sessions")}
            </button>
          </div>
        )}
        {type === "upcoming" && (
          <div className={s.buttonGroup}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
              (window.location.href =
                "/cn/browser/curriculum")
              }
            >
              {t("dashboard-btns.explore-by-curriculum")}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() =>
                (window.location.href = "/cn/search")
              }
            >
              {t("dashboard-btns.explore-by-search")}
            </button>
          </div>
        )}
      </div>
    );
  };

  // Confirm whether teacher profile is new, incomplete, or returning but not confirmed for
  // this year, and reveal toast with message and link to onboarding flow (if enabled)
  useLayoutEffect(() => {
    // First check for principals/district leaders in NU
    if (!promptProfileRef.current &&
      userInfo &&
      userInfo["School P/T Text"] === "NU" &&
      (!userInfo["2FA"] || userInfo["2FA"] === "None") &&
      ((userInfo["School Leader?"] === "true") || (userInfo["District Leader?"] === "true"))) {

      toast.warning(
        <>
          <strong>You must activate a Login Security method before you can view My School data & PD Plans!</strong>
          <Button
            color="primary"
            style={{ marginTop: "0.75rem", display: 'block' }}
            onClick={() => window.location.href = "/cn/mysettings"}
          >
            Edit My Settings
          </Button>
        </>,
        {
          position: "top-right",
          hideProgressBar: true,
          autoClose: false,
          closeOnClick: false
        }
      );

      promptProfileRef.current = true;
    }

    // Then check the teacher onboarding condition
    else if (getIsTeacherOnboardEnabled() && userInfo && userType === "Teacher" && !isDistrictLeader && !promptProfileRef.current) {
      const isIncompleteTeacher = getIsIncompleteTeacher(userInfo);

      if (!isIncompleteTeacher && userInfo[TEACHER_FIELDS.COMPLETED]) {
        return;
      }

      const toastMessage = isIncompleteTeacher ? 'incompleteTeacherMessage' : 'unconfirmedTeacherMessage';
      const toastButton = isIncompleteTeacher ? 'incompleteTeacherButton' : 'unconfirmedTeacherButton';
      const toastLink = ONBOARD_TEACHER_ROUTES.DETAILS.path;

      toast.warning(
        <>
          <strong>{t(`teacherOnboard.dashboardPrompt.${toastMessage}`)}</strong>
          <Button
            color="primary"
            style={{ marginTop: "0.75rem", display: 'block' }}
            onClick={() => window.location.href = toastLink}
          >
            {t(`teacherOnboard.dashboardPrompt.${toastButton}`)}
          </Button>
        </>,
        {
          position: "top-right",
          hideProgressBar: true,
          autoClose: false,
          closeOnClick: false
        }
      );

      promptProfileRef.current = true;
    }
  }, [userType, userInfo, t, isDistrictLeader]);

  const promptProfileRef = useRef(false);

  return (
    <div
      className="main-container dashboard dashboard-page"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {/* 4 grid */}
      <div className="dashboard-grid">
        <div>
          <h1>
            <b>
              {t("dashboard-page.greeting", {
                name: userInfo["First Name"],
              })}{" "}
            </b>
          </h1>
          <p style={{ fontWeight: "bold" }}>
            <b>{t("dashboard-page.subheading")}</b>
          </p>
          {(isSchoolLeader || isDistrictLeader) && (
            <div>
              <span>{t("teacher-view")}</span>
              <Switch
                onChange={handlePrincipal}
                value={showPrincipalView}
                checked={isSwitchedOn}
              />
              <span>{isDistrictLeader ? t("district-view", { displayname: getClusterLeadName(userInfo) }) : t("school-view")}</span>
            </div>
          )}
        </div>
        {!loading && (
          <>
            {!!userInfo["School Lead Name"] ? (
              <div className="schoolLead-box">
                <div>
                  <h3 style={{ fontWeight: 400, fontSize: 20 }}>
                    {Array.isArray(userInfo["School Lead Name"]) ? (
                      (() => {
                        const uniqueNames = Array.from(new Set(userInfo["School Lead Name"])).sort((a, b) => a.localeCompare(b));

                        return uniqueNames.length === 1 ? (
                          <span>{t("dashboard-page.school-lead", { name: uniqueNames[0] })}</span>
                        ) : (
                          <span>
                            {t("dashboard-page.school-leads", { displayname: userInfo["Cluster Lead Display Name"] })}:{" "}
                            {uniqueNames.map((name, index) => (
                              <span key={index}>
                                {name}
                                {index === uniqueNames.length - 1 ? "" : index === uniqueNames.length - 2 ? ` and ` : ", "}
                              </span>
                            ))}
                          </span>
                        );
                      })()
                    ) : (
                      <span>{t("dashboard-page.school-lead", { name: userInfo["School Lead Name"] })}</span>
                    )}
                  </h3>
                  {lead && userInfo["School Lead Name"].length <= 1 && (
                    <>
                      <a href={`mailto:${lead["Email"]}`} className="lead-contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <i className="fa fa-envelope" />
                        {lead["Email"]}
                      </a>
                      <a href={`tel:${lead["Phone"]}`} className="lead-contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <i className="fa fa-phone" />
                        {lead["Phone"]}
                      </a>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className={`schoolLead-box ${s.hideMobile}`} style={{ visibility: 'hidden' }}>
                <div>
                  <h3 style={{ fontWeight: 400, fontSize: 20 }}>&nbsp;</h3>
                  <div className="lead-contact">&nbsp;</div>
                  <div className="lead-contact">&nbsp;</div>
                </div>
              </div>
            )}
            <div className="graph-box">
              {/* Session Graph */}
              {all?.length > 0 ? (
                <LineGraph
                  sessions={all}
                  title={isDistrictLeader && isSwitchedOn ?
                    t("total-sessions-district") :
                    undefined
                  }
                />
              ) : (
                <EmptyGraph
                  disclaimer={t(
                    "dashboard-page.no-booked-chart",
                  )}
                >
                  <LineGraph sessions={[]} />
                </EmptyGraph>
              )}
            </div>
            <div
              className="graph-box"
              data-intercom-target="credits-dashboard"
            >
              <h3
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: 700,
                  fontSize: 25,
                  marginBottom: 10,
                }}
              >
                {isDistrictLeader && isSwitchedOn
                  ? t("dashboard-page.district-booking-credits", { displayname: getClusterLeadName(userInfo) })
                  : (showPrincipalView
                    ? t("dashboard-page.school-booking-credits")
                    : t("dashboard-page.booking-credits"))
                }
              </h3>
              <div className="sig-number">
                {showPrincipalView
                  ? (userInfo["Cluster Lead Balance"] !== null && userInfo["Cluster Lead Balance"] !== undefined
                    ? userInfo["Cluster Lead Balance"].toLocaleString() // Display "Cluster Lead Balance" if it's not null/undefined
                    : (Array.isArray(userInfo["School Current Balance"]) // If "Cluster Lead Balance" is null/undefined, check "School Current Balance"
                      ? userInfo["School Current Balance"].reduce((a, b) => a + b, 0).toLocaleString() // Sum the values if it's an array
                      : userInfo["School Current Balance"].toLocaleString() // Display the value if it's not an array
                    )
                  )
                  : userInfo["Credit Balance"]
                }
                <TokenBlue style={{ width: "38px", height: "38px" }} />
              </div>
              <p>
                {t("dashboard-page.credits-description")}{" "}
                {userInfo["Credit Balance"] === 0 && (
                  <strong>{t("dashboard-page.credits-description-extra")}</strong>
                )}
              </p>
              <div className={["d-flex"]}>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => window.location.href = `/cn/credits/earn-more`}
                >
                  {t("earn-credits")}
                </Button>
                <Button
                  onClick={() => window.location.href = `/cn/credits`}
                  color="outline-primary"
                  type="button"
                >
                  {t("credit-history")}
                </Button>
              </div>
            </div>
            <div className="graph-box">
              {/* Session Bar Graph */}
              {all?.length > 0 ? (
                <BarGraph
                  title={isDistrictLeader
                    ? t("dashboard-page.district-sessions-by-grade", { displayname: getClusterLeadName(userInfo) })
                    : (showPrincipalView
                      ? t("dashboard-page.school-sessions-by-grade")
                      : t("dashboard-page.your-sessions-by-grade"))
                  }
                  isDistrictLeader={isDistrictLeader}
                  showPrincipalView={showPrincipalView}
                  sessions={all}
                />
              ) : (
                <EmptyGraph disclaimer={t("dashboard-page.no-booked-chart")}>
                  <BarGraph
                    title={isDistrictLeader
                      ? t("dashboard-page.district-sessions-by-grade", { displayname: getClusterLeadName(userInfo) })
                      : (showPrincipalView
                        ? t("dashboard-page.school-sessions-by-grade")
                        : t("dashboard-page.your-sessions-by-grade"))
                    }
                    sessions={[]}
                  />
                </EmptyGraph>
              )}
            </div>
            {userInfo["Preferred Language"] !== 'French' && (
              <div className="graph-box">
                {all?.length > 0 ? (
                  <IndigenousGraph
                    percentage={
                      showPrincipalView
                        ? userInfo["Cluster Lead % Indigenous"] ?? (
                          Array.isArray(userInfo["School Indigenous % TY"]) && userInfo["School Indigenous % TY"].length > 0
                            ? userInfo["School Indigenous % TY"].reduce((a, b) => a + b, 0) / userInfo["School Indigenous % TY"].length // Calculate average if it's an array and has elements
                            : userInfo["School Indigenous % TY"] // Use directly if it's not an array
                        )
                        : userInfo["Indigenous % TY"] // Use if showPrincipalView is false
                    }
                  />
                ) : (
                  <EmptyGraph disclaimer={t("dashboard-page.no-booked-chart")}>
                    <IndigenousGraph percentage={0} />
                  </EmptyGraph>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {loading !== true ? (
        <>
          {/* SessionItem Carousel */}
          <div>
            <CarouselProvider
              responsive={responsiveSessions}
              title={userInfo["Cluster Lead Display Name"] && isSwitchedOn ? t("upcoming-sessions-district", { displayname: userInfo["Cluster Lead Display Name"] }) : t("upcoming-sessions")}
              infinite={false}
            >
              {renderSessions(upcomings, "upcoming")}
            </CarouselProvider>
            {upcomings?.length > 0 && (
              <>
                <div
                  href="#"
                  onClick={() => window.open(`/cn/upcoming`)}
                  style={{
                    marginBottom: 80,
                    marginTop: -100,
                    cursor: "pointer",
                    position: "relative",
                    zIndex: "5",
                  }}
                ></div>
                {/* Link to Upcoming */}
                <button
                  className="btn btn-secondary mt-4"
                  onClick={() =>
                    (window.location.href = `/cn/upcoming`)
                  }
                >
                  {t("see-upcoming")}
                </button>
              </>
            )}
          </div>

          {/* Well-being Pie-chart */}
          <div className="pie-box">
            {all?.length > 0 &&
              (showPrincipalView ||
                userInfo["Well-being Links"][0] != null) ? (
              <WellbeingPieGraph
                isDistrictLeader={isDistrictLeader}
                showPrincipalView={showPrincipalView}
                sessions={all}
              />
            ) : (
              <EmptyGraph disclaimer={t("dashboard-page.no-wellbeing-chart")}>
                <WellbeingPieGraph sessions={[]} />
              </EmptyGraph>
            )}
          </div>

          {/* Well-being carousel */}
          {!showPrincipalView && (
            <div>
              <CarouselProvider
                infinite={false}
                responsive={responsiveWellbeing}
                title={t("dashboard-page.missing-wb-links")}
              >
                {Object.values(wellbeingList)
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .filter((x) => x.count === 0)
                  .map((item, i) => (
                    <div className="missingLink-box" key={i}>
                      <div className="image-box">
                        <img
                          src={require(`../../assets/img/wellbeing/${userInfo["School PT"]?.includes("recalRmA0o3OZhdlO") ? item.image : item.imageFn}.svg`)}
                          alt="Well-being"
                          style={{
                            background: userInfo["School PT"]?.includes("recalRmA0o3OZhdlO") ? "#9de0f8" : "#FFFFFF"
                          }}
                        />
                      </div>
                      <span>
                        {appLocale === "fr"
                          ? item.titleFr
                          : item.title}
                      </span>
                      <div
                        className="search-link"
                        href="#"
                        onClick={() => {
                          const params =
                            new URLSearchParams();
                          params.append(
                            "wellbeinglinks",
                            item.title,
                          );
                          window.location.href = `/cn/search?${params.toString()}`;
                        }}
                      >
                        <span>{t("dashboard-page.search-sessions")}</span>{" "}
                        <i className="fa fa-arrow-right" />
                      </div>
                    </div>
                  ))}
              </CarouselProvider>
              <button
                className="btn btn-secondary mt-3"
                onClick={() => window.open(`https://www.connectednorth.org/en/wellbeing`)}
              >
                {t("dashboard-page.learn-more-wellbeing")}
              </button>
            </div>
          )}

          {/* Subject Pie-chart */}
          <div className="pie-box">
            {all?.length > 0 ? (
              <SubjectsPieGraph
                title={isDistrictLeader
                  ? t("dashboard-page.district-sessions-by-subject", { displayname: getClusterLeadName(userInfo) })
                  : (showPrincipalView
                    ? t("dashboard-page.school-sessions-by-subject")
                    : t("dashboard-page.your-sessions-by-subject"))}
                isDistrictLeader={isDistrictLeader}
                showPrincipalView={showPrincipalView}
                sessions={all}
              />
            ) : (
              <EmptyGraph
                disclaimer={t("dashboard-page.no-booked-chart")}
              >
                <SubjectsPieGraph
                  title={isDistrictLeader
                    ? t("dashboard-page.district-sessions-by-subject", { displayname: getClusterLeadName(userInfo) })
                    : (showPrincipalView
                      ? t("dashboard-page.school-sessions-by-subject")
                      : t("dashboard-page.your-sessions-by-subject"))}
                  sessions={[]}
                />
              </EmptyGraph>
            )}
          </div>

          {/* Free Sessions */}
          {!showPrincipalView && (
            <>
              {!userInfo["Credit Balance"] ||
                userInfo["Credit Balance"] < 10 ? (
                <div>
                  <CarouselProvider
                    responsive={responsiveSessions}
                    title={t("free-sessions")}
                    infinite={false}
                  >
                    {renderSessions(free, "free")}
                  </CarouselProvider>
                </div>
              ) : null}
              {/* Recommended Sessions */}
              <div>
                <CarouselProvider
                  responsive={responsiveSessions}
                  title={t("recommended-sessions")}
                  infinite={false}
                >
                  {renderSessions(recommended, "recommended")}
                </CarouselProvider>
              </div>
            </>
          )}

          {/* Achievements Carousel*/}
          <section>
            <AchievementBadges />
          </section>

          <AchievementCredit creditIDs={userInfo["Credits"]} />
        </>
      ) : (
        <div style={{ margin: "60px auto" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Screen;
