import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import airtable from "../../../airtables";
import supabase from "../../../database";
import { createFormResponse } from "../../PrincipalReporting/mutations/create-form-response";
import { createInitialValues } from "../../PrincipalReporting/mutations/create-initial-values";
import { getAllFieldsFromLayout } from "../../PrincipalReporting/util/get-all-fields-from-layout";
import { fetchFormFields } from "../../PrincipalReporting/queries/fetch-form-fields";
import { PRINCIPAL_FIELDS } from "../constants/graph-choices";
import { transformGraphFields } from "../utils/graphCalculations";

const minute = 1000 * 60;
const activeFormStaleTime = 10 * minute;
const schoolStaleTime = 5 * minute;

export const useSchools = () => {
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const [school, setSchool] = useState(null);
	const [loadingReports, setLoadingReports] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const queryClient = useQueryClient();

	const activeFormsQuery = useQuery({
		queryKey: ["activeForms"],
		queryFn: () => supabase.forms.listActiveAndInactive(),
		staleTime: activeFormStaleTime,
	});

	const getSchoolIds = useCallback(async () => {
		if (userType === "Team") {
			// Use getTeamSchools for both admin and non-admin Team users
			const schools = await airtable.schools.getTeamSchools(userInfo);
			return schools?.map(school => school.id) || [];
		} else if (userType === "Teacher") {
			if (userInfo["Cluster Leadership"]) {
				const cluster = await airtable.clusters.select(
					userInfo["Cluster Leadership"][0],
				);
				return cluster?.["Schools"] || [];
			}
			return userInfo["School Name"] || [];
		}
		return [];
	}, [userType, userInfo]);

	const schoolsQuery = useQuery({
		queryKey: ["schools", userType, userInfo],
		queryFn: async () => {
			const schoolIds = await getSchoolIds();
			if (!schoolIds || schoolIds.length === 0) return [];

			const allSchools = await airtable.schools.getAllSchools(schoolIds);

			return allSchools
		},
		enabled: !!userInfo && activeFormsQuery.isSuccess, // Ensure forms are loaded first
		staleTime: schoolStaleTime,
	});

	useEffect(() => {
		if (schoolsQuery.data?.length > 0) {
			const savedSchoolId = sessionStorage.getItem("principalSelectedSchool");
			const savedSchool = schoolsQuery.data.find((s) => s.id === savedSchoolId);
			setSchool(savedSchool || schoolsQuery.data[0]);
		}
	}, [schoolsQuery.data]);

	// Helper function to get user name safely
	const getUserName = async (userId) => {
		if (!userId) return null;
		const teacher = await airtable.teachers.select(userId);
		return teacher && teacher["Teacher Name"] ? teacher["Teacher Name"] : null;
	};

	const handleClickReport = useCallback((reportId) => {
		history.push(`principal-reporting/${reportId}`);
	}, [history]);

	const createReportMutation = useMutation({
		mutationFn: async ({ schoolId, activeForm }) => {
			const previousResponse =
				await supabase.formResponses.selectLatest(schoolId);
			const response = await createFormResponse(activeForm.id, userInfo.id, [
				schoolId,
			]);
			const previousValues = previousResponse
				? await supabase.formResponseValues.listByResponse(previousResponse.id)
				: [];

			const pages = await fetchFormFields(activeForm.template_id);
			const fields = getAllFieldsFromLayout({ pages });

			await createInitialValues(response.id, fields, previousValues);
			return response;
		},
		onSuccess: (response) => {
			queryClient.invalidateQueries({
				queryKey: ["schools", userType, userInfo],
			});
			handleClickReport(response.id);
		},
		onError: (error) => {
			console.error("Error creating report:", error);
		},
	});

	const handleCreateReport = useCallback((schoolId, activeForm) => {
		createReportMutation.mutate({ schoolId, activeForm });
	}, [createReportMutation]);

	useEffect(() => {
		if (school?.id) {
			sessionStorage.setItem("principalSelectedSchool", school.id);
			if(!school.reports && !loadingReports) {
				// Define fetchSchoolReports inside useEffect to avoid dependency issues
				const fetchReports = async () => {
					setLoadingReports(true);

					const activeForms = activeFormsQuery.data || [];

					const reports =
						userType === "Team"
							? []
							: await Promise.all(
									activeForms.map(async (form) => {
										const formResponses =
											await supabase.formResponses.listBySchoolAndForm(
												school.id,
												form.id,
											);

										if (formResponses.length === 0) {
											return {
												status: "not-started",
												month: form.name,
												form_id: form.id,
												report_label: "Create Report",
												onClick: () => handleCreateReport(school.id, form),
												year: form.year,
												monthValue: form.month,
											};
										}

										const processedResponses = await Promise.all(
											formResponses.map(async (response) => {
												const fieldsData =
													await supabase.formResponseValues.listByResponseAndFields(
														response.id,
														PRINCIPAL_FIELDS.fieldIds,
													);
												const newData = transformGraphFields(
													fieldsData,
													PRINCIPAL_FIELDS,
													t,
												);

												// Get user name
												const userName = await getUserName(response.user_id);

												// Get DEA name if DEA user ID exists
												let deaName = null;
												if (response.dea_user_id) {
													deaName = await getUserName(response.dea_user_id);
												}

												return {
													...response,
													month: form.name,
													status: response.status,
													submitted_at: formatDate(response.submitted_at),
													last_saved_at: formatDate(response.last_saved_at),
													created_at: formatDate(response.created_at),
													user_name: userName,
													dea_name: deaName,
													report_label: "Enter Report",
													onClick: () => handleClickReport(response.id),
													year: form.year,
													monthValue: form.month,
													cardData: newData.graph_cards,
													comparativeData: newData.comparative_graph,
												};
											}),
										);
										return processedResponses;
									}),
								);

					setSchool({
						...school,
						reports: reports
							.flat()
							.sort((a, b) =>
								b.year === a.year
									? b.monthValue - a.monthValue
									: b.year - a.year,
							),
					})

					setLoadingReports(false);
				};

				fetchReports();
			}
		}
	}, [school, loadingReports, activeFormsQuery.data, userType, t, handleClickReport, handleCreateReport]);

	const addNewTeacherMutation = useMutation({
		mutationFn: async ({ name, email }) => {
			let teacher = await airtable.teachers.selectTeacherByEmail(email);
			if (teacher) throw Error(`A teacher with ${email} already registered.`);

			const cluster = await airtable.clusters.selectBySchool(school?.School);
			const defaultCredits = cluster?.["Default New Teacher Credits"] ?? 15;

			const res = await airtable.teachers.create({
				"Teacher Name": name,
				Email: email,
				"School Name": [school.id],
				"Platform Logins": 0,
				Status: "Active",
				"Created by": "Principal through Platform",
				"E-mail Reminders": ["24 hours before"],
			});

			await airtable.credit.create({
				Teacher: [res.id],
				Credits: defaultCredits,
				Action: "Credits Issued by Program",
				"Display Note":
					"Introductory Credits - Teacher joined the Connected North Platform",
			});

			return res;
		},
	});

	const formatDate = (timestamp) =>
		new Date(timestamp).toLocaleDateString("en-CA");

	const getTitle = () =>
		schoolsQuery.isLoading || activeFormsQuery.isLoading
			? t("principal.heading")
			: schoolsQuery.data?.length > 1
				? t("principal.heading-multi")
				: t("principal.heading");

	return {
		loading: schoolsQuery.isLoading || activeFormsQuery.isLoading,
		loadingReports,
		school,
		setSchool,
		schools: schoolsQuery.data || [],
		getTitle,
		addNewTeacher: addNewTeacherMutation.mutateAsync,
		refresh: () =>
			queryClient.invalidateQueries({
				queryKey: ["schools", userType, userInfo],
			}),
	};
};
