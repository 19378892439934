import { confirmAlert } from "react-confirm-alert";
import { removeSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { setAppUserInfo, setAppUserType } from "../../redux/actions";
import { deleteToken, getMessaging, isSupported } from "firebase/messaging";
import { hideLoading, showLoading } from "../../utils/loading";
import { removeUserPushToken } from "../apis";

const useSignOut = () => {
  const { notificationToken, userInfo } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const preSignOut = async () => {
    confirmAlert({
      title: "Connected North",
      message: "Are you sure you want to sign out?",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            showLoading("");
            removeSession("CONNECTEDNORTH_PERMISSION_ASKED_STATUS")

            const fnSupported = await isSupported();
            if (fnSupported) {
              if (notificationToken) {
                await removeUserPushToken(userInfo.id, notificationToken)
              }
            }

            if (window.isMobile) {
              window.ReactNativeWebView.postMessage("RemoveNotificationToken");
            } else if (fnSupported) {
              const messaging = getMessaging();
              await deleteToken(messaging);
            }
            removeSession(CONNECTEDNORTH_SIGNINFO);
            removeSession(CONNECTEDNORTH_SIGNTYPE);
            dispatch(setAppUserInfo(null));
            dispatch(setAppUserType(null));
            window.location.href = "/login";
            hideLoading();
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  }

  return { preSignOut };
}

export default useSignOut;