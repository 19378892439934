import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormGroup, Tooltip } from "reactstrap";
import Select from 'react-select';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import { toast } from 'react-toastify';
import "./styles.scss";
import "./styles-override.scss";
import { cnSupabase } from '../../database';
import airtable from '../../airtables';

// Configure dayjs to use Monday as the first day of the week
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1 // Monday as first day of week (0 is Sunday)
});

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const weekDaysMobile = ["M", "T", "W", "T", "F"];

const TeamViewerScheduler = () => {
  const { userInfo, totalTimezones } = useSelector(state => state.appInfo);
  
  // State for week selection - use next week on weekends, current week on other days
  const [currentWeekStart, setCurrentWeekStart] = useState(() => {
    const today = dayjs();
    const dayOfWeek = today.day(); // 0 = Sunday, 6 = Saturday with default plugin
    // If today is Saturday or Sunday, show next week
    return dayOfWeek === 0 || dayOfWeek === 6 
      ? today.add(1, 'week').weekday(0) // Next week's Monday
      : today.weekday(0); // Current week's Monday (0 = Monday with weekday plugin)
  });
  
  // Track if we're at the current week (to disable previous button)
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  const [weekDates, setWeekDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({ current: 0, total: 0, stage: 'Initializing' });
  
  // Session host states
  const [sessionHosts, setSessionHosts] = useState([]);
  const [selectedHost, setSelectedHost] = useState({ value: 'all', label: 'All Hosts' });
  const [hostAvailabilities, setHostAvailabilities] = useState({});
  const [aggregateAvailability, setAggregateAvailability] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [hostBookings, setHostBookings] = useState({});
  const [hostProviderBookings, setHostProviderBookings] = useState({});
  const [hostHistory, setHostHistory] = useState({});
  // State to filter which days to show in the host table
  const [filteredDate, setFilteredDate] = useState(null);
  
  // Session assignment states
  const [selectedDate, setSelectedDate] = useState(null);
  const [sessionsForDate, setSessionsForDate] = useState({});
  const [availableHostsForSessions, setAvailableHostsForSessions] = useState({});
  const [selectedSessionHosts, setSelectedSessionHosts] = useState({});
  const [blockedHostIds, setBlockedHostIds] = useState({});
  const [assignedSessionIds, setAssignedSessionIds] = useState([]);
  const [tentativeBookings, setTentativeBookings] = useState({});
  const [resetKey, setResetKey] = useState(0);
  const [isAutoAssigning, setIsAutoAssigning] = useState(false);
  const [showSessionAssignment, setShowSessionAssignment] = useState(false);
  // Add state to track which hosts should be disabled in which session dropdowns
  const [disabledHostsBySession, setDisabledHostsBySession] = useState({});
  // Add state to track which sessions are currently being assigned
  const [assigningSessionIds, setAssigningSessionIds] = useState({});

  // Determine if the logged-in user is a team viewer
  const isTeamViewer = useMemo(() => 
    userInfo && userInfo['Status'] !== 'Session Host' && !userInfo['Primary Session Host'], 
    [userInfo]
  );

  // Generate week dates from start date and check if it's the current week
  useEffect(() => {
    const dates = [];
    // Ensure we're starting from Monday regardless of what day currentWeekStart is
    const start = currentWeekStart.weekday(0); // 0 = Monday with weekday plugin
    
    for (let i = 0; i < 5; i++) {
      dates.push(start.add(i, 'day'));
    }
    
    setWeekDates(dates);
    
    // Check if we're at the current week (accounting for weekends)
    const today = dayjs();
    const dayOfWeek = today.day(); // 0 = Sunday, 6 = Saturday
    // On weekends, "current week" is next week
    const currentWeek = (dayOfWeek === 0 || dayOfWeek === 6) 
      ? today.add(1, 'week').weekday(0) // Next week's Monday
      : today.weekday(0); // Current week's Monday
    setIsCurrentWeek(currentWeekStart.isSame(currentWeek, 'day'));
  }, [currentWeekStart]);

  // Move to previous week, but not before current week
  const goToPreviousWeek = async () => {
    const today = dayjs();
    const dayOfWeek = today.day(); // 0 = Sunday, 6 = Saturday
    // On weekends, "current week" is next week
    const currentWeek = (dayOfWeek === 0 || dayOfWeek === 6) 
      ? today.add(1, 'week').weekday(0) // Next week's Monday
      : today.weekday(0); // Current week's Monday
    setCurrentWeekStart(prev => {
      // If the previous week would be before current week, don't navigate back
      const previousWeek = prev.subtract(1, 'week');
      if (previousWeek.isBefore(currentWeek)) {
        return currentWeek;
      }
      return previousWeek;
    });
  };

  // Move to next week
  const goToNextWeek = async () => {
    setCurrentWeekStart(prev => prev.add(1, 'week'));
  };

  // Format date for database storage
  const formatDateForDB = useCallback((date) => {
    return date.format('YYYY-MM-DD');
  }, []);

  // Helper function to convert time zones (for future use)
  /* Commented out to address ESLint no-unused-vars warning
  const convertToEastern = (hour, timezone) => {
    if (timezone === 'America/Toronto') return hour;
    const date = DateTime.now().setZone(timezone).set({ hour, minute: 0, second: 0, millisecond: 0 });
    return date.setZone('America/Toronto').hour;
  };
  */

  // Toggle tooltip
  const toggleTooltip = (id) => {
    setTooltipOpen(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Fetch all session hosts
  const fetchSessionHosts = useCallback(async () => {
    if (!isTeamViewer) return;
    
    setIsLoading(true);
    setLoadingProgress({ current: 0, total: 1, stage: 'Loading session hosts' });
    
    try {
      // Fetch both contract and primary session hosts
      const contractHosts = await airtable.teams.getContractSessionHosts();
      const primaryHosts = await airtable.teams.getPrimarySessionHost();
      
      // Combine and deduplicate hosts
      const allHosts = [...contractHosts];
      primaryHosts.forEach(primaryHost => {
        if (!allHosts.some(host => host.id === primaryHost.id)) {
          allHosts.push(primaryHost);
        }
      });
      
      // Filter out hosts with 'Supervisor' in their name
      const filteredHosts = allHosts.filter(host => !host.Name.includes('Supervisor'));
      
      setSessionHosts(filteredHosts);
      setLoadingProgress({ current: 1, total: 1, stage: 'Hosts loaded' });
      
      // Don't turn off loading yet as we'll load availability next
    } catch (error) {
      console.error("Error fetching session hosts:", error);
      toast.error("Unable to fetch session hosts. Please try again.");
      setIsLoading(false);
    }
  }, [isTeamViewer]);

  // Fetch host session history to find hosts who have worked with specific teachers/providers before
  const fetchHostHistory = useCallback(async () => {
    const hostTeacherProviderHistory = {};
    
    try {
      // Get sessions from the past 6 months that were completed
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      const formattedDate = sixMonthsAgo.toISOString().split('T')[0];
      
      const filterFormula = `AND(
        Status = 'Completed',
        DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') >= '${formattedDate}',
        NOT({Session Host(s)} = "")
      )`;
      
      const pastSessions = await airtable.sessions.list({ 
        filterByFormula: filterFormula,
        fields: ['Session Host(s)', 'Provider', 'Teacher']
      });
      
      // Process the sessions to build history map
      pastSessions.forEach(session => {
        // Skip sessions without hosts, providers, or teachers
        if (!session['Session Host(s)'] || !session.Provider || !session.Teacher) return;
        
        // Get the provider and teacher IDs
        const providerId = session.Provider[0];
        const teacherId = session.Teacher[0];
        const key = `${teacherId}_${providerId}`;
        
        // For each host of this session, update their history
        session['Session Host(s)'].forEach(hostId => {
          if (!hostTeacherProviderHistory[hostId]) {
            hostTeacherProviderHistory[hostId] = {};
          }
          
          if (!hostTeacherProviderHistory[hostId][key]) {
            hostTeacherProviderHistory[hostId][key] = 0;
          }
          
          // Increment the count of sessions with this teacher/provider combination
          hostTeacherProviderHistory[hostId][key]++;
        });
      });
      
      return hostTeacherProviderHistory;
    } catch (error) {
      console.error("Error fetching host history:", error);
      return {};
    }
  }, []);

  // Fetch host bookings (when they are assigned to sessions or presenting as providers)
  const fetchHostBookings = useCallback(async () => {
    const hostBookings = {};
    const hostProviderBookings = {};
    
    // Only proceed if we have session hosts and dates
    if (!sessionHosts.length || !weekDates.length) {
      return { hostBookings, hostProviderBookings };
    }
    
    try {
      // Build map of host IDs to provider IDs
      const hostToProviderMap = {};
      
      // Get provider information for hosts who are also providers
      for (const host of sessionHosts) {
        const userData = await airtable.teams.select(host.id);
        if (userData["Also a Provider?"] && userData["Also a Provider?"].length > 0) {
          // This host is also a provider - store their provider ID
          hostToProviderMap[host.id] = userData["Also a Provider?"][0];
        }
      }
      
      // Fetch bookings for each day in the week
      for (const date of weekDates) {
        const formattedDate = date.format('YYYY-MM-DD');
        
        // Fetch sessions for this date
        const filterFormula = `AND(
          DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') = '${formattedDate}',
          OR(Status = 'Booked', Status = 'Completed')
        )`;
        
        const sessions = await airtable.sessions.list({ filterByFormula: filterFormula });
        
        // Process sessions
        sessions.forEach(session => {
          // Get session details
          const startTime = new Date(session["Session Start Date/Time"]);
          const startHour = startTime.getHours();
          
          // Only process if the session is during working hours (8am-6pm)
          if (startHour < 8 || startHour > 18) return;
          
          // Calculate session duration and end time
          const sessionDuration = parseInt(session["Length (Minutes)"]) || 60;
          const endTime = new Date(startTime.getTime() + sessionDuration * 60000);
          const endHour = endTime.getHours();
          
          // Format times for display
          const startHourDisplay = startTime.getHours();
          const startMinuteDisplay = startTime.getMinutes();
          const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
          const formattedStartTime = `${startHourDisplay % 12 || 12}:${startMinuteDisplay.toString().padStart(2, '0')}`;
          
          const endHourDisplay = endTime.getHours();
          const endMinuteDisplay = endTime.getMinutes();
          const endAmPm = endHourDisplay >= 12 ? 'PM' : 'AM';
          const formattedEndTime = `${endHourDisplay % 12 || 12}:${endMinuteDisplay.toString().padStart(2, '0')}`;
          
          const formattedTimeRange = startAmPm === endAmPm
            ? `${formattedStartTime}-${formattedEndTime} ${endAmPm}`
            : `${formattedStartTime} ${startAmPm}-${formattedEndTime} ${endAmPm}`;
          
          // Create session info object
          const sessionInfo = {
            id: session.id,
            name: session["Session Title Text"] || "Untitled Session",
            provider: session["Provider Name"] || "Unknown Provider",
            teacher: session["Teacher Name"] || "Unknown Teacher",
            school: session["School Name Text"] || "Unknown School",
            startHour,
            endHour,
            timeRange: formattedTimeRange
          };
          
          // First, process host bookings (when hosts are assigned to sessions)
          if (session["Session Host(s)"] && session["Session Host(s)"].length > 0) {
            session["Session Host(s)"].forEach(hostId => {
              if (!hostBookings[hostId]) {
                hostBookings[hostId] = {};
              }
              
              if (!hostBookings[hostId][formattedDate]) {
                hostBookings[hostId][formattedDate] = Array(11).fill(null);
              }
              
              // Add booking to each hour this session spans
              for (let hour = startHour; hour <= endHour; hour++) {
                const hourIndex = hour - 8;
                if (hourIndex < 0 || hourIndex > 10) continue;
                
                // Determine if this is a partial hour
                let isPartial = false;
                let isPartialStart = false;
                let isPartialEnd = false;
                let isPartialSingleHour = false;
                let partialPercentage = 1.0;
                let startPosition = 0;
                
                // Check for single hour session
                if (startHour === endHour) {
                  isPartial = true;
                  isPartialSingleHour = true;
                  const startMinutes = startTime.getMinutes();
                  const endMinutes = endTime.getMinutes() || 60;
                  const occupiedMinutes = endMinutes - startMinutes;
                  partialPercentage = occupiedMinutes / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial start hour
                else if (hour === startHour && startTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialStart = true;
                  const startMinutes = startTime.getMinutes();
                  partialPercentage = (60 - startMinutes) / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial end hour
                else if (hour === endHour && endTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialEnd = true;
                  const endMinutes = endTime.getMinutes();
                  partialPercentage = endMinutes / 60;
                }
                
                // Create booking entry
                hostBookings[hostId][formattedDate][hourIndex] = {
                  ...sessionInfo,
                  isPartial,
                  isPartialStart,
                  isPartialEnd,
                  isPartialSingleHour,
                  partialPercentage,
                  startPosition
                };
              }
            });
          }
          
          // Then, process provider bookings (when hosts are presenting)
          const providerId = session.Provider && session.Provider[0];
          if (providerId) {
            // Find host ID from provider ID
            const hostId = Object.keys(hostToProviderMap).find(
              hid => hostToProviderMap[hid] === providerId
            );
            
            if (hostId) {
              if (!hostProviderBookings[hostId]) {
                hostProviderBookings[hostId] = {};
              }
              
              if (!hostProviderBookings[hostId][formattedDate]) {
                hostProviderBookings[hostId][formattedDate] = Array(11).fill(null);
              }
              
              // Add provider booking to each hour this session spans
              for (let hour = startHour; hour <= endHour; hour++) {
                const hourIndex = hour - 8;
                if (hourIndex < 0 || hourIndex > 10) continue;
                
                // Determine if this is a partial hour
                let isPartial = false;
                let isPartialStart = false;
                let isPartialEnd = false;
                let isPartialSingleHour = false;
                let partialPercentage = 1.0;
                let startPosition = 0;
                
                // Check for single hour session
                if (startHour === endHour) {
                  isPartial = true;
                  isPartialSingleHour = true;
                  const startMinutes = startTime.getMinutes();
                  const endMinutes = endTime.getMinutes() || 60;
                  const occupiedMinutes = endMinutes - startMinutes;
                  partialPercentage = occupiedMinutes / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial start hour
                else if (hour === startHour && startTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialStart = true;
                  const startMinutes = startTime.getMinutes();
                  partialPercentage = (60 - startMinutes) / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial end hour
                else if (hour === endHour && endTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialEnd = true;
                  const endMinutes = endTime.getMinutes();
                  partialPercentage = endMinutes / 60;
                }
                
                // Create provider booking entry (flag as provider session)
                hostProviderBookings[hostId][formattedDate][hourIndex] = {
                  ...sessionInfo,
                  provider: true,
                  isPartial,
                  isPartialStart,
                  isPartialEnd,
                  isPartialSingleHour,
                  partialPercentage,
                  startPosition
                };
              }
            }
          }
        });
      }
      
      return { hostBookings, hostProviderBookings };
    } catch (error) {
      console.error("Error fetching host bookings:", error);
      return { hostBookings, hostProviderBookings };
    }
  }, [sessionHosts, weekDates]);

  // Fetch host availability for the current week
  const fetchHostAvailabilities = useCallback(async () => {
    if (!sessionHosts.length) return;
    
    setIsLoading(true);
    setLoadingProgress({ 
      current: 0, 
      total: sessionHosts.length + 1, // +1 for fetching bookings
      stage: 'Fetching host availabilities' 
    });
    
    try {
      const hostData = {};
      const aggregateData = {};
      
      // Initialize aggregate data structure
      weekDates.forEach((date, dateIndex) => {
        const dateString = formatDateForDB(date);
        aggregateData[dateString] = Array(11).fill(0);
      });
      
      // For each host, fetch their availability
      let hostCounter = 0;
      for (const host of sessionHosts) {
        // Update loading progress
        hostCounter++;
        setLoadingProgress({ 
          current: hostCounter, 
          total: sessionHosts.length + 1,
          stage: `Loading availability for ${host.Name} (${hostCounter}/${sessionHosts.length})` 
        });
        
        const userData = await airtable.teams.select(host.id);
        let timezone = 'America/Toronto';
        
        if (userData["Time Zone"]) {
          const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
          if (tz) timezone = tz["Connected North System"];
        }
        
        // Fetch availability from Supabase for all dates in the selected week
        const startDate = formatDateForDB(weekDates[0]);
        const endDate = formatDateForDB(weekDates[weekDates.length - 1]);
        
        const supabaseAvailability = await cnSupabase.hostAvailability.getByHostAndDateRange(
          host.id,
          startDate,
          endDate
        );
        
        // Initialize host data
        const hostAvailability = {};
        
        weekDates.forEach((date, dateIndex) => {
          const dateString = formatDateForDB(date);
          
          // Default to empty array for this date
          hostAvailability[dateString] = Array(11).fill(0);
          
          // Check if we have availability from Supabase
          const specificDateData = supabaseAvailability.find(record => record.date === dateString);
          
          if (specificDateData) {
            // Use Supabase availability
            try {
              const availArray = JSON.parse(specificDateData.availability);
              hostAvailability[dateString] = availArray;
              
              // Update aggregate data
              availArray.forEach((value, hourIndex) => {
                // Only count a host once per hour slot if they have any availability in that hour
                if (value > 0) {
                  // Host is available for at least part of this hour
                  aggregateData[dateString][hourIndex]++;
                }
              });
            } catch (e) {
              console.error("Error parsing Supabase JSON:", e);
            }
          } else {
            // For Primary Session Hosts, default to 9am-5pm Eastern if no Supabase data
            const isPrimarySessionHost = userData && userData["Primary Session Host"];
            
            if (isPrimarySessionHost) {
              // Set default availability from 9am (index 1) to 5pm (index 9)
              for (let hourIndex = 1; hourIndex <= 9; hourIndex++) {
                hostAvailability[dateString][hourIndex] = 3; // 3 means full hour available
                
                // Update aggregate data
                aggregateData[dateString][hourIndex]++;
              }
            }
          }
          // No fallback to Airtable - we're only using Supabase for availability now
        });
        
        // Add host availability to the collected data
        hostData[host.id] = {
          id: host.id,
          name: host.Name,
          timezone,
          availability: hostAvailability
        };
      }
      
      // Update the loading state for fetching bookings
      setLoadingProgress({ 
        current: sessionHosts.length, 
        total: sessionHosts.length + 1,
        stage: 'Loading session bookings' 
      });
      
      // Get host bookings for the week
      const bookingsData = await fetchHostBookings();
      
      // Set state with all the collected data
      setHostAvailabilities(hostData);
      setAggregateAvailability(aggregateData);
      setHostBookings(bookingsData.hostBookings);
      setHostProviderBookings(bookingsData.hostProviderBookings);
      
      // Complete loading
      setLoadingProgress({ 
        current: sessionHosts.length + 1, 
        total: sessionHosts.length + 1,
        stage: 'Data loaded successfully' 
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching host availabilities:", error);
      toast.error("Unable to fetch availability data. Please try again.");
      setLoadingProgress({ 
        current: 0, 
        total: 0,
        stage: 'Error loading data' 
      });
      setIsLoading(false);
    }
  }, [sessionHosts, weekDates, totalTimezones, fetchHostBookings, formatDateForDB]);

  // Load initial data
  useEffect(() => {
    if (isTeamViewer) {
      fetchSessionHosts();
      
      // Also fetch host history data
      const getHostHistory = async () => {
        const history = await fetchHostHistory();
        setHostHistory(history);
      };
      
      getHostHistory();
    }
  }, [isTeamViewer, fetchSessionHosts, fetchHostHistory]);

  // Update availabilities when hosts or week changes
  useEffect(() => {
    if (sessionHosts.length) {
      fetchHostAvailabilities();
    }
  }, [sessionHosts, currentWeekStart, fetchHostAvailabilities]);

  // Format time for display
  const formatTime = (hour) => {
    const adjustedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
    return `${adjustedHour}`;
  };

  // Get color class based on count of available hosts
  const getHeatmapColorClass = (count) => {
    if (count === 0) return 'heat-none';
    if (count <= 2) return 'heat-low';
    if (count <= 4) return 'heat-medium';
    if (count <= 6) return 'heat-high';
    return 'heat-very-high';
  };
  
  // Helper function to check if two sessions overlap in time (with buffer)
  const doSessionsOverlap = useCallback((session1, session2, bufferMinutes = 10) => {
    // Get start and end times with buffer
    const start1 = new Date(session1["Session Start Date/Time"]);
    const duration1 = parseInt(session1["Length (Minutes)"]) || 60;
    const end1 = new Date(start1.getTime() + duration1 * 60000);
    
    const start2 = new Date(session2["Session Start Date/Time"]);
    const duration2 = parseInt(session2["Length (Minutes)"]) || 60;
    const end2 = new Date(start2.getTime() + duration2 * 60000);
    
    // Apply buffer (subtract from start, add to end)
    const bufferedStart1 = new Date(start1.getTime() - bufferMinutes * 60000);
    const bufferedEnd1 = new Date(end1.getTime() + bufferMinutes * 60000);
    
    const bufferedStart2 = new Date(start2.getTime() - bufferMinutes * 60000);
    const bufferedEnd2 = new Date(end2.getTime() + bufferMinutes * 60000);
    
    // Check for overlap
    return (
      (bufferedStart1 >= bufferedStart2 && bufferedStart1 < bufferedEnd2) || // start1 is in session2's range
      (bufferedEnd1 > bufferedStart2 && bufferedEnd1 <= bufferedEnd2) || // end1 is in session2's range
      (bufferedStart1 <= bufferedStart2 && bufferedEnd1 >= bufferedEnd2) // session1 completely spans session2
    );
  }, []);
  
  /* Commenting out unused function to address ESLint warning
  // New helper function to check if a specific half-hour block overlaps with a session
  const doesHalfHourOverlapSession = useCallback((date, hour, isFirstHalf, session, bufferMinutes = 5) => {
    // Create a Date object for the start of this half-hour block
    const halfHourDate = new Date(date);
    // Add 8 to convert from index (0-10) to actual hour (8-18)
    halfHourDate.setHours(hour + 8);
    halfHourDate.setMinutes(isFirstHalf ? 0 : 30);
    halfHourDate.setSeconds(0);
    halfHourDate.setMilliseconds(0);
    
    // Create a Date object for the end of this half-hour block
    const halfHourEndDate = new Date(halfHourDate);
    halfHourEndDate.setMinutes(halfHourEndDate.getMinutes() + 30);
    
    // Get the session start and end times
    const sessionStart = new Date(session["Session Start Date/Time"]);
    const sessionDuration = parseInt(session["Length (Minutes)"]) || 60;
    const sessionEnd = new Date(sessionStart.getTime() + sessionDuration * 60000);
    
    // Apply buffer
    const bufferedSessionStart = new Date(sessionStart.getTime() - bufferMinutes * 60000);
    const bufferedSessionEnd = new Date(sessionEnd.getTime() + bufferMinutes * 60000);
    
    // Check if the half-hour block overlaps with the buffered session time
    return (
      // Half-hour block starts during session (including buffer)
      (halfHourDate >= bufferedSessionStart && halfHourDate < bufferedSessionEnd) ||
      // Half-hour block ends during session (including buffer)
      (halfHourEndDate > bufferedSessionStart && halfHourEndDate <= bufferedSessionEnd) ||
      // Half-hour block contains the session (unlikely but possible with short sessions)
      (halfHourDate <= bufferedSessionStart && halfHourEndDate >= bufferedSessionEnd)
    );
  }, []);
  */
  
  // Define calculateAvailableHostsForSessions first so it can be referenced by fetchSessionsForDate
  const calculateAvailableHostsForSessions = useCallback((sessions) => {
    const availableHosts = {};
    
    sessions.forEach(session => {
      // Calculate session hours
      const sessionDate = new Date(session["Session Start Date/Time"]);
      const startHour = sessionDate.getHours();
      
      // Calculate session end time
      const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
      const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
      const endHour = endTime.getHours();
      
      // Generate array of all hours this session spans
      const sessionHours = [];
      for (let h = startHour; h <= endHour; h++) {
        if (h >= 8 && h <= 18) { // Only include hours within 8am-6pm
          sessionHours.push(h);
        }
      }
      
      // Calculate if session ends exactly at the top of an hour
      const sessionEndMinutes = endTime.getMinutes();
      const endsExactlyOnHour = sessionEndMinutes === 0;
      
      // Find available hosts for this session
      const hostsList = Object.keys(hostAvailabilities).map(hostId => ({
        id: hostId,
        name: hostAvailabilities[hostId].name,
        availability: hostAvailabilities[hostId].availability
      }));
      
      // Filter hosts that are available for all hours of the session
      const availableHostsForSession = hostsList.filter(host => {
        // For sessions that end exactly at the top of the hour,
        // we need to exclude the end hour from our checks
        const hoursToCheck = endsExactlyOnHour
          ? sessionHours.filter(h => h !== endHour)
          : sessionHours;
        
        // Get date string for the session date
        const sessionDateString = dayjs(sessionDate).format('YYYY-MM-DD');
        
        // Check each hour of the session
        for (const hour of hoursToCheck) {
          const hourIndex = hour - 8;
          
          // Skip hours outside our range
          if (hourIndex < 0 || hourIndex >= 11) continue;
          
          // Check if host is available for this hour
          const hostHasAvailability = host.availability[sessionDateString] && 
                                     (host.availability[sessionDateString][hourIndex] > 0);
          
          // Check if host is already booked for this hour
          const hostIsBooked = hostBookings[host.id] && 
                              hostBookings[host.id][sessionDateString] && 
                              hostBookings[host.id][sessionDateString][hourIndex];
          
          // Check if host is presenting as a provider at this time
          const hostIsProvider = hostProviderBookings[host.id] && 
                               hostProviderBookings[host.id][sessionDateString] && 
                               hostProviderBookings[host.id][sessionDateString][hourIndex];
          
          // Check if host has a tentative booking
          const hasTentativeBooking = tentativeBookings[host.id] && 
                                    tentativeBookings[host.id][hourIndex];
          
          // Host is not available for this session if any of these conditions are true
          if (!hostHasAvailability || hostIsBooked || hostIsProvider || hasTentativeBooking) {
            return false;
          }
        }
        
        // If we get here, host is available for all hours of this session
        return true;
      });
      
      // Store the list of available hosts for this session
      availableHosts[session.id] = availableHostsForSession;
    });
    
    setAvailableHostsForSessions(availableHosts);
  }, [hostAvailabilities, hostBookings, hostProviderBookings, tentativeBookings]);

  // Update the list of disabled hosts for each session based on overlaps with other sessions
  const updateDisabledHostsForOverlaps = useCallback((sessions) => {
    const newDisabledHosts = {};
    
    // Get all sessions that already have hosts assigned or selected
    const sessionsWithHosts = Object.keys(selectedSessionHosts).map(sessionId => {
      const session = sessions.find(s => s.id === sessionId);
      if (session && selectedSessionHosts[sessionId]) {
        return { 
          session, 
          hostId: selectedSessionHosts[sessionId].value 
        };
      }
      return null;
    }).filter(Boolean);
    
    // For each session with a host, find overlapping sessions and disable that host
    sessions.forEach(session => {
      // Initialize empty array for this session if it doesn't exist
      if (!newDisabledHosts[session.id]) {
        newDisabledHosts[session.id] = [];
      }
      
      // Check each session with a host assigned
      sessionsWithHosts.forEach(({ session: otherSession, hostId }) => {
        // Skip same session
        if (session.id === otherSession.id) return;
        
        // Check if the sessions overlap
        if (doSessionsOverlap(session, otherSession)) {
          // This host should be disabled for this session
          if (!newDisabledHosts[session.id].includes(hostId)) {
            newDisabledHosts[session.id].push(hostId);
          }
        }
      });
    });
    
    // Update the state
    setDisabledHostsBySession(newDisabledHosts);
  }, [selectedSessionHosts, doSessionsOverlap, setDisabledHostsBySession]);

  // Fetch sessions for a specific date
  const fetchSessionsForDate = useCallback(async (date) => {
    if (!date) return;
    
    setIsLoading(true);
    setLoadingProgress({ 
      current: 0, 
      total: 2, 
      stage: 'Fetching sessions for ' + date.format('MMMM D, YYYY') 
    });
    
    try {
      // Format date as YYYY-MM-DD
      const formattedDate = formatDateForDB(date);
      
      // Create filter for sessions on this specific date that are assigned to Lily or have Jayson/Serei/Michael as School Lead
      const filterFormula = `AND(
        DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') = '${formattedDate}',
        Status = 'Booked',
        OR(
          {Session Host Text} = 'Lily Aniwaya',
          {School Lead Text} = 'Jayson Moore',
          {School Lead Text} = 'Michael Furdyk',
          {School Lead Email} = 'serei@takingitglobal.org'
        )
      )`;
      
      // Fetch matching sessions from Airtable
      const sessions = await airtable.sessions.list({ 
        filterByFormula: filterFormula,
        sort: [{ field: "Session Start Date/Time", direction: "asc" }]
      });
      
      setLoadingProgress({ 
        current: 1, 
        total: 2, 
        stage: 'Processing session data' 
      });
      
      if (sessions.length === 0) {
        setSessionsForDate({});
        setShowSessionAssignment(false);
        setIsLoading(false);
        toast.info("No sessions requiring host assignment found for this date.");
        return;
      }
      
      // Filter out sessions that already have hosts other than Lily
      const filteredSessions = sessions.filter(session => {
        // Get the session hosts text and ensure it's a string
        const sessionHosts = String(session["Session Host Text"] || "");
        
        // If the session has no hosts or only has Lily, include it
        return !sessionHosts || sessionHosts === "Lily Aniwaya" || 
               (sessionHosts.includes("Lily Aniwaya") && sessionHosts.split(",").length === 1);
      });
      
      // Process sessions and group by School Lead
      const groupedSessions = {};
      
      filteredSessions.forEach(session => {
        // Format session times for display
        const startTime = new Date(session["Session Start Date/Time"]);
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const startAmPm = startHour >= 12 ? 'PM' : 'AM';
        const formattedStartHour = startHour % 12 || 12;
        
        // Calculate session end time
        const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
        const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();
        const endAmPm = endHour >= 12 ? 'PM' : 'AM';
        const formattedEndHour = endHour % 12 || 12;
        
        // Format time range (e.g., "10:30 AM - 11:30 AM")
        const formattedTime = `${formattedStartHour}:${startMinute.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinute.toString().padStart(2, '0')} ${endAmPm}`;
        
        // Process the session data
        const processedSession = {
          ...session,
          formattedTime,
          startHour,
          startMinute,
          endHour,
          endMinute,
          durationMinutes
        };
        
        // Group by school lead using the correct field
        const schoolLead = session["School Lead Text"] || "Unassigned";
        
        if (!groupedSessions[schoolLead]) {
          groupedSessions[schoolLead] = [];
        }
        
        groupedSessions[schoolLead].push(processedSession);
      });
      
      // Update state with the processed data
      setSessionsForDate(groupedSessions);
      setShowSessionAssignment(true);
      
      // Pre-calculate available hosts for each session
      calculateAvailableHostsForSessions(sessions);
      
      // Reset the disabled hosts mapping
      setDisabledHostsBySession({});
      
      // Pre-compute which hosts should be disabled for which sessions due to overlaps
      // This only applies to already selected hosts for other sessions
      updateDisabledHostsForOverlaps(sessions);
      
      setIsLoading(false);
      setLoadingProgress({ 
        current: 2, 
        total: 2, 
        stage: 'Sessions loaded successfully' 
      });
    } catch (error) {
      console.error("Error fetching sessions:", error);
      toast.error("Unable to fetch sessions. Please try again.");
      setIsLoading(false);
      setShowSessionAssignment(false);
    }
  }, [formatDateForDB, calculateAvailableHostsForSessions, updateDisabledHostsForOverlaps, setIsLoading, setLoadingProgress, setSessionsForDate, setShowSessionAssignment, setDisabledHostsBySession]);

  // Show available hosts for a specific time slot
  const showAvailableHosts = (date, hour, count) => {
    if (count === 0) {
      toast.info("No hosts available at this time.");
      return;
    }
    
    // Get the hosts available at this time
    const dateString = formatDateForDB(date);
    const availableHosts = [];
    const unavailableHosts = [];
    
    Object.keys(hostAvailabilities).forEach(hostId => {
      const host = hostAvailabilities[hostId];
      
      // Check if host is available for this block
      if (host.availability[dateString] && 
          (host.availability[dateString][hour] === 1 || 
           host.availability[dateString][hour] === 2 || 
           host.availability[dateString][hour] === 3)) {
        
        // Check if host is booked for this time
        const isBooked = hostBookings[hostId] && 
                       hostBookings[hostId][dateString] && 
                       hostBookings[hostId][dateString][hour];
                       
        // Check if host is presenting (as provider) at this time
        const isProvider = hostProviderBookings[hostId] && 
                          hostProviderBookings[hostId][dateString] && 
                          hostProviderBookings[hostId][dateString][hour];
        
        // Only add if host is not booked or presenting
        if (!isBooked && !isProvider) {
          availableHosts.push(host.name);
        } else {
          // Host is available but booked
          unavailableHosts.push({
            name: host.name,
            reason: isProvider ? "presenting as provider" : "hosting a session"
          });
        }
      }
    });
    
    // Sort hosts alphabetically
    availableHosts.sort();
    unavailableHosts.sort((a, b) => a.name.localeCompare(b.name));
    
    // Show available hosts in a toast notification
    if (availableHosts.length === 0 && unavailableHosts.length === 0) {
      toast.info("No hosts have set availability for this time slot.");
    } else if (availableHosts.length === 0) {
      toast.info(
        <div>
          <strong>All {unavailableHosts.length} hosts with availability at this time are already booked:</strong>
          <ul className="mb-0 pl-3">
            {unavailableHosts.map((host, i) => (
              <li key={i}>{host.name} ({host.reason})</li>
            ))}
          </ul>
        </div>,
        { autoClose: 5000 }
      );
    } else {
      toast.info(
        <div>
          <strong>Available hosts ({availableHosts.length}):</strong>
          <ul className="mb-0 pl-3">
            {availableHosts.map((host, i) => (
              <li key={i}>{host}</li>
            ))}
          </ul>
          {unavailableHosts.length > 0 && (
            <>
              <strong className="mt-2 d-block">Booked hosts ({unavailableHosts.length}):</strong>
              <ul className="mb-0 pl-3">
                {unavailableHosts.map((host, i) => (
                  <li key={i}>{host.name} ({host.reason})</li>
                ))}
              </ul>
            </>
          )}
        </div>,
        { autoClose: 8000 }
      );
    }
  };
  
  // Handle clicking on a date to fetch sessions
  const handleDateClick = (date) => {
    setSelectedDate(date);
    // Also set the filtered date to show only this day in the host table
    setFilteredDate(date);
    fetchSessionsForDate(date);
    
    // Fetch host history again if we don't have it yet
    if (Object.keys(hostHistory).length === 0) {
      const getHostHistory = async () => {
        const history = await fetchHostHistory();
        setHostHistory(history);
      };
      
      getHostHistory();
    }
    
    // Add a slight delay to ensure the section has rendered before scrolling
    setTimeout(() => {
      // Try to find and scroll to the host assignment queue section by ID
      const queueSection = document.getElementById('host-assignment-queue');
      if (queueSection) {
        // Scroll to the queue section
        queueSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        // Fallback: try to find by class name or heading text
        const headingElement = Array.from(document.querySelectorAll('h2')).find(
          el => el.textContent.includes('Host Assignment Queue')
        );
        
        if (headingElement) {
          // If found the heading, scroll to its parent container
          headingElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 1000); // 1-second delay to ensure the data is loaded and section is rendered
  };
  
  // Reset unassigned sessions
  const resetUnassignedSessions = () => {
    // Clear tentative bookings
    setTentativeBookings({});
    
    // Clear selected hosts for sessions
    setSelectedSessionHosts({});
    
    // Clear blocked hosts for sessions
    setBlockedHostIds({});
    
    // Clear disabled hosts completely
    setDisabledHostsBySession({});
    
    // Clear assigned session IDs
    setAssignedSessionIds([]);
    
    // Force re-render of selects by updating the reset key
    setResetKey(prev => prev + 1);
    
    // Recalculate available hosts for each session
    if (sessionsForDate && Object.keys(sessionsForDate).length > 0) {
      const allSessions = Object.values(sessionsForDate).flat();
      if (allSessions.length > 0) {
        calculateAvailableHostsForSessions(allSessions);
      }
    }
    
    toast.info("All session assignments have been reset.");
  };
  
  // Handle manual assignment of a host to a session
  const handleAssignHost = async (sessionId, selectedOption) => {
    if (!selectedOption) return;
    
    const hostId = selectedOption.value;
    const hostName = selectedOption.label;
    const session = Object.values(sessionsForDate)
      .flat()
      .find(s => s.id === sessionId);
    
    if (!session) {
      toast.error("Session not found.");
      return;
    }
    
    // Set loading state for this specific session
    setAssigningSessionIds(prev => ({ ...prev, [sessionId]: true }));
    
    try {
      // Update Airtable with the host assignment
      await airtable.sessions.update(sessionId, {
        "Session Host(s)": [hostId]
      });
      
      // Mark session as assigned (client-side only)
      setAssignedSessionIds(prev => [...prev, sessionId]);
      
      // Show success toast
      toast.success(`Host ${hostName} has been assigned to session "${session["Session Title Text"]}"`);
      
      // Update local data without refetching everything
      
      // 1. Remove tentative booking for this host
      const newTentativeBookings = {...tentativeBookings};
      
      if (newTentativeBookings[hostId]) {
        // Remove all hour slots related to this session
        Object.keys(newTentativeBookings[hostId]).forEach(hourIdx => {
          if (newTentativeBookings[hostId][hourIdx]?.id === sessionId) {
            delete newTentativeBookings[hostId][hourIdx];
          }
        });
        
        // If there are no more bookings for this host, remove the host entry
        if (Object.keys(newTentativeBookings[hostId]).length === 0) {
          delete newTentativeBookings[hostId];
        }
      }
      
      setTentativeBookings(newTentativeBookings);
      
      // 2. Update local booking data without a full refetch
      // Create a synthetic booking entry for the client-side state
      const dateString = dayjs(session["Session Start Date/Time"]).format('YYYY-MM-DD');
      const startTime = new Date(session["Session Start Date/Time"]);
      const startHour = startTime.getHours();
      const duration = parseInt(session["Length (Minutes)"]) || 60;
      const endTime = new Date(startTime.getTime() + duration * 60000);
      const endHour = endTime.getHours();
      
      // Build a local copy of the host bookings data
      const newHostBookings = {...hostBookings};
      
      if (!newHostBookings[hostId]) {
        newHostBookings[hostId] = {};
      }
      
      if (!newHostBookings[hostId][dateString]) {
        newHostBookings[hostId][dateString] = Array(11).fill(null);
      }
      
      // Add booking to each hour this session spans
      for (let h = startHour; h <= endHour; h++) {
        const hourIndex = h - 8;
        if (hourIndex < 0 || hourIndex > 10) continue;
        
        // Check if this hour is fully or partially covered by the session
        // For precise half-hour handling
        const sessionStartMinute = h === startHour ? startTime.getMinutes() : 0;
        const sessionEndMinute = h === endHour ? endTime.getMinutes() : 60;
        
        // Only mark this hour if there's actual overlap (more precise with half-hours)
        if (sessionStartMinute < 60 && sessionEndMinute > 0) {
          newHostBookings[hostId][dateString][hourIndex] = {
            id: session.id,
            name: session["Session Title Text"] || "Untitled Session",
            provider: session["Provider Name"] || "Unknown Provider",
            teacher: session["Teacher Name"] || "Unknown Teacher",
            school: session["School Name Text"] || "Unknown School",
            timeRange: session.formattedTime || "Time not specified",
            // Add minute details for half-hour precision
            startMinute: sessionStartMinute,
            endMinute: sessionEndMinute
          };
        }
      }
      
      // Update the host bookings state
      setHostBookings(newHostBookings);
      
      // Update disabled hosts for overlapping sessions
      const allSessions = Object.values(sessionsForDate).flat();
      updateDisabledHostsForOverlaps(allSessions);
      
      // Force re-render the component with the updated state
      setResetKey(prev => prev + 1);
    } catch (error) {
      console.error("Error assigning host:", error);
      toast.error("Failed to assign host. Please try again.");
    } finally {
      // Clear loading state
      setAssigningSessionIds(prev => ({ ...prev, [sessionId]: false }));
    }
  };
  
  // Auto-assign hosts to sessions based on prioritization logic
  const autoAssignHosts = async () => {
    setIsAutoAssigning(true);
    
    try {
      // Reset all tentative assignments
      setTentativeBookings({});
      setSelectedSessionHosts({});
      setBlockedHostIds({});
      
      // Flatten all sessions
      const allSessions = Object.values(sessionsForDate).flat();
      
      // Skip already assigned sessions
      const unassignedSessions = allSessions.filter(
        session => !assignedSessionIds.includes(session.id)
      );
      
      if (unassignedSessions.length === 0) {
        toast.info("No unassigned sessions found.");
        setIsAutoAssigning(false);
        return;
      }
      
      // Sort sessions by start time (earliest first)
      const sortedSessions = [...unassignedSessions].sort((a, b) => {
        return new Date(a["Session Start Date/Time"]) - new Date(b["Session Start Date/Time"]);
      });
      
      // Track assigned hosts to avoid double-booking
      const newTentativeBookings = {};
      const newSelectedHosts = {};
      const newBlockedHosts = {};
      
      // Process each session
      for (const session of sortedSessions) {
        // Get available hosts for this session
        const availableHosts = availableHostsForSessions[session.id] || [];
        
        // Filter out hosts that are already tentatively booked or disabled due to overlaps
        const filteredHosts = availableHosts.filter(host => {
          // Exclude Tania Rashid from auto-assignments as requested
          if (host.name === "Tania Rashid") {
            return false;
          }
          
          // Check if host is disabled for this session
          if (disabledHostsBySession[session.id]?.includes(host.id)) {
            return false;
          }
          
          // Check if this host is already booked for an overlapping session
          for (const processedSession of sortedSessions) {
            // Skip this session and sessions we haven't processed yet
            if (processedSession.id === session.id) continue;
            
            // Check if this session overlaps with a processed session that has this host assigned
            if (newSelectedHosts[processedSession.id]?.value === host.id &&
                doSessionsOverlap(session, processedSession, 10)) {
              return false;
            }
          }
          
          // Calculate session hours
          const sessionDate = new Date(session["Session Start Date/Time"]);
          const startHour = sessionDate.getHours();
          
          // Calculate session end time
          const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
          const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
          const endHour = endTime.getHours();
          
          // Generate array of all hours this session spans
          const sessionHours = [];
          for (let h = startHour; h <= endHour; h++) {
            if (h >= 8 && h <= 18) { // Only include hours within 8am-6pm
              sessionHours.push(h);
            }
          }
          
          // Check each hour of the session for conflicts
          for (const hour of sessionHours) {
            const hourIndex = hour - 8;
            
            // Skip hours outside our range
            if (hourIndex < 0 || hourIndex >= 11) continue;
            
            // Check if host is already tentatively booked for this hour
            if (newTentativeBookings[host.id] && newTentativeBookings[host.id][hourIndex]) {
              return false;
            }
          }
          
          // If we get here, host has no conflicts
          return true;
        });
        
        if (filteredHosts.length === 0) {
          // No available hosts for this session
          continue;
        }
        
        // Find the best host for this session
        // Prioritization logic:
        // 1. Hosts who have previously worked with this teacher/provider combo
        // 2. Hosts with the fewest total tentative assignments
        
        // Get provider and teacher IDs for history matching
        const providerId = session.Provider && session.Provider[0];
        const teacherId = session.Teacher && session.Teacher[0];
        
        // Find the best host for this session based on history
        let bestHost = null;
        let bestHostScore = -1;
        let isPreviousMatch = false;
        
        // Only try to match history if we have both teacher and provider
        if (providerId && teacherId) {
          const historyKey = `${teacherId}_${providerId}`;
          
          // Check each available host to see if they have history with this teacher/provider
          for (const host of filteredHosts) {
            // Calculate a score for this host
            let score = 0;
            
            // Check if this host has worked with this teacher/provider combo before
            if (hostHistory[host.id] && hostHistory[host.id][historyKey]) {
              // This host has worked with this teacher/provider before!
              // Give them a high score based on how many times they've worked together
              score = 10000 + hostHistory[host.id][historyKey];
            }
            
            // If this host has the highest score so far, select them
            if (score > bestHostScore) {
              bestHost = host;
              bestHostScore = score;
              isPreviousMatch = score >= 10000; // Mark as previous match if score is high (worked together before)
            }
          }
        }
        
        // If we found a host with history, use them; otherwise use the first available host
        // This preserves the original assignment logic which assigns hosts in the order they appear
        const selectedHost = bestHost || filteredHosts[0];
        
        // Create tentative booking for this host
        if (!newTentativeBookings[selectedHost.id]) {
          newTentativeBookings[selectedHost.id] = {};
        }
        
        // Calculate session hours
        const sessionDate = new Date(session["Session Start Date/Time"]);
        const startHour = sessionDate.getHours();
        const startMinute = sessionDate.getMinutes();
        
        // Calculate session end time
        const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
        const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();
        
        // Book each hour of the session
        for (let h = startHour; h <= endHour; h++) {
          const hourIndex = h - 8;
          
          // Skip hours outside our range
          if (hourIndex < 0 || hourIndex >= 11) continue;
          
          // Check if this hour is actually covered by the session (with half-hour precision)
          const sessionStartMinute = h === startHour ? startMinute : 0;
          const sessionEndMinute = h === endHour ? endMinute : 60;
          
          // Only book hours that actually overlap with the session
          // This prevents blocking half-hour slots unnecessarily
          if (sessionStartMinute < 60 && sessionEndMinute > 0) {
            // Determine if this is a partial hour (for visual display)
            let isPartial = false;
            let isPartialStart = false;
            let isPartialEnd = false;
            let isPartialSingleHour = false;
            let partialMinutes = 0;
            
            // Calculate partial hour flags
            if (h === startHour && startMinute > 0) {
              isPartial = true;
              isPartialStart = true;
              partialMinutes = 60 - startMinute;
            }
            
            if (h === endHour && endMinute < 60) {
              isPartial = true;
              if (h === startHour) {
                isPartialSingleHour = true;
                partialMinutes = endMinute - startMinute;
              } else {
                isPartialEnd = true;
                partialMinutes = endMinute;
              }
            }
            
            // Calculate session details for display
            const startAmPm = startHour >= 12 ? 'PM' : 'AM';
            const formattedStartHour = startHour % 12 || 12;
            const endAmPm = endHour >= 12 ? 'PM' : 'AM';
            const formattedEndHour = endHour % 12 || 12;
            
            const formattedTime = `${formattedStartHour}:${startMinute.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinute.toString().padStart(2, '0')} ${endAmPm}`;
            
            // Create booking entry with precise minute timing for half-hour precision
            newTentativeBookings[selectedHost.id][hourIndex] = {
              id: session.id,
              name: session["Session Title Text"] || "Untitled Session",
              provider: session["Provider Name"] || "Unknown Provider",
              teacher: session["Teacher Name"] || "Unknown Teacher",
              school: session["School Name Text"] || "Unknown School",
              timeRange: formattedTime,
              isPreviousMatch,
              isPartial,
              isPartialStart,
              isPartialEnd,
              isPartialSingleHour,
              partialMinutes: partialMinutes,
              startMinute: sessionStartMinute,
              endMinute: sessionEndMinute
            };
          }
        }
        
        // Add to selected hosts
        newSelectedHosts[session.id] = {
          value: selectedHost.id,
          label: selectedHost.name,
          isPreviousMatch
        };
      }
      
      // Update state in a specific order to ensure consistency
      // First, set the tentative bookings
      setTentativeBookings(newTentativeBookings);
      
      // Next, set blocked hosts
      setBlockedHostIds(newBlockedHosts);
      
      // Create comprehensive disabled hosts mapping
      const allDisabledHosts = {...disabledHostsBySession};
      
      // For each session, determine which other sessions it overlaps with
      sortedSessions.forEach(session => {
        const sessionId = session.id;
        
        // Skip sessions that don't have a host assigned
        if (!newSelectedHosts[sessionId]) return;
        
        const assignedHostId = newSelectedHosts[sessionId].value;
        
        // For each other session, check if there's an overlap
        sortedSessions.forEach(otherSession => {
          // Skip the same session
          if (otherSession.id === sessionId) return;
          
          // Check if the sessions overlap
          if (doSessionsOverlap(session, otherSession, 5)) {
            // Initialize array if needed
            if (!allDisabledHosts[otherSession.id]) {
              allDisabledHosts[otherSession.id] = [];
            }
            
            // Add this host to the disabled list for the other session
            if (!allDisabledHosts[otherSession.id].includes(assignedHostId)) {
              allDisabledHosts[otherSession.id].push(assignedHostId);
            }
          }
        });
      });
      
      // Update disabled hosts mapping
      setDisabledHostsBySession(allDisabledHosts);
      
      // Now set the selected hosts after other constraints are established
      setSelectedSessionHosts(newSelectedHosts);
      
      // Finally, force re-render of selects only after all state is updated
      setTimeout(() => {
        setResetKey(prev => prev + 1);
      }, 50);
      
      // Show how many assignments were made
      const assignmentCount = Object.keys(newSelectedHosts).length;
      const historyMatchCount = Object.values(newSelectedHosts).filter(h => h.isPreviousMatch).length;
      
      if (assignmentCount > 0) {
        if (historyMatchCount > 0) {
          toast.success(
            <div>
              <p>Auto-assigned {assignmentCount} sessions.</p>
              <p><strong>{historyMatchCount} sessions</strong> were assigned to hosts who have previously worked with the same teacher/provider.</p>
              <p>Please review and confirm the assignments.</p>
            </div>,
            { autoClose: 8000 }
          );
        } else {
          toast.success(`Auto-assigned ${assignmentCount} sessions. Please review and confirm the assignments.`);
        }
      } else {
        toast.info("No sessions could be automatically assigned. Try manually assigning hosts.");
      }
    } catch (error) {
      console.error("Error auto-assigning hosts:", error);
      toast.error("Error occurred during auto-assignment. Please try again.");
    } finally {
      setIsAutoAssigning(false);
    }
  };

  // Get bookings and availability for a specific host
  const getHostData = (hostId, date, hour) => {
    const dateString = formatDateForDB(date);
    
    // Check if host is booked for this time
    const booking = hostBookings[hostId] && 
                   hostBookings[hostId][dateString] && 
                   hostBookings[hostId][dateString][hour];
    
    // Check if host is presenting (as provider) at this time
    const providerBooking = hostProviderBookings[hostId] && 
                            hostProviderBookings[hostId][dateString] && 
                            hostProviderBookings[hostId][dateString][hour];
    
    // Check availability
    const availability = hostAvailabilities[hostId] && 
                        hostAvailabilities[hostId].availability[dateString] && 
                        hostAvailabilities[hostId].availability[dateString][hour];
    
    return { booking, providerBooking, availability };
  };

  // Determine cell class and tooltip for host table cell
  const getHostCellInfo = (hostId, date, hour) => {
    const { booking, providerBooking, availability } = getHostData(hostId, date, hour);
    
    let cellClass = "";
    let tooltipContent = "";
    
    // Priority: Provider Booking > Session Booking > Availability > Not Available
    if (providerBooking) {
      cellClass = "host-provider-booked";
      tooltipContent = `${providerBooking.name} (presenting as provider)`;
    } else if (booking) {
      cellClass = "host-booked";
      tooltipContent = `${booking.name} with ${booking.provider} for ${booking.teacher}`;
    } else if (availability === 3) {
      cellClass = "host-available availability-full";
      tooltipContent = "Available (full hour)";
    } else if (availability === 1) {
      cellClass = "host-available availability-first-half";
      tooltipContent = "Available (first half-hour)";
    } else if (availability === 2) {
      cellClass = "host-available availability-second-half";
      tooltipContent = "Available (second half-hour)";
    } else {
      cellClass = "host-not-available";
      tooltipContent = "Not available";
    }
    
    return { cellClass, tooltipContent };
  };

  // Render the aggregate heatmap view
  const renderHeatmap = () => {
    return (
      <div className="availability-grid team-viewer-grid">
        <div className="days-header heatmap-header">
          <div className="time-label"></div>
          {weekDates.map((date, i) => (
            <div 
              key={i} 
              className="day-label"
              style={{ cursor: 'pointer' }}
              onClick={() => handleDateClick(date)}
            >
              <div className="day-name">
                <span className="desktop-day">{weekDays[i]}</span>
                <span className="mobile-day">{weekDaysMobile[i]}</span>
              </div>
              <div className="date">
                <span className="desktop-date">{date.format('MMMM D')}</span>
                <span className="mobile-date">{date.format('MMM D')}</span>
              </div>
            </div>
          ))}
        </div>
        
        <div className="time-slots heatmap-slots">
          {Array.from({ length: 11 }).map((_, hour) => (
            <div key={hour} className="time-row">
              <div className="time-label">
                {formatTime(hour + 8)}
                <span className="am-pm">{hour + 8 >= 12 ? 'PM' : 'AM'}</span>
              </div>
              
              {weekDates.map((date, dayIndex) => {
                const dateString = formatDateForDB(date);
                const count = aggregateAvailability[dateString] ? aggregateAvailability[dateString][hour] : 0;
                const colorClass = getHeatmapColorClass(count);
                const tooltipId = `heatmap-${dayIndex}-${hour}`;
                
                return (
                  <div key={dayIndex} className="heatmap-cell-wrapper">
                    <div
                      id={tooltipId}
                      className={`heatmap-cell ${colorClass}`}
                      onClick={() => showAvailableHosts(date, hour, count)}
                      style={{ cursor: count > 0 ? 'pointer' : 'default' }}
                      title={count === 0 ? 'No hosts available' : 
                            count === 1 ? '1 host has availability' : 
                            `${count} hosts have availability`}
                    >
                      {count > 0 && <span className="host-count">{count}</span>}
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[tooltipId]}
                        target={tooltipId}
                        toggle={() => toggleTooltip(tooltipId)}
                      >
                        {count === 0 ? 'No hosts available' : 
                         count === 1 ? '1 host has availability' : 
                         `${count} hosts have availability`}
                        <br />
                        <small>Click to see host details</small>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render the individual host view
  const renderHostView = () => {
    if (!selectedHost || selectedHost.value === 'all') {
      return null;
    }
    
    return (
      <div className="availability-grid individual-host-grid">
        <div className="days-header">
          <div className="time-label"></div>
          {weekDates.map((date, i) => (
            <div key={i} className="day-label">
              <div className="day-name">
                <span className="desktop-day">{weekDays[i]}</span>
                <span className="mobile-day">{weekDaysMobile[i]}</span>
              </div>
              <div className="date">
                <span className="desktop-date">{date.format('MMMM D')}</span>
                <span className="mobile-date">{date.format('MMM D')}</span>
              </div>
            </div>
          ))}
        </div>
        
        <div className="time-slots">
          {Array.from({ length: 11 }).map((_, hour) => (
            <div key={hour} className="time-row">
              <div className="time-label">
                {formatTime(hour + 8)}
                <span className="am-pm">{hour + 8 >= 12 ? 'PM' : 'AM'}</span>
              </div>
              
              {weekDates.map((date, dayIndex) => {
                const dateString = formatDateForDB(date);
                const { tooltipContent } = getHostCellInfo(selectedHost.value, date, hour);
                const tooltipId = `host-${selectedHost.value}-${dayIndex}-${hour}`;
                
                // Get availability value (0: none, 1: first half, 2: second half, 3: full hour)
                const availValue = hostAvailabilities[selectedHost.value] && 
                                  hostAvailabilities[selectedHost.value].availability[dateString] ? 
                                  hostAvailabilities[selectedHost.value].availability[dateString][hour] : 0;
                
                // Check if top and bottom halves are selected
                const hasTopHalf = availValue === 1 || availValue === 3;
                const hasBottomHalf = availValue === 2 || availValue === 3;
                
                // Check for bookings
                const booking = hostBookings[selectedHost.value] && 
                              hostBookings[selectedHost.value][dateString] && 
                              hostBookings[selectedHost.value][dateString][hour];
                
                const providerBooking = hostProviderBookings[selectedHost.value] && 
                                      hostProviderBookings[selectedHost.value][dateString] && 
                                      hostProviderBookings[selectedHost.value][dateString][hour];
                
                // Determine cell class based on availability and booking status
                let displayClass;
                if (providerBooking) {
                  displayClass = "host-provider-booked";
                } else if (booking) {
                  displayClass = "host-booked";
                } else if (availValue > 0) {
                  displayClass = "time-block";
                  if (availValue === 1) displayClass += " first-half";
                  if (availValue === 2) displayClass += " second-half";
                  if (availValue === 3) displayClass += " full-hour";
                } else {
                  displayClass = "time-block";
                }
                
                return (
                  <div key={dayIndex} className={`${displayClass}`}>
                    {!booking && !providerBooking && (
                      <>
                        <div 
                          className="half-block top-half"
                          id={`top-${tooltipId}`}
                          title={hasTopHalf ? "Available (first half-hour)" : "Not available"}
                        >
                          {hasTopHalf && !booking && !providerBooking && (
                            <span className="time-label-display">
                              {`${hour + 8}:00-${hour + 8}:30`}
                            </span>
                          )}
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[`top-${tooltipId}`]}
                            target={`top-${tooltipId}`}
                            toggle={() => toggleTooltip(`top-${tooltipId}`)}
                          >
                            {hasTopHalf ? "Available (first half-hour)" : "Not available"}
                          </Tooltip>
                        </div>
                        <div 
                          className="half-block bottom-half"
                          id={`bottom-${tooltipId}`}
                          title={hasBottomHalf ? "Available (second half-hour)" : "Not available"}
                        >
                          {hasBottomHalf && !booking && !providerBooking && (
                            <span className="time-label-display">
                              {`${hour + 8}:30-${hour + 9}:00`}
                            </span>
                          )}
                          <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen[`bottom-${tooltipId}`]}
                            target={`bottom-${tooltipId}`}
                            toggle={() => toggleTooltip(`bottom-${tooltipId}`)}
                          >
                            {hasBottomHalf ? "Available (second half-hour)" : "Not available"}
                          </Tooltip>
                        </div>
                      </>
                    )}
                    {(booking || providerBooking) && (
                      <div 
                        className="host-cell-content"
                        id={tooltipId}
                        title={tooltipContent}
                      >
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[tooltipId]}
                          target={tooltipId}
                          toggle={() => toggleTooltip(tooltipId)}
                        >
                          {tooltipContent}
                        </Tooltip>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render the host table view (all hosts)
  const renderHostTable = () => {
    // Check if a host has any availability data for the current week
    const hasAvailabilityForCurrentWeek = (hostId) => {
      // Get all date strings for the current week
      const weekDateStrings = weekDates.map(date => formatDateForDB(date));
      
      // Loop through each date to check if the host has any availability set
      for (const dateString of weekDateStrings) {
        if (hostAvailabilities[hostId].availability[dateString]) {
          // Check if any hour slot has availability (value > 0)
          if (hostAvailabilities[hostId].availability[dateString].some(value => value > 0)) {
            return true;
          }
        }
      }
      return false;
    };
    
    // Only show hosts with availability data for the current week
    const hostsWithData = Object.keys(hostAvailabilities)
      .filter(hostId => hasAvailabilityForCurrentWeek(hostId))
      .map(hostId => ({
        id: hostId,
        name: hostAvailabilities[hostId].name
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    
    if (hostsWithData.length === 0) {
      return (
        <div className="host-table-container">
          <h3>Host Availability Details</h3>
          <div className="alert alert-info">
            No hosts have availability data entered for this week.
          </div>
        </div>
      );
    }
    
    // Filter dates to show based on filteredDate (if set)
    const datesToShow = filteredDate 
      ? weekDates.filter(date => date.isSame(filteredDate, 'day')) 
      : weekDates;
    
    // Create a button to reset the filter if we're filtering
    const filterMessage = filteredDate 
      ? `Showing only ${filteredDate.format('MMMM D, YYYY')} - ` 
      : '';
    
    const resetFilter = () => {
      setFilteredDate(null);
    };
    
    return (
      <div className="host-table-container">
        <h3>
          Host Availability Details ({hostsWithData.length} hosts)
          {filteredDate && (
            <span className="text-muted ml-2" style={{ fontSize: '0.9rem' }}>
              {filterMessage}
              <Button color="link" className="p-0 ml-1" onClick={resetFilter}>
                Show All Dates
              </Button>
            </span>
          )}
        </h3>
        <div className="host-table-scroll">
          <table className={`host-table ${filteredDate ? 'filtered-day-view' : ''}`}>
            <thead>
              <tr>
                <th>Host</th>
                {datesToShow.map((date, dateIndex) => (
                  <th key={dateIndex} colSpan="22">
                    <div 
                      className="host-table-date"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDateClick(date)}
                    >
                      <span>{filteredDate ? date.format('ddd') : weekDays[weekDates.indexOf(date)]}</span>
                      <span>{date.format('MMM D')}</span>
                    </div>
                  </th>
                ))}
              </tr>
              <tr className="hour-header">
                <th></th>
                {datesToShow.map((date, dateIndex) => (
                  Array.from({ length: 22 }).map((_, halfHourIndex) => {
                    const hour = Math.floor(halfHourIndex / 2) + 8;
                    const minute = halfHourIndex % 2 === 0 ? "00" : "30";
                    return (
                      <th key={`${dateIndex}-${halfHourIndex}`} className="half-hour-cell">
                        {halfHourIndex % 2 === 0 ? hour : ""}
                        {halfHourIndex % 2 === 1 ? <small>{minute}</small> : ""}
                      </th>
                    );
                  })
                ))}
              </tr>
            </thead>
            <tbody>
              {hostsWithData.map(host => (
                <tr key={host.id}>
                  <td className="host-name">{host.name}</td>
                  {datesToShow.map((date, dateIndex) => (
                    Array.from({ length: 22 }).map((_, halfHourIndex) => {
                      const hour = Math.floor(halfHourIndex / 2);
                      const isFirstHalf = halfHourIndex % 2 === 0;
                      const halfHourKey = `${hour}-${isFirstHalf ? 'first' : 'second'}`;
                      const tooltipId = `table-${host.id}-${dateIndex}-${halfHourKey}`;
                      
                      // Get the hour-based availability (we'll refine this for half-hours)
                      const { cellClass: hourCellClass, tooltipContent: hourTooltipContent } = getHostCellInfo(host.id, date, hour);
                      
                      // Define logic for half-hour availability display
                      let cellClass = hourCellClass;
                      let tooltipContent = hourTooltipContent;
                      
                      // Refine for half-hour display
                      // For availability: 1 = first half, 2 = second half, 3 = full hour
                      const availability = hostAvailabilities[host.id]?.availability[formatDateForDB(date)]?.[hour];
                      
                      if (availability === 1 && !isFirstHalf) {
                        // Not available in second half when only first half is available
                        cellClass = "host-not-available";
                        tooltipContent = "Not available";
                      } else if (availability === 2 && isFirstHalf) {
                        // Not available in first half when only second half is available
                        cellClass = "host-not-available";
                        tooltipContent = "Not available";
                      }
                      
                      // Check if there's a tentative booking for this host at this time
                      const hourIndex = hour;
                      
                      // Look for any tentative bookings for this host and time
                      let tentativeBooking = null;
                      if (tentativeBookings[host.id] && tentativeBookings[host.id][hourIndex]) {
                        tentativeBooking = tentativeBookings[host.id][hourIndex];
                      }
                      
                      // For actual bookings, check session time details
                      const dateString = formatDateForDB(date);
                      const booking = hostBookings[host.id]?.[dateString]?.[hour];
                      const providerBooking = hostProviderBookings[host.id]?.[dateString]?.[hour];
                      
                      // Determine if this particular half-hour is included in the booking
                      let isInBookingTimeRange = false;
                      
                      // Handle half-hour precision - check if this specific half-hour overlaps with the booking
                      if (booking) {
                        // Get the minutes for this half-hour block
                        const halfHourStart = isFirstHalf ? 0 : 30;
                        const halfHourEnd = isFirstHalf ? 30 : 60;
                        
                        // Check if the booking overlaps with this half-hour block
                        const bookingStartMinute = booking.startMinute || 0;
                        const bookingEndMinute = booking.endMinute || 60;
                        
                        // Half-hour overlaps if:
                        // 1. Booking starts before half-hour ends
                        // 2. Booking ends after half-hour starts
                        isInBookingTimeRange = 
                          bookingStartMinute < halfHourEnd && 
                          bookingEndMinute > halfHourStart;
                      }
                      
                      if (booking) {
                        try {
                          // Get start time, handling different data structures
                          let startTimeStr;
                          let durationMinutes = 60;
                          
                          if (booking.startTime) {
                            startTimeStr = booking.startTime;
                          } else if (booking["Session Start Date/Time"]) {
                            startTimeStr = booking["Session Start Date/Time"];
                          } else if (booking.session && booking.session["Session Start Date/Time"]) {
                            startTimeStr = booking.session["Session Start Date/Time"];
                          }
                          
                          // Get duration, handling different data structures
                          if (booking.duration) {
                            durationMinutes = booking.duration;
                          } else if (booking["Length (Minutes)"]) {
                            durationMinutes = parseInt(booking["Length (Minutes)"]) || 60;
                          } else if (booking.session && booking.session["Length (Minutes)"]) {
                            durationMinutes = parseInt(booking.session["Length (Minutes)"]) || 60;
                          }
                          
                          if (startTimeStr) {
                            const startTime = new Date(startTimeStr);
                            // Convert actual hour and minute to display format
                            const startHourDisplay = startTime.getHours();
                            const startMinuteDisplay = startTime.getMinutes();
                            const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
                            const formattedStartHour = startHourDisplay % 12 || 12;
                            
                            const sessionHour = hour + 8; // Convert from index to actual hour
                            const halfHourMinute = isFirstHalf ? 0 : 30;
                            
                            // Calculate session end time
                            const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
                            const endHourDisplay = endTime.getHours();
                            const endMinuteDisplay = endTime.getMinutes();
                            const endAmPm = endHourDisplay >= 12 ? 'PM' : 'AM';
                            const formattedEndHour = endHourDisplay % 12 || 12;
                            
                            // Check if this half-hour is within the booking time
                            // Use the date from the booking but set the hour/minute to our half-hour slot
                            const halfHourStart = new Date(startTime);
                            halfHourStart.setHours(sessionHour, halfHourMinute, 0, 0);
                            
                            const halfHourEnd = new Date(halfHourStart);
                            halfHourEnd.setMinutes(halfHourStart.getMinutes() + 30);
                            
                            // This half-hour is in the booking if the booking starts before this half-hour ends
                            // and the booking ends after this half-hour starts
                            isInBookingTimeRange = 
                              startTime <= halfHourEnd && endTime >= halfHourStart;
                              
                            // Format time for tooltip
                            const formattedTime = `${formattedStartHour}:${startMinuteDisplay.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinuteDisplay.toString().padStart(2, '0')} ${endAmPm}`;
                            
                            // Update tooltip content with the time information
                            if (isInBookingTimeRange) {
                              tooltipContent = `${booking.name || "Session"} with ${booking.provider || "Provider"} (${formattedTime})`;
                            }
                          } else {
                            // Default to true if we can't determine the exact time
                            isInBookingTimeRange = true;
                          }
                        } catch (error) {
                          console.error("Error calculating booking time range:", error);
                          // Default to true if there's any error
                          isInBookingTimeRange = true;
                        }
                      } else if (providerBooking) {
                        // Same logic for provider bookings
                        const halfHourStart = isFirstHalf ? 0 : 30;
                        const halfHourEnd = isFirstHalf ? 30 : 60;
                        
                        const providerStartMinute = providerBooking.startMinute || 0;
                        const providerEndMinute = providerBooking.endMinute || 60;
                        
                        isInBookingTimeRange = 
                          providerStartMinute < halfHourEnd && 
                          providerEndMinute > halfHourStart;
                          
                        // If provider booking has time information, format it for the tooltip
                        if (providerBooking.timeRange) {
                          tooltipContent = `${providerBooking.name || "Session"} (provider presenting) (${providerBooking.timeRange})`;
                        } else {
                          // Try to format the time from start/end hour/minute if available
                          try {
                            if (hour !== undefined && providerStartMinute !== undefined) {
                              const startHourDisplay = hour;
                              const startMinuteDisplay = providerStartMinute;
                              const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
                              const formattedStartHour = startHourDisplay % 12 || 12;
                              
                              const endHour = providerEndMinute === 60 ? hour + 1 : hour;
                              const endMinuteDisplay = providerEndMinute === 60 ? 0 : providerEndMinute;
                              const endAmPm = endHour >= 12 ? 'PM' : 'AM';
                              const formattedEndHour = endHour % 12 || 12;
                              
                              const formattedTime = `${formattedStartHour}:${startMinuteDisplay.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinuteDisplay.toString().padStart(2, '0')} ${endAmPm}`;
                              tooltipContent = `${providerBooking.name || "Session"} (provider presenting) (${formattedTime})`;
                            }
                          } catch (error) {
                            console.error("Error formatting provider booking time:", error);
                            tooltipContent = `${providerBooking.name || "Session"} (provider presenting)`;
                          }
                        }
                      } else if (tentativeBooking) {
                        // Use the same half-hour precision for tentative bookings
                        const halfHourStart = isFirstHalf ? 0 : 30;
                        const halfHourEnd = isFirstHalf ? 30 : 60;
                        
                        const tentativeStartMinute = tentativeBooking.startMinute || 0;
                        const tentativeEndMinute = tentativeBooking.endMinute || 60;
                        
                        isInBookingTimeRange = 
                          tentativeStartMinute < halfHourEnd && 
                          tentativeEndMinute > halfHourStart;
                          
                        // If tentative booking has time information, format it for the tooltip
                        if (tentativeBooking.timeRange) {
                          tooltipContent = `TENTATIVE: ${tentativeBooking.name || "Session"} with ${tentativeBooking.provider || "Provider"} (${tentativeBooking.timeRange})`;
                        }
                      }
                      
                      // Add a special class if there's a tentative booking
                      const tentativeClass = tentativeBooking && isInBookingTimeRange ? 'tentative-booking' : '';
                      
                      // Generate tooltip content - include tentative booking info if present
                      let finalTooltipContent = tooltipContent;
                      if (tentativeBooking && isInBookingTimeRange) {
                        if (tentativeBooking.timeRange) {
                          finalTooltipContent = `TENTATIVE: ${tentativeBooking.name} with ${tentativeBooking.provider} (${tentativeBooking.timeRange})`;
                        } else {
                          // Try to format the time for tentative bookings that don't have timeRange already
                          try {
                            if (hour !== undefined && tentativeBooking.startMinute !== undefined && tentativeBooking.endMinute !== undefined) {
                              const startHourDisplay = hour;
                              const startMinuteDisplay = tentativeBooking.startMinute;
                              const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
                              const formattedStartHour = startHourDisplay % 12 || 12;
                              
                              const endHour = tentativeBooking.endMinute === 60 ? hour + 1 : hour;
                              const endMinuteDisplay = tentativeBooking.endMinute === 60 ? 0 : tentativeBooking.endMinute;
                              const endAmPm = endHour >= 12 ? 'PM' : 'AM';
                              const formattedEndHour = endHour % 12 || 12;
                              
                              const formattedTime = `${formattedStartHour}:${startMinuteDisplay.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinuteDisplay.toString().padStart(2, '0')} ${endAmPm}`;
                              finalTooltipContent = `TENTATIVE: ${tentativeBooking.name} with ${tentativeBooking.provider} (${formattedTime})`;
                            } else {
                              finalTooltipContent = `TENTATIVE: ${tentativeBooking.name} with ${tentativeBooking.provider}`;
                            }
                          } catch (error) {
                            console.error("Error formatting tentative booking time:", error);
                            finalTooltipContent = `TENTATIVE: ${tentativeBooking.name} with ${tentativeBooking.provider}`;
                          }
                        }
                      }
                      
                      // Only show booking displays for the correct half-hours
                      if ((booking || providerBooking) && !isInBookingTimeRange) {
                        cellClass = availability === 1 && isFirstHalf ? "host-available availability-first-half" :
                                   availability === 2 && !isFirstHalf ? "host-available availability-second-half" :
                                   availability === 3 ? "host-available availability-full" :
                                   "host-not-available";
                        
                        tooltipContent = cellClass.includes("host-available") ? 
                                        `Available ${isFirstHalf ? "(first half-hour)" : "(second half-hour)"}` : 
                                        "Not available";
                        
                        finalTooltipContent = tooltipContent;
                      }
                      
                      return (
                        <td key={`${dateIndex}-${halfHourIndex}`} className="host-half-hour-cell">
                          <div
                            className={`host-half-hour ${cellClass} ${tentativeClass}`}
                            id={tooltipId}
                            title={finalTooltipContent}
                            style={tentativeBooking && isInBookingTimeRange ? { background: '#ffe0b2', borderColor: '#ffcc80' } : {}}
                          >
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[tooltipId]}
                              target={tooltipId}
                              toggle={() => toggleTooltip(tooltipId)}
                            >
                              {finalTooltipContent}
                            </Tooltip>
                          </div>
                        </td>
                      );
                    })
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // Render the session assignment table
  const renderSessionAssignmentTable = () => {
    if (!showSessionAssignment || !selectedDate || Object.keys(sessionsForDate).length === 0) {
      return null;
    }
    
    return (
      <div id="host-assignment-queue" className="session-assignment-section mt-5">
        <div className="sessions-header-container">
          <h2 className="sessions-table-title">Host Assignment Queue for {selectedDate.format('MMMM D, YYYY')}</h2>
          <div className="button-group">
            <Button 
              className="reset-all-btn mr-2" 
              color="secondary" 
              onClick={resetUnassignedSessions}
              disabled={isLoading || isAutoAssigning}
            >
              <i className="fa fa-refresh mr-1" aria-hidden="true"></i> Reset All
            </Button>
            <Button 
              className="auto-assign-btn" 
              color="primary" 
              onClick={autoAssignHosts}
              disabled={isLoading || isAutoAssigning}
            >
              {isAutoAssigning ? (
                <>
                  <i className="fa fa-spinner fa-spin mr-1" aria-hidden="true"></i> Assigning Hosts...
                </>
              ) : (
                <>
                  <i className="fa fa-magic mr-1" aria-hidden="true"></i> Automagically Assign
                </>
              )}
            </Button>
          </div>
        </div>
        
        {isLoading ? (
          <div className="loading-container">
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            <p>Loading host availability data...</p>
          </div>
        ) : (
          Object.keys(sessionsForDate).map(schoolLead => (
            <div key={schoolLead}>
              <h3 className="school-lead-title">{schoolLead}</h3>
              <table className="sessions">
                <thead>
                  <tr>
                    <th>Session Title & Provider</th>
                    <th>School & Teacher</th>
                    <th>Time</th>
                    <th>Available Hosts</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionsForDate[schoolLead].map(session => {
                    // Check if this session is in the assigned list
                    const isAssigned = assignedSessionIds.includes(session.id);
                    
                    return (
                      <tr 
                        key={session.id} 
                        className={isAssigned || assigningSessionIds[session.id] ? 'assigned-session-row' : ''}
                      >
                        <td>
                          <div className="session-title">
                            <a 
                              href={`http://sessions.connectednorth.org/cn/session/${session["Session Title"]}`} 
                              target="_blank" 
                              rel="noreferrer"
                            >
                              {session["Session Title Text"]}
                            </a>
                          </div>
                          <div className="session-provider">
                            with <a 
                              href={`http://sessions.connectednorth.org/cn/provider/${session["Provider"]}`} 
                              target="_blank" 
                              rel="noreferrer"
                            >
                              {session["Provider Name"]}
                            </a>
                          </div>
                        </td>
                        <td>
                          {session["# Schools"] > 1 ? (
                            <div className="school-name">
                              {session["Primary Subject Text"] === "Professional Development" ? 
                                `PD with ${session["# Schools"]} Schools` : 
                                `Premium Session with ${session["# Schools"]} Schools`}
                            </div>
                          ) : (
                            <div className="school-name">
                              <a href={`${session["School Profile Link"]}`} target="_blank" rel="noreferrer">
                                {session["School Name Text"]}
                              </a>
                            </div>
                          )}
                          <div className="session-provider">for {session["Teacher Name"]}</div>
                        </td>
                        <td>{session.formattedTime}</td>
                        <td>
                          {isAssigned ? (
                            <div className="text-success">
                              <i className="fa fa-check-circle mr-2" />
                              Host assigned
                            </div>
                          ) : (
                            <div className="host-assign-container">
                              <Select
                                key={`select-${session.id}-${resetKey}`}
                                className="host-select"
                                value={selectedSessionHosts[session.id]}
                                options={(availableHostsForSessions[session.id] || [])
                                    .filter(host => {
                                      // Skip hosts that are manually blocked
                                      if (blockedHostIds[session.id]?.includes(host.id)) {
                                        return false;
                                      }
                                      
                                      // Skip hosts that are disabled due to overlaps with other sessions
                                      // Only if we have valid disabled hosts data
                                      if (disabledHostsBySession && 
                                          Object.keys(disabledHostsBySession).length > 0 &&
                                          disabledHostsBySession[session.id]?.includes(host.id)) {
                                        return false;
                                      }
                                      
                                      return true;
                                    })
                                    .map(host => ({
                                      value: host.id,
                                      label: host.name,
                                      // Add a flag if this host is hosting other sessions soon
                                      isScheduledNearby: disabledHostsBySession[session.id]?.includes(host.id)
                                    }))
                                    // Sort alphabetically by host name
                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                placeholder="Select a host..."
                                isSearchable
                                isClearable={true}
                                formatOptionLabel={(option) => {
                                  // Check if this host has history with this teacher/provider
                                  const providerId = session.Provider && session.Provider[0];
                                  const teacherId = session.Teacher && session.Teacher[0];
                                  const historyKey = `${teacherId}_${providerId}`;
                                  const hasHistory = providerId && teacherId && 
                                                    hostHistory[option.value] && 
                                                    hostHistory[option.value][historyKey];
                                  const historyCount = hasHistory ? hostHistory[option.value][historyKey] : 0;
                                  
                                  return (
                                    <div className="select-option-with-icon">
                                      {option.label}
                                      {hasHistory && (
                                        <span className="history-badge ml-1" title={`Host has worked with this teacher/provider ${historyCount} time(s) before`}>
                                          <i className="fa fa-history text-success" />
                                          {historyCount > 1 && ` (${historyCount})`}
                                        </span>
                                      )}
                                      {selectedSessionHosts[session.id]?.isPreviousMatch && 
                                       option.value === selectedSessionHosts[session.id]?.value && !hasHistory && (
                                        <i 
                                          className="fa fa-check-circle ml-1 text-primary" 
                                          title="Auto-selected host"
                                        />
                                      )}
                                    </div>
                                  );
                                }}
                                onChange={(selectedOption) => {
                                  // Update the host selection
                                  setSelectedSessionHosts(prev => ({
                                    ...prev,
                                    [session.id]: selectedOption
                                  }));
                                  
                                  // Clear any previous tentative bookings for this session
                                  const updatedTentativeBookings = {...tentativeBookings};
                                  
                                  // Get the previously selected host for this session, if any
                                  const previousHostId = selectedSessionHosts[session.id]?.value;
                                  
                                  // Loop through all host ids and remove any entries for this session
                                  Object.keys(updatedTentativeBookings).forEach(hId => {
                                    Object.keys(updatedTentativeBookings[hId] || {}).forEach(hourIdx => {
                                      if (updatedTentativeBookings[hId][hourIdx]?.id === session.id) {
                                        delete updatedTentativeBookings[hId][hourIdx];
                                      }
                                    });
                                    
                                    // Clean up empty objects
                                    if (Object.keys(updatedTentativeBookings[hId] || {}).length === 0) {
                                      delete updatedTentativeBookings[hId];
                                    }
                                  });
                                  
                                  // Update tentative bookings state
                                  setTentativeBookings(updatedTentativeBookings);
                                  
                                  // Get all sessions for the day for overlap checking
                                  const allSessions = Object.values(sessionsForDate).flat();
                                  
                                  // If an option was selected, add new tentative bookings
                                  if (selectedOption) {
                                    const hostId = selectedOption.value;
                                    
                                    // Make sure the host's entry exists
                                    if (!updatedTentativeBookings[hostId]) {
                                      updatedTentativeBookings[hostId] = {};
                                    }
                                    
                                    // Calculate session hours
                                    const sessionDate = new Date(session["Session Start Date/Time"]);
                                    const startHour = sessionDate.getHours();
                                    
                                    // Calculate session end time
                                    const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
                                    const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
                                    const endHour = endTime.getHours();
                                    
                                    // Book each hour of the session
                                    for (let h = startHour; h <= endHour; h++) {
                                      const hourIndex = h - 8;
                                      
                                      // Skip hours outside our range
                                      if (hourIndex < 0 || hourIndex >= 11) continue;
                                      
                                      // Calculate session details for display
                                      const startHourDisplay = startHour;
                                      const startMinuteDisplay = sessionDate.getMinutes();
                                      const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
                                      const formattedStartHour = startHourDisplay % 12 || 12;
                                      
                                      const endHourDisplay = endTime.getHours();
                                      const endMinuteDisplay = endTime.getMinutes();
                                      const endAmPm = endHourDisplay >= 12 ? 'PM' : 'AM';
                                      const formattedEndHour = endHourDisplay % 12 || 12;
                                      
                                      const formattedTime = `${formattedStartHour}:${startMinuteDisplay.toString().padStart(2, '0')} ${startAmPm} - ${formattedEndHour}:${endMinuteDisplay.toString().padStart(2, '0')} ${endAmPm}`;
                                      
                                      // Check if this hour is actually covered by the session (with half-hour precision)
                                      const sessionStartMinute = h === startHour ? sessionDate.getMinutes() : 0;
                                      const sessionEndMinute = h === endHour ? endTime.getMinutes() : 60;
                                      
                                      // Create booking entry
                                      updatedTentativeBookings[hostId][hourIndex] = {
                                        id: session.id,
                                        name: session["Session Title Text"] || "Untitled Session",
                                        provider: session["Provider Name"] || "Unknown Provider",
                                        teacher: session["Teacher Name"] || "Unknown Teacher",
                                        timeRange: formattedTime,
                                        startMinute: sessionStartMinute,
                                        endMinute: sessionEndMinute
                                      };
                                    }
                                    
                                    // Update tentative bookings again with the new entries
                                    setTentativeBookings(updatedTentativeBookings);
                                    
                                    // Update which hosts should be disabled for which sessions
                                    const newDisabledHosts = {...disabledHostsBySession};
                                    
                                    // For each other session, check if it overlaps with this one
                                    allSessions.forEach(otherSession => {
                                      // Skip the current session
                                      if (otherSession.id === session.id) return;
                                      
                                      // Check for overlap with a 10-minute buffer
                                      if (doSessionsOverlap(session, otherSession, 10)) {
                                        // Initialize the array if needed
                                        if (!newDisabledHosts[otherSession.id]) {
                                          newDisabledHosts[otherSession.id] = [];
                                        }
                                        
                                        // Add this host to the disabled list for the other session
                                        if (!newDisabledHosts[otherSession.id].includes(hostId)) {
                                          newDisabledHosts[otherSession.id].push(hostId);
                                        }
                                      }
                                    });
                                    
                                    // Also make sure this host is disabled in all sessions that overlap with sessions
                                    // where this host is already assigned
                                    allSessions.forEach(sessionWithHost => {
                                      // Skip if not assigned to this host
                                      if (!selectedSessionHosts[sessionWithHost.id] || 
                                          selectedSessionHosts[sessionWithHost.id].value !== hostId) {
                                        return;
                                      }
                                      
                                      // For each other session, check for overlaps
                                      allSessions.forEach(otherSession => {
                                        // Skip same session or current session
                                        if (otherSession.id === sessionWithHost.id || 
                                            otherSession.id === session.id) {
                                          return;
                                        }
                                        
                                        // Check if overlapping
                                        if (doSessionsOverlap(sessionWithHost, otherSession, 10)) {
                                          // Initialize the array if needed
                                          if (!newDisabledHosts[otherSession.id]) {
                                            newDisabledHosts[otherSession.id] = [];
                                          }
                                          
                                          // Add this host to the disabled list
                                          if (!newDisabledHosts[otherSession.id].includes(hostId)) {
                                            newDisabledHosts[otherSession.id].push(hostId);
                                          }
                                        }
                                      });
                                    });
                                    
                                    // Update the disabled hosts state
                                    setDisabledHostsBySession(newDisabledHosts);
                                    
                                    // Force re-render of all dropdowns after a selection
                                    setResetKey(prev => prev + 1);
                                  } else {
                                    // A host was deselected - we need to remove this host from disabled lists
                                    // for any sessions that overlap with this one
                                    
                                    // Only do this if we had a previous host selected
                                    if (previousHostId) {
                                      const newDisabledHosts = {...disabledHostsBySession};
                                      
                                      // For each other session, check if it overlaps with this one
                                      allSessions.forEach(otherSession => {
                                        // Skip the current session
                                        if (otherSession.id === session.id) return;
                                        
                                        // Check for overlap with a 10-minute buffer
                                        if (doSessionsOverlap(session, otherSession, 10)) {
                                          // If this session overlaps, remove the previous host from its disabled list
                                          if (newDisabledHosts[otherSession.id]) {
                                            const index = newDisabledHosts[otherSession.id].indexOf(previousHostId);
                                            if (index !== -1) {
                                              // Check if this host should still be disabled for this session
                                              // due to being selected for another overlapping session
                                              const shouldStillBeDisabled = Object.entries(selectedSessionHosts)
                                                .some(([sessionId, selectedOption]) => {
                                                  // Skip the current session and the session we're checking
                                                  if (sessionId === session.id || sessionId === otherSession.id) {
                                                    return false;
                                                  }
                                                  
                                                  // Only consider if the host matches
                                                  if (selectedOption?.value !== previousHostId) {
                                                    return false;
                                                  }
                                                  
                                                  // Get the session object
                                                  const checkSession = allSessions.find(s => s.id === sessionId);
                                                  if (!checkSession) return false;
                                                  
                                                  // Check if this session overlaps with the session we're checking
                                                  return doSessionsOverlap(checkSession, otherSession, 10);
                                                });
                                              
                                              // Only remove if the host shouldn't still be disabled
                                              if (!shouldStillBeDisabled) {
                                                newDisabledHosts[otherSession.id].splice(index, 1);
                                              }
                                            }
                                          }
                                        }
                                      });
                                      
                                      // Update the disabled hosts state
                                      setDisabledHostsBySession(newDisabledHosts);
                                      
                                      // Force re-render of all dropdowns after a deselection
                                      setResetKey(prev => prev + 1);
                                    }
                                  }
                                }}
                              />
                              <Button
                                color="primary"
                                size="sm"
                                className="assign-host-btn"
                                onClick={() => handleAssignHost(session.id, selectedSessionHosts[session.id])}
                                disabled={!selectedSessionHosts[session.id] || isLoading || assigningSessionIds[session.id]}
                              >
                                {assigningSessionIds[session.id] ? (
                                  <>
                                    <i className="fa fa-spinner fa-spin mr-1"></i> Assigning...
                                  </>
                                ) : (
                                  'Assign'
                                )}
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))
        )}
      </div>
    );
  };
  
  return (
    <div className="team-viewer-scheduler" style={{ padding: '20px' }}>
      <div className="mb-4 mt-3">
        <h2>Session Host Availability Manager</h2>
      </div>
      
      <p className="text-muted">
        View and manage availability for all session hosts across multiple weeks. Select specific hosts, or view the heat map to see overall availability.
        <strong> Click on a heat map cell to view and assign sessions for that day.</strong>
      </p>
      
      <div className="week-selector d-flex justify-content-between align-items-center mb-4">
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={goToPreviousWeek}
          disabled={isCurrentWeek || isLoading}
          title={isCurrentWeek ? "Cannot go before current week" : "Go to previous week"}
        >
          <i className="fa fa-chevron-left" />
        </Button>
        
        <div className="week-display">
          <span>Week of {weekDates[0] ? weekDates[0].format('MMMM D, YYYY') : ''}</span>
          {isCurrentWeek && <span className="current-week-badge ml-2">Current Week</span>}
        </div>
        
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={goToNextWeek}
          disabled={isLoading}
        >
          <i className="fa fa-chevron-right" />
        </Button>
      </div>
      
      <div className="host-selector mb-4">
        <FormGroup>
          <label>Select Session Host</label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={selectedHost}
            onChange={setSelectedHost}
            options={[
              { value: 'all', label: 'All Hosts (Heat Map)' },
              ...sessionHosts
                .map(host => ({ value: host.id, label: host.Name }))
                .sort((a, b) => a.label.localeCompare(b.label))
            ]}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </FormGroup>
      </div>
      
      {isLoading && !showSessionAssignment ? (
        <div className="text-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-2 font-weight-bold">{loadingProgress.stage}</p>
          {loadingProgress.total > 0 && (
            <>
              <div className="progress mt-3" style={{ height: '10px', maxWidth: '500px', margin: '0 auto' }}>
                <div 
                  className="progress-bar progress-bar-striped progress-bar-animated" 
                  role="progressbar" 
                  style={{ 
                    width: `${Math.round((loadingProgress.current / loadingProgress.total) * 100)}%` 
                  }}
                  aria-valuenow={loadingProgress.current}
                  aria-valuemin="0"
                  aria-valuemax={loadingProgress.total}
                ></div>
              </div>
              <p className="mt-2 text-muted">
                {`${loadingProgress.current} of ${loadingProgress.total} ${loadingProgress.current === 1 ? 'task' : 'tasks'} completed`}
              </p>
            </>
          )}
        </div>
      ) : (
        <>
          {selectedHost && selectedHost.value === 'all' ? (
            renderHeatmap()
          ) : (
            renderHostView()
          )}
          
          {selectedHost && selectedHost.value === 'all' && renderHostTable()}
          
          {/* Session Assignment Table */}
          {renderSessionAssignmentTable()}
        </>
      )}
    </div>
  );
};

export default TeamViewerScheduler;